import moment from 'moment'
import _get from 'lodash/get'
import queryString from 'query-string'

export const arrayToObject = arr => {
  const rv = {}
  if (!arr) return rv
  if (Object.prototype.toString.call(arr) === '[object Array]') {
    for (let i = 0; i < arr.length; ++i) rv[i] = arr[i]
  } else {
    for (const attr in arr) {
      rv[attr] = arr[attr]
    }
  }
  return rv
}

export const isDateRangeInvalid = (start, end) => {
  const sm = moment.utc(start) //moment(start, dateFormat, true)
  const em = moment.utc(end) //moment(end, dateFormat, true)
  if (!sm.isValid()) {
    return
  }
  if (!em.isValid()) {
    return
  }
  return em.isSame(sm) || em.isBefore(sm)
}

export const isDateRangeInvalid2 = (start, end) => {
  if (!start) return false
  const sm = moment.utc(start) //moment(start, dateFormat, true)
  const em = moment.utc(end) //moment(end, dateFormat, true)
  if (!sm.isValid()) {
    return
  }
  if (!em.isValid()) {
    return
  }
  return em.isSame(sm) || em.isBefore(sm)
}

export const isDateRangeInvalid3 = (start, end) => {
  const sm = moment.utc(start) //moment(start, dateFormat, true)
  const em = moment.utc(end) //moment(end, dateFormat, true)
  if (!sm.isValid()) {
    return
  }
  if (!em.isValid()) {
    return
  }
  return em.isBefore(sm)
}

const getPayoutDetailsFlagValue = props => {
  const accountName = _get(props, 'initialValues.bankingDetails.accountName')
  const accountNumber = _get(props, 'initialValues.bankingDetails.accountNumber')
  const routingNumber = _get(props, 'initialValues.bankingDetails.routingNumber')
  const bsb = _get(props, 'initialValues.bankingDetails.bsb')
  const sortCode = _get(props, 'initialValues.bankingDetails.sortCode')
  const ifscNumber = _get(props, 'initialValues.bankingDetails.ifscNumber')
  const bankName = _get(props, 'initialValues.bankingDetails.bankName')

  if (!accountName && !accountNumber && !routingNumber && !bsb && !sortCode && !ifscNumber && !bankName) {
    return false
  }
  return true
}

const getOwner = (props, id) => {
  let filteredOrg = _.uniqBy(props.organizations, 'id')
  filteredOrg = filteredOrg
    .sort((a, b) => a.displayName.trim().localeCompare(b.displayName))
    .map(brand => ({ label: brand.displayName, value: brand.id }))
  const owner = filteredOrg.find(brand => brand.value === id)
  return owner ? owner : _get(props, 'initialValues.owner.id')
}

export const getDataFromProps = props => {
  const params = queryString.parse(location.search)
  const owner = params.brandId ? getOwner(props, params.brandId) : _get(props, 'initialValues.owner.id')
  const reminderEmailMinutes = _get(props, 'initialValues.reminderEmailMinutes') || ''
  const reminderEmailDate = _get(props, 'initialValues.reminderEmailDate') || ''
  let data = {
    owner,
    country: _get(props, 'event.owner.country'),
    descriptions: _get(props, 'initialValues.descriptions'),
    displayName: _get(props, 'initialValues.displayName') || '',
    shortName: _get(props, 'initialValues.shortName') || '',
    slug: _get(props, 'initialValues.slug') || '',
    bannerURL: _get(props, 'initialValues.bannerURL'),
    imageURLs: _get(props, 'initialValues.imageURLs'),
    autoBackground: !_get(props, 'initialValues.backgroundURL'),
    backgroundURL: _get(props, 'initialValues.backgroundURL'),
    useImageInsteadOfTitle: _get(props, 'initialValues.useImageInsteadOfTitle') || false,
    titleReplacementImage: _get(props, 'initialValues.titleReplacementImage') || null,
    //titleReplacementImageSvg: _get(props, 'initialValues.titleReplacementImageSvg') || null,
    startDate: _get(props, 'initialValues.startDate') || '',
    endDate: _get(props, 'initialValues.endDate') || '',
    salesStartDate: _get(props, 'initialValues.salesStartDate') || '',
    salesEndDate: _get(props, 'initialValues.salesEndDate') || '',
    hasSales: _get(props, 'initialValues.hasSales'),
    checkInStart: _get(props, 'initialValues.checkInStart') || '',
    checkInEnd: _get(props, 'initialValues.checkInEnd') || '',
    externalRedirect: _get(props, 'initialValues.externalRedirect') || '',
    flagDifferentTicketingPlatform: _get(props, 'initialValues.externalRedirect') ? true : false,
    flagCarer: _get(props, 'initialValues.flagCarer'),
    flagInvitationsOnly: _get(props, 'initialValues.flagInvitationsOnly'),
    flagRefunds: _get(props, 'initialValues.flagRefunds'),
    flagPasswordProtected: _get(props, 'initialValues.flagPasswordProtected'),
    flagValidateAge: _.get(props, 'initialValues.flagValidateAge'),
    minimumAge: _.get(props, 'initialValues.minimumAge'),
    facebookEvent: _get(props, 'initialValues.facebookEvent') || '',
    twitterHashtag: _get(props, 'initialValues.twitterHashtag') || '',
    eventType: _get(props, 'initialValues.eventType'),
    tags: _get(props, 'initialValues.tags'),
    blurredBackgroundUrl: _get(props, 'initialValues.blurredBackgroundUrl'),
    timezone: _get(props, 'initialValues.timezone'),
    currency: _get(props, 'initialValues.currency') ? _get(props, 'initialValues.currency.currency') : '',
    ogImage: _get(props, 'initialValues.ogImage'),
    twitterImage: _get(props, 'initialValues.twitterImage'),
    // payment details
    accountName: _get(props, 'initialValues.bankingDetails.accountName') || '',
    accountNumber: _get(props, 'initialValues.bankingDetails.accountNumber') || '',
    routingNumber: _get(props, 'initialValues.bankingDetails.routingNumber') || '',
    bsb: _get(props, 'initialValues.bankingDetails.bsb') || '',
    sortCode: _get(props, 'initialValues.bankingDetails.sortCode') || '',
    ifscNumber: _get(props, 'initialValues.bankingDetails.ifscNumber') || '',
    institutionNumber: _get(props, 'initialValues.bankingDetails.institutionNumber') || null,
    transitNumber: _get(props, 'initialValues.bankingDetails.transitNumber') || null,
    bankName: _get(props, 'initialValues.bankingDetails.bankName') || '',
    flagPaymentDetails: getPayoutDetailsFlagValue(props),
    // hosting details
    isNewMeeting: _get(props, 'initialValues.isNewMeeting') || false,
    flagDonation: _get(props, 'initialValues.flagDonation') || false,
    donationDetails: _get(props, 'initialValues.donationDetails') || {
      type: '',
      minAmount: null,
      amountOptions: []
    },
    embed: _get(props, 'initialValues.embed') || '',

    reminderEmailDate,
    reminderEmailMinutes,
    flagReminderEmails: !!(reminderEmailDate || reminderEmailMinutes),
    emailDateOption: reminderEmailMinutes ? 'minutesBefore' : 'onDate'
  }

  const {
    meta = {},
    provider,
    preEventContentPlaceholderType,
    preEventContentPlaceholder,
    ...rest
  } = _get(props, 'initialValues.onlineDetails') || {}
  const settings = meta.settings || {}
  const onlineDetails = {
    ...rest,
    preEventContentPlaceholderType: preEventContentPlaceholderType || '',
    preEventContentPlaceholder: preEventContentPlaceholder || '',
    autoRecording: !(!settings.autoRecording || settings.autoRecording === 'none'),
    joinBeforeHost: settings.joinBeforeHost || false,
    muteUponEntry: settings.muteUponEntry || false,
    waitingRoom: settings.waitingRoom || false,
    password: meta.password || '',
    meetingId: meta.id || '',
    provider
  }

  data = {
    ...data,
    onlineDetails,
    selectedMeeting: onlineDetails
  }

  return data
}
