import _findIndex from 'lodash/findIndex'
import _filter from 'lodash/filter'
import _get from 'lodash/get'
import _forEach from 'lodash/forEach'
import _map from 'lodash/map'
import handleActions from 'redux-actions/lib/handleActions'
import { createAsyncHandlers } from '../actions'

const initialState = {
  addons: {},
}

const FETCH_EVENT_ADDONS = createAsyncHandlers('FETCH_EVENT_ADDONS', {
  success(state, action) {
    const { eventId, addons } = action.payload
    state.addons[eventId] = addons
  },
})

const CREATE_ADDON = createAsyncHandlers('CREATE_ADDON', {
  success(state, action) {
    const { res, eventId } = action.payload

    if (state.addons[eventId]) {
      state.addons[eventId].push({ ...res, isGroup: false })
    }
  },
})

const CREATE_ADDON_GROUP = createAsyncHandlers('CREATE_ADDON_GROUP', {
  success(state, action) {
    const { res, eventId } = action.payload
    const newAddonsVariants = _get(res, 'variants', [])
    const newAddonsVariantsAdapted = _map(newAddonsVariants, variant => ({ ...variant, isGroup: false }))

    const addonsList = [{ ...res, isGroup: true }, ...newAddonsVariantsAdapted]

    if (state.addons[eventId]) {
      state.addons[eventId].push(...addonsList)
    }
  },
})

const DELETE_ADDON = createAsyncHandlers('DELETE_ADDON', {
  success(state, action) {
    const { addonId, eventId } = action.payload
    const newList = _filter(state.addons[eventId], item => item.id !== addonId)
    state.addons[eventId] = newList
  },
})

const UPDATE_ADDON = createAsyncHandlers('UPDATE_ADDON', {
  success(state, action) {
    const { res, eventId } = action.payload
    
    // Check also that should not be group-add-on
    const index = _findIndex(state.addons[eventId], el => el.id === res.id && !el.isGroup) 
    if (index !== -1) {
      state.addons[eventId][index] = { ...res, isGroup: false }
    }
  },
})

const UPDATE_ADDON_GROUP = createAsyncHandlers('UPDATE_ADDON_GROUP', {
  success(state, action) {
    const { res, eventId } = action.payload
    const eventAddOns = state.addons[eventId]
    const eventAddOnVariantsIdValues = {}
    const allVariantsData = _get(res, 'variants', [])
    const newAddedVariants = []

    //Check also that must be group-add-on
    const index = _findIndex(eventAddOns, el => el.id === res.id && el.isGroup) 
    if (index !== -1) {
      eventAddOns[index] = { ...res, isGroup: true }
    }

    _forEach(eventAddOns, addOn => {
      if (addOn.addOnGroupId) {
        eventAddOnVariantsIdValues[addOn.id] = addOn
      }
    })
    _forEach(allVariantsData, variant => {
      if (!eventAddOnVariantsIdValues[variant.id]) {
        newAddedVariants.push({ ...variant, isGroup: false })
      }
    })
    eventAddOns.push(...newAddedVariants)
  },
})

const UPDATE_ADDONS_SORTING = createAsyncHandlers('UPDATE_ADDONS_SORTING', {
  success(state, action) {
    const { eventId, addons, addOnsGroups } = action.payload
    state.addons[eventId] = [...addons, ...addOnsGroups]
  },
})

export default handleActions(
  {
    ...FETCH_EVENT_ADDONS,
    ...CREATE_ADDON,
    ...CREATE_ADDON_GROUP,
    ...UPDATE_ADDON,
    ...UPDATE_ADDON_GROUP,
    ...DELETE_ADDON,
    ...UPDATE_ADDONS_SORTING,
  },
  initialState,
)
