/* eslint-disable prefer-const */
// Re-render Optimization 15.05.2020
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import React from 'react'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'

import Notifications from '../_library/notifications/Notifications'
import LoadingBar from '../_library/LoadingBar'
import { CLEAR_SELECTED_EVENT, FETCH_EVENT } from '../../_common/redux/events/actions'

import EventSwitcher from './_library/EventSwitcher/index'
import EventPublish from './_library/EventPublish'
import PermissionDenied from '../_library/PermissionDeniedComponent'
import { PATHS } from '../constants/paths'
import { ROLES } from '../constants/roles'

import { get_event } from '../../_common/core/selectors'
import { getPermissions } from '../_theme/utils'
import { getConfig } from '../utils/configUtils'
import { RouteLeavingGuard } from '../_library/RouteLeavingGuard'

@withRouter
@connect(
  (state, props) => {
    let event = get_event(state)
    let { events, fullReady, initial: eventsInitial } = state.events
    const { user } = state.auth
    const isEventLoading = state.loading.has('FETCH_EVENT')

    const paramsId = _get(props, 'match.params.id')
    const path = _get(props, 'location.pathname')
    const selectedId = _get(event, 'id', '')

    if (paramsId !== selectedId) {
      event = null
    }
    if (event && _isEmpty(events)) {
      events = [event]
    }
    return {
      path,
      events,
      event,
      isEventLoading,
      eventsInitial,
      fullReady,
      user,
    }
  },
  { CLEAR_SELECTED_EVENT, FETCH_EVENT },
)
export default class EventContainer extends React.PureComponent {
  state = {
    isEventLoading: true,
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props
    this.loadEvent(id)
  }

  componentWillReceiveProps(nextProps) {
    const {
      match: {
        params: { id: pId },
      },
    } = this.props

    const {
      match: {
        params: { id: nId },
      },
    } = nextProps

    if (pId !== nId) {
      this.setState(
        {
          isEventLoading: true,
        },
        () => {
          this.loadEvent(nId)
        },
      )
    }
  }

  loadEvent = async eventId => {
    const { FETCH_EVENT } = this.props
    try {
      await FETCH_EVENT(eventId)
      this.setState({
        isEventLoading: false,
      })
    } catch (err) {
      // Redirect when Event was not found
      const {
        history: { replace },
      } = this.props
      if (err?.response?.status === 404) {
        replace('/events')
        return
      }

      this.setState({ isEventLoading: false })
    }
  }

  switchEvent = event => {
    const {
      history: { push },
    } = this.props
    form_helper_reset()
    push(this.props.location.pathname.replace(this.props.match.params.id, event.id))
  }

  canShowSwitcher = () => {
    const { path } = this.props

    switch (path) {
      case path.indexOf('musicad') > -1:
      case path.indexOf('musicstreamingad') > -1:
      case path.indexOf('likesad') > -1:
      case path.indexOf('track-sales') > -1:
        return false
      default:
        return true
    }
  }

  detectPermissionIsDenied = () => {
    const { event, path, user } = this.props
    const { isEventLoading } = this.state

    const externalEventPartner = event?.externalEventPartner
    const role = event?.self.role
    const permissions = getPermissions(role)
    const isAdminOrOwner = permissions.isAdminOrOwner()
    const configFile = getConfig()
    const domain = _get(configFile, '*.domain')
    const userIsTFStaff = user && user.tfStaff
    const domains = _get(configFile, 'global.CONSTANTS.DOMAINS', [])
    let isAllowed = false

    for (const allowedDomain of domains) {
      if (allowedDomain === externalEventPartner) {
        isAllowed = true
      }
    }
    const isPermissionDenied =
      ((externalEventPartner && !(externalEventPartner === domain || isAllowed)) ||
        (event && ROLES.is_support_or_partial_support(role) && !PATHS.isIncludePaths(path, [103])) ||
        (role && !isAdminOrOwner && PATHS.isEditLevelPath(path)) ||
        (!role && !userIsTFStaff) ||
        (PATHS.isIncludePaths(path, [303]) && !ROLES.is_tf_support(user.tfRole))) &&
      !isEventLoading
    return isPermissionDenied
  }

  routerWillLeave = () => !form_helper_isEditted()

  render() {
    const {
      match: {
        params: { id },
      },
      eventsInitial,
      fullReady,
      events,
      event,
    } = this.props
    const { isEventLoading } = this.state

    const showSwitcher = this.canShowSwitcher()
    const isPermissionDeniedForEvent = this.detectPermissionIsDenied()

    return (
      <div className="body-main">
        <RouteLeavingGuard routerWillLeave={this.routerWillLeave} />
        {isPermissionDeniedForEvent ? (
          <PermissionDenied />
        ) : (
          <>
            <Notifications />
            <div>
              <div className="body-panel-header">
                <div className="left">
                  <div className="description">
                    {showSwitcher && !_isEmpty(events) && (
                      <EventSwitcher
                        currentEventId={id}
                        currentEvent={event}
                        events={events}
                        fullReady={fullReady}
                        active={!eventsInitial}
                        onClickEvent={this.switchEvent}
                        brandDetails={event?.owner}
                      />
                    )}
                  </div>
                </div>
              </div>
              {!!event && !isEventLoading && <EventPublish />}
              <div className="body-panel-spacing" />
              <div className="body-panel-content">
                {isEventLoading ? (
                  <LoadingBar title="Hold tight! We're getting your event's details..." />
                ) : event ? (
                  this.props.children
                ) : null}
              </div>
            </div>
          </>
        )}
      </div>
    )
  }
}
