/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import React, { isValidElement } from 'react'
import _map from 'lodash/map'
import _isEqual from 'lodash/isEqual'
import _find from 'lodash/find'
import _filter from 'lodash/filter'
import TableRow from './TableRow'
import { getSortedRows } from './utils'
import Clipboard from '../../_library/Clipboard'
import { shortid } from '../../../_common/core/rand'
import { showAxiosError } from '../../utils/messenger'
import DnDTableBody from './DnDTableBody'

export default class SortableTable extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      sortBy: props.sortBy || null,
      tableColumns: null,
      isDesktop: window.innerWidth > 767,
      data: [],
      dataWithDetailsRows: [],
      expandedRowId: props.expandedRowId || null,
      isSortedFromOutside: false,
      loadingRowData: {},
      hasHiddenRows: false,
    }
  }

  componentDidMount() {
    this.updateTableData()
    window.addEventListener('resize', this.updateScreenSizeStatus)
  }

  componentDidUpdate(prevProps) {
    const { disableInnerSort, data, sortBy, detailsRows } = this.props
    const { data: stateData } = this.state

    if (disableInnerSort && !_isEqual(data, stateData)) {
      const dataWithDetailsRows = this.addDetailsRow(data, null, true)

      this.setState({
        data,
        dataWithDetailsRows,
        sortBy,
        detailsRows,
      })
    }

    if (!disableInnerSort) {
      this.updateTableData(prevProps)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateScreenSizeStatus)
  }

  updateTableData = prevProps => {
    const {
      tableColumns,
      data,
      enableCopyTable,
      actions,
      statuses,
      enableDefaultActions,
      enableSort,
      detailsRows,
      dataForCopy,
      actionsLabel,
      actionsClassName,
      calculatedColumns,
      isAsyncDetails,
      className,
      isDnDEnabled,
      hideRows,
      onShowMoreCb,
      showMore,
      id,
      enableDetailsRowsReinitialize,
    } = this.props

    const { expandedRowId, dataWithDetailsRows, isSortedFromOutside, shouldUpdateColumns, hasHiddenRows } =
      this.state
    let updatedTableColumns = [...tableColumns]

    if (
      (!this.state.tableColumns && tableColumns && !dataWithDetailsRows.length) ||
      !_isEqual(tableColumns, prevProps.tableColumns) ||
      shouldUpdateColumns
    ) {
      // add actions column
      if ((actions && actions.length) || enableDefaultActions) {
        updatedTableColumns.push({
          label: actionsLabel || '',
          key: 'actions',
          isSortable: false,
          className: `center actions ${actionsClassName || ''}`,
        })
      }
      // add Clipboard column
      if (data.length && enableCopyTable) {
        const sortByObj = isSortedFromOutside
          ? this.props.sortBy
          : this.state.sortBy || { column: tableColumns[0].key, asc: false }
        updatedTableColumns.push({
          label: (
            <Clipboard
              columns={tableColumns}
              data={dataForCopy || getSortedRows(data, sortByObj, calculatedColumns)}
            />
          ),
          key: 'clipboard',
          isSortable: false,
          className: `column-clipboard ${className || ''}`,
        })
      }
      // add statuses column
      if (statuses && statuses.length) {
        updatedTableColumns.unshift({
          label: 'Status',
          key: 'status',
          isSortable: false,
          className: 'status-column',
        })
      }
      // add expand column
      if ((detailsRows && detailsRows.length) || isAsyncDetails || enableDetailsRowsReinitialize) {
        updatedTableColumns.unshift({
          label: '',
          key: 'expand',
          isSortable: false,
          className: 'toggle-expand',
        })
      }

      this.setState({
        tableColumns: updatedTableColumns,
      })
    } else {
      updatedTableColumns = [...this.state.tableColumns]
    }

    const sortBy = {
      column: tableColumns[0].key,
      asc: false,
    }
    const sortByObj = isSortedFromOutside
      ? this.props.sortBy
      : this.state.sortBy || this.props.sortBy || sortBy

    if (enableSort) {
      if (
        !_isEqual(getSortedRows(data, sortByObj, calculatedColumns), this.state.data) ||
        (!_isEqual(this.state.sortBy, this.props.sortBy) && isSortedFromOutside) ||
        !_isEqual(detailsRows, this.state.detailsRows)
      ) {
        const sortedData = getSortedRows(data, sortByObj, calculatedColumns)
        const dataWithDetailsRows = this.addDetailsRow(sortedData, null, true)
        this.setState({
          data: sortedData,
          dataWithDetailsRows,
          sortBy: sortByObj,
          isSortedFromOutside: false,
          detailsRows,
          shouldUpdateColumns: true,
        })
      } else {
        this.setState({ shouldUpdateColumns: false })
      }
    } else if (isDnDEnabled) {
      if (!_isEqual(data, this.state.data) || !_isEqual(detailsRows, this.state.detailsRows)) {
        this.setState({
          data,
          detailsRows,
          dataWithDetailsRows: this.addDetailsRow(data, null, true),
        })
      }
    } else {
      if (
        !_isEqual(getSortedRows(data, sortByObj, calculatedColumns), this.state.data) ||
        !_isEqual(detailsRows, this.state.detailsRows)
      ) {
        if (onShowMoreCb && !showMore[id]) {
          if (hideRows && !hasHiddenRows) {
            const dataWithDetailsRows = this.addDetailsRow(
              getSortedRows(data, sortByObj, calculatedColumns),
              null,
              true,
            )
            const filteredData = dataWithDetailsRows.filter(row => row?.totalVisitors > 1)
            this.setState({
              data: getSortedRows(data, sortByObj, calculatedColumns),
              dataWithDetailsRows: filteredData,
              hasHiddenRows: filteredData?.length < dataWithDetailsRows?.length,
              shouldUpdateColumns: true,
            })
          }
        } else {
          this.setState({
            data: getSortedRows(data, sortByObj, calculatedColumns),
            detailsRows,
            dataWithDetailsRows: this.addDetailsRow(
              getSortedRows(data, sortByObj, calculatedColumns),
              null,
              true,
            ),
            shouldUpdateColumns: true,
          })
        }
      } else {
        this.setState({ shouldUpdateColumns: false })
      }
    }

    if (data && expandedRowId && !_find(data, item => item.id === expandedRowId)) {
      this.setState({ expandedRowId: null })
    }
  }
  onShowMore = id => {
    const { data, onShowMoreCb } = this.props
    this.addDetailsRow(getSortedRows(data), null, true)
    this.setState({
      dataWithDetailsRows: this.addDetailsRow(getSortedRows(data), null, true),
      hasHiddenRows: false,
    })
    onShowMoreCb(id)
  }

  addDetailsRow = (rows, id, keepRowExpanded) => {
    const { detailsRows } = this.props
    const { expandedRowId } = this.state
    let rowsWithDetails = []

    if (!id && !expandedRowId) return rows

    if (id !== expandedRowId) {
      const rowId = keepRowExpanded ? expandedRowId : id
      _map(rows, (rowItem, rowIndex) => {
        const detailRow = _find(detailsRows, detailRowItem => rowId === detailRowItem.id)
        if (detailRow && detailRow.id === rowItem.id) {
          rowsWithDetails.push(rowItem, { ...detailRow, detailRowIndex: rowIndex })
        } else {
          rowsWithDetails.push(rowItem)
        }
      })

      if (expandedRowId && !keepRowExpanded) {
        // remove previous expnaded detail row
        rowsWithDetails = _filter(
          rowsWithDetails,
          rowItem => !(rowItem.id === expandedRowId && rowItem.type === 'detailRow'),
        )
      }
    } else {
      rowsWithDetails = _filter(rows, rowItem => rowItem.id !== id || rowItem.type !== 'detailRow')
    }

    return rowsWithDetails
  }

  updateScreenSizeStatus = () => {
    const isBigScreen = window.innerWidth > 767
    if (this.state.isDesktop !== isBigScreen) {
      this.setState({ isDesktop: isBigScreen })
    }
  }

  getDefaultActions = data => {
    if (data.type === 'detailRow') return
    const { handleEdit, handleActivate, handleDeactivate, isTimeSlotTickets } = this.props
    const active = data.active !== undefined ? data.active : data.enabled
    return [
      {
        label: 'Edit',
        className: 'btn btn-primary',
        icon: 'fa fa-pencil fa-fw',
        onClick: data => handleEdit(data, isTimeSlotTickets),
      },
      {
        label: data => (active ? 'Deactivate' : 'Activate'),
        className: data => (active ? 'btn btn-seablue' : 'btn btn-ok'),
        icon: data => (active ? 'fa fa-ban fa-fw' : 'fa fa-cog fa-fw'),
        onClick: data =>
          active ? handleDeactivate(data, isTimeSlotTickets) : handleActivate(data, isTimeSlotTickets),
      },
    ]
  }

  handleColumnClick = e => {
    const { calculatedColumns } = this.props

    if (e.sort) {
      e.sort(e)
      this.setState(() => ({
        isSortedFromOutside: true,
        expandedRowId: null,
      }))
      return
    }

    if (!e.isSortable) return
    this.setState(prevState => {
      const sortBy = {
        column: e.key,
        asc: !prevState.sortBy || prevState.sortBy.column !== e.key ? true : !prevState.sortBy.asc,
      }
      const data = getSortedRows(prevState.data, sortBy, calculatedColumns)
      const dataWithDetailsRows = this.addDetailsRow(data)
      return {
        ...prevState,
        sortBy,
        data,
        dataWithDetailsRows,
        expandedRowId: null,
      }
    })
  }

  getSortIcon = (sortBy, column) => {
    const sortByObj = column.sort ? this.props.sortBy : sortBy
    if (!sortByObj || (sortByObj && sortByObj.column !== column.key)) {
      return ''
    }
    return sortByObj.isLoading
      ? 'fa fa-spinner fa-spin'
      : sortByObj.asc
      ? 'fa fa-caret-up'
      : 'fa fa-caret-down'
  }

  handleRowExpand = async expandedRow => {
    const { isAsyncDetails, getDetailRowData } = this.props
    const { expandedRowId } = this.state

    if (isAsyncDetails) {
      if (expandedRow.id === expandedRowId) {
        this.setState(prevState => ({
          ...prevState,
          expandedRowId: null,
          dataWithDetailsRows: this.addDetailsRow(prevState.dataWithDetailsRows, null),
        }))

        return
      }

      this.setState({
        loadingRowData: {
          [expandedRow.id]: true,
        },
      })
      try {
        await getDetailRowData(expandedRow)
        this.setState(prevState => ({
          ...prevState,
          expandedRowId: expandedRow.id,
          dataWithDetailsRows: this.addDetailsRow(prevState.dataWithDetailsRows, expandedRow.id),
          loadingRowData: {},
        }))
      } catch (err) {
        showAxiosError(err)
        this.setState({
          loadingRowData: {},
          expandedRowId: null,
        })
      }
    } else {
      this.setState(prevState => {
        const id = prevState.expandedRowId === expandedRow.id ? null : expandedRow.id
        return {
          ...prevState,
          expandedRowId: id,
          dataWithDetailsRows: this.addDetailsRow(prevState.dataWithDetailsRows, expandedRow.id),
        }
      })
    }
  }

  getFootbarRow = () => {
    const { footbar } = this.props

    const { tableColumns, dataWithDetailsRows } = this.state
    const rowCells = []
    _map(tableColumns, (column, index) => {
      const footbarItem = _find(footbar.columns, item => (item.key || item) === column.key)
      let columnTotal = 0
      // eslint-disable-next-line no-underscore-dangle
      let _FootbarComponent = null
      if (footbarItem) {
        _map(dataWithDetailsRows, item => {
          if (item.type !== 'detailRow') {
            const val = parseFloat(item[footbarItem.sourceKey || footbarItem.key || footbarItem])
            columnTotal += isNaN(val) ? 0 : val
          }
        })

        if (footbarItem.component) {
          _FootbarComponent = footbarItem.component
        }
      }

      rowCells.splice(
        index,
        0,
        <td key={shortid()}>
          {index === 0 ? (
            <strong>
              {footbar.label} {footbar.value || ''}
            </strong>
          ) : footbarItem ? (
            isValidElement(_FootbarComponent) ? (
              footbarItem.component
            ) : footbarItem.normalizer ? (
              footbarItem.normalizer(columnTotal)
            ) : (
              columnTotal || ''
            )
          ) : (
            ''
          )}
        </td>,
      )
    })

    return rowCells
  }

  getColumnLabel = column => column.labelComponent || column.label

  render() {
    const {
      e2e_test_id,
      actions,
      enableSort,
      className,
      statuses,
      enableDnd,
      editModalComponent,
      enableDefaultActions,
      footbar,
      disableMobileView,
      hideHeader,
      onDragEnd,
      isDnDEnabled,
      parentTableClass = '',
      rowTableClass = '',
      id,
    } = this.props
    const {
      tableColumns,
      sortBy,
      isDesktop,
      dataWithDetailsRows,
      expandedRowId,
      loadingRowData,
      hasHiddenRows,
    } = this.state

    return (
      <div className={'row ' + rowTableClass}>
        {editModalComponent ? editModalComponent : null}
        <div className="col-xs-12">
          <div className={'table-responsive ' + parentTableClass}>
            <table className={`sortable-table ${className || ''}`} data-e2e-test-id={e2e_test_id}>
              {!hideHeader && (
                <thead>
                  <tr>
                    {(isDesktop || disableMobileView) && tableColumns
                      ? _map(tableColumns, column => (
                          <th
                            data-e2e-test-id={column.key}
                            key={column.key}
                            aria-hidden={true}
                            className={
                              (column.className || '') + (enableSort && column.isSortable ? ' sort' : '')
                            }
                            onClick={() =>
                              enableSort && column.isSortable ? this.handleColumnClick(column) : null
                            }
                          >
                            {this.getColumnLabel(column)}
                            {enableSort && column.isSortable && (
                              <i
                                className={'sort-direction ' + this.getSortIcon(sortBy, column)}
                                style={column.key === 'clipboard' ? { marginLeft: 0 } : {}}
                                aria-hidden="true"
                              />
                            )}
                          </th>
                        ))
                      : null}
                  </tr>
                </thead>
              )}
              {isDnDEnabled ? (
                <DnDTableBody
                  disableMobileView={disableMobileView}
                  onDragEnd={onDragEnd}
                  data={dataWithDetailsRows}
                  isDesktop={isDesktop}
                  columns={tableColumns}
                  actions={actions}
                  statuses={statuses}
                  expandedRowId={expandedRowId}
                  loadingRowData={loadingRowData}
                  handleRowExpand={this.handleRowExpand}
                />
              ) : (
                <tbody>
                  {_map(dataWithDetailsRows, (row, index) => (
                    <TableRow
                      key={shortid()}
                      data={row}
                      columns={tableColumns}
                      actions={enableDefaultActions ? this.getDefaultActions(row) : actions}
                      isDesktop={isDesktop}
                      statuses={statuses}
                      enableDnd={enableDnd}
                      handleRowExpand={this.handleRowExpand}
                      expandedRowId={expandedRowId}
                      loadingRowData={loadingRowData}
                      disableMobileView={disableMobileView}
                      rowIndex={index}
                      e2e_test_id={e2e_test_id}
                    />
                  ))}
                </tbody>
              )}
              {!!footbar && (
                <tfoot>
                  <tr>{this.getFootbarRow()}</tr>
                </tfoot>
              )}
            </table>
            {hasHiddenRows && (
              <div className="sortable-show-more">
                <span onClick={() => this.onShowMore(id)} onKeyDown={() => this.onShowMore(id)}>
                  Show more
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}
