import _orderBy from 'lodash/orderBy'
import _map from 'lodash/map'
import _result from 'lodash/result'
import _get from 'lodash/get'
import React from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'

import Card from '../../_library/Card'
import Button from '../../_library/Button'
import { JSONDatatable, DATATABLE, TYPE_FROM_ARRAY } from '../../_library/JSONDatatable'
import EmptyBar from '../../_library/EmptyBar'
import LoadingBar from '../../_library/LoadingBar'
import modalStyle from '../../../_common/core/modalStyle'
import {
  FETCH_EMAIL_TEMPLATES,
  CREATE_EMAIL_TEMPLATE,
  UPDATE_EMAIL_TEMPLATE,
} from '../../../_common/redux/emailtemplates/actions'
import { DisableSectionByPermissionWrapper } from '../../hoc'

import { EditEmailTemplateForm } from './EditEmailTemplateForm'
import { NewEmailTemplateForm } from './NewEmailTemplateForm'
import { EmailTemplateRow } from './EmailTemplateRow'

import { get_email_templates } from '../../../_common/core/selectors'

// constants
import { EMAIL_TEMPLATE_TYPES } from '../../constants/emailTemplatesTypes'
import { getTitle } from '../../utils/getTitle'

@connect(
  state => {
    const emailTemplates = get_email_templates(state)

    return {
      emailTemplates
    }
  },
  {
    FETCH_EMAIL_TEMPLATES,
    CREATE_EMAIL_TEMPLATE,
    UPDATE_EMAIL_TEMPLATE,
  },
)
export default class EmailTemplates extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loadingEmailTemplates: false,
      previewTemplateContentModalOpen: false,
      templatePreviewed: null,
      editTemplateModalOpen: false,
      templateEdited: null,
      confirmModalOpen: false,
      confirmModalMessage: '',
      confirmCallback: null,
      showNewTemplate: false,
    }

    this.validateData = this.validateData.bind(this)
    this.getFilteredRows = this.getFilteredRows.bind(this)
    this.getSortedRows = this.getSortedRows.bind(this)
    this.getTableData = this.getTableData.bind(this)
    this.getClipboardText = this.getClipboardText.bind(this)
    this.createTemplate = this.createTemplate.bind(this)
    this.closePreviewTemplateContentModal = this.closePreviewTemplateContentModal.bind(this)
    this.closeEditTemplateModal = this.closeEditTemplateModal.bind(this)
    this.updateTemplate = this.updateTemplate.bind(this)
    this.closeConfirmModal = this.closeConfirmModal.bind(this)
    this.confirmInConfirmModal = this.confirmInConfirmModal.bind(this)
    this.showNewTemplateSection = this.showNewTemplateSection.bind(this)

    const { originData, configs } = this.props
    this.id = originData?.id

    // Set document title
    const displayName = originData?.displayName
    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])
  }

  componentDidMount() {
    this.fetchEmailTemplates()
  }

  fetchEmailTemplates() {
    const { FETCH_EMAIL_TEMPLATES, originType } = this.props
    const loadingSetter = val => () => this.setState({ loadingEmailTemplates: val })

    FETCH_EMAIL_TEMPLATES(this.id, originType).finally(loadingSetter(false))

    loadingSetter(true)()
  }

  validateData(data, index) {
    return data
  }

  getFilteredRows(rows, search) {
    return rows
  }

  getSortedRows(rows_filtered, sort) {
    if (sort) {
      const dir = sort.asc ? 'asc' : 'desc'
      switch (sort.index) {
        case 0:
          rows_filtered = _orderBy(rows_filtered, t => t.enabled, dir)
          break
        case 1:
          rows_filtered = _orderBy(rows_filtered, t => t.name.toLowerCase(), dir)
          break
        case 2:
          rows_filtered = _orderBy(rows_filtered, t => t.description.toLowerCase(), dir)
          break
        case 3:
          rows_filtered = _orderBy(rows_filtered, t => t.subject.toLowerCase(), dir)
          break
        case 4:
          rows_filtered = _orderBy(rows_filtered, t => t.type.toLowerCase(), dir)
          break
        default:
          break
      }
    }
    return rows_filtered
  }

  getTableData(datatable, rows_filtered, sort) {
    const clipboard_text = this.getClipboardText(rows_filtered)
    const clipboard = datatable.getClipboardColumn(datatable, clipboard_text)

    const content_header = datatable.getHeaderRow(
      datatable,
      [
        { title: 'Status', sort: true },
        { title: 'Name', sort: true },
        { title: 'Description', sort: true },
        { title: 'Subject', sort: true },
        { title: 'Template Type', sort: true },
        { title: clipboard, sort: false, className: 'column-clipboard' },
      ],
      sort,
    )

    return rows_filtered.length != 0 ? (
      <table className="table emailtemplates-table">
        <thead>{content_header}</thead>
        <tbody>
          {rows_filtered.map(row => (
            <EmailTemplateRow
              key={row.id}
              template={row}
              previewContent={this.previewTemplateContent.bind(this, row)}
              enable={this.enableTemplate.bind(this, row)}
              disable={this.disableTemplate.bind(this, row)}
              confirm={this.openConfirmModal.bind(this)}
              edit={this.editTemplate.bind(this, row)}
            />
          ))}
        </tbody>
      </table>
    ) : (
      <EmptyBar />
    )
  }

  getClipboardText(rows_filtered) {
    let ret = ''
    // eslint-disable-next-line no-useless-concat
    ret += 'Name' + '\t' + 'Description' + '\t' + 'Subject' + '\n' + 'Template Type' + '\n'
    _map(rows_filtered, t => {
      ret += t.name + '\t' + t.description + '\t' + t.subject + '\n' + EMAIL_TEMPLATE_TYPES[t.type] + '\n'
    })
    return ret
  }

  previewTemplateContent(template) {
    this.setState({
      previewTemplateContentModalOpen: true,
      templatePreviewed: template,
    })
  }

  closePreviewTemplateContentModal() {
    this.setState({
      previewTemplateContentModalOpen: false,
    })
  }

  enableTemplate(template) {
    const { UPDATE_EMAIL_TEMPLATE, originType } = this.props

    const form = JSON.parse(JSON.stringify(template))
    form.enabled = '1'

    return UPDATE_EMAIL_TEMPLATE(template.id, this.id, form, originType)
      .catch(err => Promise.reject(_result(err, 'message', err)))
      .then(v => {
        Messenger().post({
          type: 'success',
          message: 'Successfully enabled!',
          hideAfter: 3,
          showCloseButton: true,
        })
        return v
      })
  }

  disableTemplate(template) {
    const { UPDATE_EMAIL_TEMPLATE, originType } = this.props

    const form = JSON.parse(JSON.stringify(template))
    form.enabled = '0'

    return UPDATE_EMAIL_TEMPLATE(template.id, this.id, form, originType)
      .catch(err => Promise.reject(_result(err, 'message', err)))
      .then(v => {
        Messenger().post({
          type: 'success',
          message: 'Successfully disabled!',
          hideAfter: 3,
          showCloseButton: true,
        })
        return v
      })
  }

  editTemplate(template) {
    this.setState({
      editTemplateModalOpen: true,
      templateEdited: template,
    })
  }

  closeEditTemplateModal() {
    this.setState({
      editTemplateModalOpen: false,
    })
  }

  updateTemplate(form) {
    if (form.content_mode === 'zip') {
      delete form.body
    }
    if (form.content_mode === 'body') {
      delete form.zip
    }
    delete form.content_mode

    const { UPDATE_EMAIL_TEMPLATE, originType } = this.props

    return UPDATE_EMAIL_TEMPLATE(form.id, this.id, form, originType)
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
      .then(v => {
        Messenger().post({
          type: 'success',
          message: 'Successfully updated!',
          hideAfter: 3,
          showCloseButton: true,
        })
        this.closeEditTemplateModal()
        this.fetchEmailTemplates()
        return v
      })
  }

  showNewTemplateSection() {
    this.setState({
      showNewTemplate: true,
    })
  }

  createTemplate(form) {
    if (form.content_mode === 'zip') {
      delete form.body
    }
    if (form.content_mode === 'body') {
      delete form.zip
    }
    delete form.content_mode

    const { CREATE_EMAIL_TEMPLATE, originType } = this.props
    return CREATE_EMAIL_TEMPLATE(this.id, form, originType)
      .catch(err => {
        this.setState({
          showNewTemplate: false,
        })
        return Promise.reject(_result(err, 'toFieldErrors', err))
      })
      .then(v => {
        Messenger().post({
          type: 'success',
          message: 'Successfully created!',
          hideAfter: 3,
          showCloseButton: true,
        })
        this.setState({
          showNewTemplate: false,
        })
        this.fetchEmailTemplates()
        return v
      })
  }

  openConfirmModal(message, callback) {
    this.setState({
      confirmModalOpen: true,
      confirmModalMessage: message,
      confirmCallback: callback,
    })
  }

  closeConfirmModal() {
    this.setState({
      confirmModalOpen: false,
    })
  }

  confirmInConfirmModal() {
    this.state.confirmCallback()
    this.closeConfirmModal()
  }

  render() {
    const {
      loadingEmailTemplates,
      previewTemplateContentModalOpen,
      templatePreviewed,
      editTemplateModalOpen,
      templateEdited,
      showNewTemplate,
      confirmModalOpen,
      confirmModalMessage,
    } = this.state
    const { emailTemplates, originType, configs } = this.props
    
    return (
      <DisableSectionByPermissionWrapper>
        <div className="EmailTemplates">
          <Card title={'Email Templates'}>
            {loadingEmailTemplates && (
              <LoadingBar
                key="loadingbar"
                title={`Hold tight! We're getting your ${originType}'s email templates...`}
              />
            )}
            {!loadingEmailTemplates && (
              <JSONDatatable
                type={TYPE_FROM_ARRAY}
                data={emailTemplates}
                validateData={this.validateData}
                getFilteredRows={this.getFilteredRows}
                getSortedRows={this.getSortedRows}
                getTableData={this.getTableData}
                getClipboardText={this.getClipboardText}
                usePagination={false}
                loadingBarTitle={`Hold tight! We're getting your ${originType}'s email templates...`}
              >
                <div ref={DATATABLE} />
              </JSONDatatable>
            )}
          </Card>
          {!showNewTemplate && (
            <div
              aria-hidden={true}
              className="btn btn-primary btn-shadow btn-shownewtemplate"
              onClick={this.showNewTemplateSection}
            >
              Add New Template
            </div>
          )}
          {showNewTemplate && (
            <Card title={'New Template'}>
              <NewEmailTemplateForm
                onCancel={() => this.setState({ showNewTemplate: false })}
                onSubmit={this.createTemplate}
                configs={_get(configs, 'children.NewEmailTemplateForm')}
              />
            </Card>
          )}
          <Modal
            className="modal-dialog modal-trans"
            style={modalStyle}
            isOpen={!!previewTemplateContentModalOpen}
            contentLabel="Modal"
            onRequestClose={this.closePreviewTemplateContentModal}
            closeTimeoutMS={150}
            ariaHideApp={false}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div>
                  <div className="modal-header">
                    <p className="h4 text-compact">Email Template Content</p>
                  </div>
                  <div className="template-content-preview">
                    {templatePreviewed && templatePreviewed.body && (
                      <div dangerouslySetInnerHTML={{ __html: templatePreviewed.body }} />
                    )}
                    {templatePreviewed && templatePreviewed.preview_url && (
                      <iframe
                        title="email_templates"
                        src={templatePreviewed.preview_url}
                        width="100%"
                        height="400px"
                        frameBorder="0"
                      />
                    )}
                  </div>
                  <div className="modal-footer">
                    <div className="btn-toolbar btn-toolbar-right">
                      <Button
                        className="btn btn-default"
                        type="button"
                        onClick={this.closePreviewTemplateContentModal}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            className="modal-dialog modal-trans"
            style={modalStyle}
            isOpen={!!editTemplateModalOpen}
            contentLabel="Modal"
            onRequestClose={this.closeEditTemplateModal}
            closeTimeoutMS={150}
            ariaHideApp={false}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div>
                  <div className="modal-header">
                    <p className="h4 text-compact">Edit Template</p>
                  </div>
                  <div className="emailtemplate-edit-modal">
                    {templateEdited && (
                      <EditEmailTemplateForm
                        initialValues={{
                          id: templateEdited.id,
                          type: templateEdited.type,
                          name: templateEdited.name,
                          description: mediumEditor_revert(templateEdited.description),
                          subject: templateEdited.subject,
                          body: templateEdited.body,
                          zip: templateEdited.preview_url,
                          content_mode: templateEdited.body ? 'body' : 'zip',
                        }}
                        onSubmit={this.updateTemplate}
                        onCancel={this.closeEditTemplateModal}
                        configs={_get(configs, 'children.EditEmailTemplateForm')}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            className="modal-dialog modal-trans"
            style={modalStyle}
            isOpen={!!confirmModalOpen}
            contentLabel="Modal"
            onRequestClose={this.closeConfirmModal}
            closeTimeoutMS={150}
            ariaHideApp={false}
          >
            <div className="modal-dialog">
              <div className="modal-content">
                <div>
                  <div className="modal-header">
                    <p className="h4 text-compact">Are you sure?</p>
                  </div>
                  <div className="modal-body">
                    <p>{confirmModalMessage}</p>
                  </div>
                  <div className="modal-footer">
                    <div className="btn-toolbar btn-toolbar-right">
                      <Button
                        className="btn btn-success btn-shadow"
                        type="button"
                        onClick={this.confirmInConfirmModal}
                      >
                        Yes
                      </Button>
                      <Button
                        className="btn btn-cancel btn-shadow"
                        type="button"
                        onClick={this.closeConfirmModal}
                      >
                        No
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </DisableSectionByPermissionWrapper>
    )
  }
}
