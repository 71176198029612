import React from 'react'
import Button from './Button'
import QRCode from 'qrcode.react'

const DownloadQrCodeButton = props => {
  const {
    label = 'Download QR Code',
    className = 'btn btn-primary',
    value = '',
    size = 256,
    id = 'qr-code-' + Math.floor(Math.random() * 100000)
  } = props

  const downloadQR = () => {
    const canvas = document.getElementById(id)
    const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream')
    const downloadLink = document.createElement('a')
    downloadLink.href = pngUrl
    downloadLink.download = `${id}.png`
    document.body.appendChild(downloadLink)
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }

  return (
    <Button style={{ display: 'inline-flex' }} className={className} onClick={downloadQR}>
      <QRCode
        id={id}
        value={value}
        style={{ display: 'none' }}
        size={size}
        includeMargin={true}
        level={'H'}
      />
      <img alt="nodata" src={asset('/resources/images/download-ico.svg')} />
      {label}
    </Button>
  )
}
export default DownloadQrCodeButton