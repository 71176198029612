import { ENV } from '../constants/env'

export const DefaultConfigs = {
  global: {
    appearance: {
      fontFamily: 'Open Sans',
    },
    CONSTANTS: {
      DOMAINS: ['fabric'],
      BASE_URL: ENV.API_CONSUMER,
    },
    behavior: {
      skipIDVerificationStep: false,
      displayUsersnap: true,
      hideAppcuesSurvey: false,
    },
    baseURL: ENV.API_CONSUMER,
  },
  '*': {
    domain: 'ttf',
    messages: {
      documentTitle: 'The Ticket Fairy Dashboard',
    },
    appearance: {
      icons: {
        favicon: '/resources/images/favicon.ico',
      },
      assets: {
        headerLogo: '/resources/images/ttf-logo.png',
        logo: '/resources/images/ttf-logo.png',
      },
      classes: {
        header: '',
        logoLabel: 'ttf-powered-text',
      },
    },
  },
  '/': {
    id: 'HomePage',
    messages: {
      documentTitle: 'Home - The Ticket Fairy Dashboard',
    },
  },
  '/ads': {
    id: 'AdsPage',
    messages: {
      documentTitle: 'Ads - The Ticket Fairy Dashboard',
    },
  },
  '/event/:id/*': {
    id: 'EventAllTabs',
    appearance: {
      isBudgetVisible: true,
      isCredentialsVisible: true,
      isPreregistrationVisible: true,
      isFAQVisible: true,
      isMusicVisible: true,
      isPsychographicsVisible: true,
      isGamingVisible: true,
      isDevicesVisible: true,
      isDemographicsVisible: true,
      isGeographicsVisible: true,
      isTalentVisible: true,
      isSupportVisible: true,
    },
  },
  '/event/:id/details': {
    id: 'EventDetails',
    messages: {
      documentTitle: 'Details - {displayName} - The Ticket Fairy Dashboard',
    },
    children: {
      EventForm: {
        appearance: {
          showEventPayoutDetails: true,
          showEventCustomConfirmationText: false,
        },
        children: {
          EventImages: {
            appearance: {
              showUseAutomaticallyGeneratedBackgroundToggle: true,
              showUsetransparentLogoInsteadOfEventNameToggle: true,
            },
          },
          EventSettings: {
            showRequireNTFs: true,
            showRefundsAllowed: true,
            showFreeCarerTicketForDisabledGuests: true,
          },
          EventVenue: {
            children: {
              EventVenueFlags: {
                hideSendAlternativeInformationForRSVPs: false,
                hideDisabledAccess: false,
                showApplyTax: true,
                messages: {
                  hideToggleLabel: 'Hide this venue from customers',
                },
              },
            },
          },
          EventPaymentMethod: {
            displaySection: true,
          },
          EventAlternativeViews: {
            displaySection: true,
            behavior: {
              eventUrl: `${ENV.API_CONSUMER}/event/`,
            },
          },
          EventUrlRow: {
            behavior: {
              eventUrl: `${ENV.API_CONSUMER}/event/`,
            },
          },
          EventUrlField: {
            behavior: {
              eventUrl: `${ENV.API_CONSUMER}/event/`,
            },
          },
          EventDetails: {
            children: {
              EventUrlRow: {
                appearance: {
                  showEmbedCodeButton: true,
                },
              },
            },
          },
        },
      },
      AlternativeViewForm: {
        children: {
          AlternativeViewEventVenue: {
            messages: {
              hideToggleLabel: 'Hide this venue from customers',
            },
          },
        },
      },
      BrandForm: {
        appearance: {
          showAdvancedOptions: true,
        },
        children: {
          BrandNameDetails: {
            behavior: {
              baseURL: `${ENV.API_CONSUMER}/events/`,
            },
          },
        },
      },
    },
  },
  '/event/:id/musicad/:audienceItemId': {
    id: 'NewAdPage',
    messages: {
      documentTitle: 'New Facebook AD - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/event/:id/musicstreamingad/:audienceItemId': {
    id: 'NewAdPage',
    messages: {
      documentTitle: 'New Facebook AD - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/event/:id/likesad/:audienceItemId': {
    id: 'NewAdPage',
    messages: {
      documentTitle: 'New Facebook AD - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/account': {
    id: 'AccountSettingsPage',
    messages: {
      documentTitle: 'Account Settings - The Ticket Fairy Dashboard',
    },
  },
  '/team-invitations': {
    id: 'ConfirmTeamInvitationPage',
    messages: {
      documentTitle: 'Team Invitation - The Ticket Fairy Dashboard',
    },
  },
  '/signin': {
    id: 'LoginPage',
    messages: {
      documentTitle: 'Log In - The Ticket Fairy Dashboard',
    },
    appearance: {
      showBackgroundVideo: true,
    },
  },
  '/reset-password': {
    id: 'ResetPasswordPage',
    messages: {
      documentTitle: 'Reset Password - The Ticket Fairy Dashboard',
    },
    assets: {
      logo: '/resources/images/ttf-logo.png',
    },
  },
  '/sign-up': {
    id: 'SignUpPage',
    messages: {
      documentTitle: 'Sign Up - The Ticket Fairy Dashboard',
    },
  },
  '/brand/:id/demographics': {
    id: 'BrandAudienceDemoGraphics',
    messages: {
      documentTitle: 'Demographics - {displayName} - The Ticket Fairy Dashboard',
    },
    appearance: {
      alwaysDisplaySection: true,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
    },
  },
  '/brand/:id/music': {
    id: 'BrandAudienceMusic',
    messages: {
      documentTitle: 'Music - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/brand/:id/musicstreaming': {
    id: 'BrandAudienceMusicStreaming',
    messages: {
      documentTitle: 'Music Streaming - {displayName} - The Ticket Fairy Dashboard',
    },
    appearance: {
      alwaysDisplaySection: true,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
    },
  },
  '/brand/:id/details': {
    id: 'BrandDetails',
    messages: {
      documentTitle: 'Details - {displayName} - The Ticket Fairy Dashboard',
    },
    children: {
      BrandForm: {
        appearance: {
          showAdvancedOptions: true,
        },
        children: {
          BrandPaymentDetails: {
            displaySection: true,
            showAdvancedOptions: true,
          },
          PaymentMethod: {
            displaySection: true,
            appearance: {
              showStripeConnectedAccount: false,
            },
          },
          BrandNameDetails: {
            behavior: {
              baseURL: `${ENV.API_CONSUMER}/events/`,
            },
          },
        },
      },
    },
  },
  '/events': {
    id: 'EventList',
    messages: {
      documentTitle: 'Events - The Ticket Fairy Dashboard',
    },
    appearance: {
      hideDeleteButton: false,
      hideOnlineButton: false,
    },
    behavior: {
      skipIDVerificationStep: false,
      eventUrl: `${ENV.API_CONSUMER}/event/`,
    },
    children: {
      EventDuplicateModal: {
        children: {
          EventUrlRow: {
            appearance: {
              showEmbedCodeButton: true,
            },
          },
        },
      },
    },
  },
  '/brand/:id/likes': {
    id: 'BrandLikes',
    messages: {
      documentTitle: 'Likes - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/brand/:id/payments': {
    id: 'BrandPayments',
    messages: {
      documentTitle: 'Payments - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/brands': {
    id: 'BrandsPage',
    messages: {
      documentTitle: 'Brands - The Ticket Fairy Dashboard',
    },
  },
  '/brand/*': {
    id: 'BrandAllTabs',
    appearance: {
      showStreamingTab: true,
      showDemographicTab: true,
    },
  },
  '/brands/new': {
    id: 'NewBrandPage',
    messages: {
      documentTitle: 'New Brand - The Ticket Fairy Dashboard',
    },
    children: {
      BrandForm: {
        appearance: {
          showAdvancedOptions: true,
        },
        children: {
          BrandNameDetails: {
            behavior: {
              baseURL: `${ENV.API_CONSUMER}/events/`,
            },
          },
        },
      },
    },
  },
  '/brand/:id/sub-brands/new': {
    id: 'NewSubBrandPage',
    messages: {
      documentTitle: 'New Sub Brand - The Ticket Fairy Dashboard',
    },
    children: {
      BrandForm: {
        appearance: {
          showAdvancedOptions: true,
        },
        children: {
          BrandNameDetails: {
            behavior: {
              baseURL: `${ENV.API_CONSUMER}/events/`,
            },
          },
        },
      },
    },
  },
  '/brand/:id/team': {
    id: 'BrandTeam',
    messages: {
      documentTitle: 'Team - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/brand/:id/messaging': {
    id: 'BrandMessaging',
    messages: {
      documentTitle: 'Messaging - {displayName} - The Ticket Fairy Dashboard',
    },
    appearance: {
      showMailchimpSection: false,
    },
  },
  '/brand/:id/email-templates': {
    id: 'EmailTemplates',
    messages: {
      documentTitle: 'Email Templates - {displayName} - The Ticket Fairy Dashboard',
    },
    children: {
      NewEmailTemplateForm: {
        appearance: {
          showUploadZippedContent: false,
        },
      },
      EditEmailTemplateForm: {
        appearance: {
          showUploadZippedContent: false,
        },
      },
    },
  },
  '/event/:id/demographics': {
    id: 'EventAudienceDemographics',
    messages: {
      documentTitle: 'Demographics - {displayName} - The Ticket Fairy Dashboard',
    },
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOwner: true,
      isVisibleForStatsOrLimitedStats: true,
    },
  },
  '/event/:id/geographics': {
    id: 'EventAudienceGeographics',
    messages: {
      documentTitle: 'Geographics - {displayName} - The Ticket Fairy Dashboard',
    },
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOwner: true,
      isVisibleForStatsOrLimitedStats: true,
    },
  },
  '/event/:id/music': {
    id: 'EventAudienceMusic',
    messages: {
      documentTitle: 'Music - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/event/:id/musicstreaming': {
    id: 'EventAudienceMusicStreaming',
    messages: {
      documentTitle: 'Music Streaming - {displayName} - The Ticket Fairy Dashboard',
    },
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOwner: true,
      isVisibleForStatsOrLimitedStats: true,
    },
  },
  '/event/:id/psychographics': {
    id: 'EventAudiencePsychographics',
    messages: {
      documentTitle: 'Psychographics - {displayName} - The Ticket Fairy Dashboard',
    },
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOwner: true,
      isVisibleForStatsOrLimitedStats: true,
    },
  },
  '/event/:id/budgets': {
    id: 'EventBudgetManagement',
    messages: {
      documentTitle: 'Budget Management - {displayName} - The Ticket Fairy Dashboard',
    },
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOwner: true,
    },
  },
  '/event/:id/credentials': {
    id: 'EventCredentials',
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOwner: true,
    },
  },
  '/event/:id/devices': {
    id: 'EventDevices',
    messages: {
      documentTitle: 'Devices - {displayName} - The Ticket Fairy Dashboard',
    },
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOwner: true,
      isVisibleForStatsOrLimitedStats: true,
    },
  },
  '/event/:id/faq': {
    id: 'EventFAQ',
    messages: {
      documentTitle: 'FAQs - {displayName} - The Ticket Fairy Dashboard',
    },
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: true,
    },
  },
  '/event/:id/gaming': {
    id: 'EventGaming',
    messages: {
      documentTitle: 'Gaming - {displayName} - The Ticket Fairy Dashboard',
    },
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOwner: true,
      isVisibleForStatsOrLimitedStats: true,
    },
  },
  '/event/:id/gift-certificates-management': {
    id: 'EventGiftCertificatesMangement',
    messages: {
      documentTitle: 'Gift Certificate - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/event/:id/guest-tickets': {
    id: 'EventGuestTickets',
    messages: {
      documentTitle: 'Guest Tickets - {displayName} - - The Ticket Fairy Dashboard',
    },
    appearance: {
      showGuestTicketDelegation: true,
    },
  },
  '/event/:id/influencers': {
    id: 'EventInfluencers',
    messages: {
      documentTitle: 'Influencers - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/event/:id/inventory': {
    id: 'EventInventory',
    messages: {
      documentTitle: 'Inventory - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/event/:id/invitations': {
    id: 'EventInvitation',
    messages: {
      documentTitle: 'Invitations - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/event/:id/likes': {
    id: 'EventLikes',
    messages: {
      documentTitle: 'Likes - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/event/:id/messaging': {
    id: 'EventMessaging',
    messages: {
      documentTitle: 'Messaging - {displayName} - The Ticket Fairy Dashboard',
    },
    appearance: {
      showMailchimpSection: true,
    },
    children: {
      EmailToTicketHolders: {
        appearance: {
          hideRSVPText: false,
          hideAllPreregisteredCustomers: false,
          hideHoldersOfRefundedTickets: false,
          hideHoldersOfSlidingScaleTickets: false,
          hideHoldersOfSpecificAddOns: false,
        },
      },
      SMSToTicketHolders: {
        appearance: {
          showHoldersOfSpecificAddOns: true,
        },
      },
    },
  },
  '/event/:id/messaging-hidden': {
    id: 'EventMessaging',
    messages: {
      documentTitle: 'Messaging - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/event/:id/orders': {
    id: 'EventOrders',
    messages: {
      documentTitle: 'Orders - {displayName} - The Ticket Fairy Dashboard',
    },
    appearance: {
      showSoldOrders: true,
      showPendingPaymentPlanOrders: true,
      showIssueGuestAddOnButton: true,
    },
  },
  '/event/:id/performance/:tabId?': {
    id: 'EventPerformance',
    messages: {
      documentTitle: '{titlePrefix} - {displayName} - The Ticket Fairy Dashboard',
    },
    appearance: {
      showPaymentPlans: true,
      showAddOns: true,
      showPreregistration: true,
      showBoxOfficeSales: true,
      showRSVP: true,
    },
    children: {
      EventReportPDF: {
        appearance: {
          showBoxOfficeSales: true,
          showPaidAddOns: true,
          showFreeAddOns: true,
        },
      },
    },
  },
  '/event/:id/promotions': {
    id: 'EventPromotion',
    messages: {
      documentTitle: 'Promotions - {displayName} - The Ticket Fairy Dashboard',
    },
    children: {
      Promotion: {
        children: {
          TagsField: {
            messages: {
              promotionUrl: '{hostDomain}/event/{eventSlug}/?r={tag}',
            },
          },
        },
      },
    },
  },
  '/event/:id/contact': {
    id: 'EventSupport',
    messages: {
      documentTitle: 'Contacts - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/event/:id/talent': {
    id: 'EventTalent',
    messages: {
      documentTitle: 'Talent - {displayName} - The Ticket Fairy Dashboard',
    },
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOwner: false,
    },
  },
  '/event/:id/team': {
    id: 'EventTeam',
    messages: {
      documentTitle: 'Team - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/event/:id/tickets': {
    id: 'EventTickets',
    messages: {
      documentTitle: 'Tickets - {displayName} - The Ticket Fairy Dashboard',
    },
    appearance: {
      showTables: true,
      showAdvancedOptions: true,
      showAddOnsSection: true,
    },
    children: {
      TicketForm: {
        children: {
          CheckBoxesRow: {
            appearance: {
              hideRequireOwnershipOfNFTsToRegisterForThisTicketTypeToggle: false,
            },
          },
          DataRow: {
            messages: {
              stockLabel: 'Maximum Number of Sales',
            },
          },
        },
      },
      TicketOptionsForm: {
        appearance: {
          hideWaitingListAfterAddOnSellOutToggle: false,
          hideAllowCustomersToInitiateRefundsToggle: false,
          hideTicketsAreBeingSoldOnADifferentTicketingPlatformToggle: false,
          hideAddOnResaleSystemEnabledToggle: false,
          showRequireBuyerToEnterTheirPhoneNumberToggle: true,
          showHideBuyerPhoneNumberToggle: false,
        },
      },
      SalesTracking: {
        children: {
          TrackingForm: {
            appearance: {
              showAddOnIdsField: true,
            },
          },
        },
      },
      AddOnSection: {
        behavior: {
          useAddOnsWithVariants: false,
        },
        messages: {
          stockLabel: 'Stock',
        },
        children: {
          AddOnForm: {
            appearance: {
              showWithholdFromResalToggle: true,
            },
          },
          AddOnModal: {
            children: {
              AddOnForm: {
                appearance: {
                  showWithholdFromResalToggle: true,
                },
              },
            },
          },
        },
      },
      TicketsTable: {
        messages: {
          stockLabel: 'Max Number of Sales',
        },
      },
    },
  },
  '/events/new': {
    id: 'EventNew',
    messages: {
      documentTitle: 'New Event - The Ticket Fairy Dashboard',
    },
    children: {
      EventForm: {
        appearance: {
          showEventPayoutDetails: true,
          showEventCustomConfirmationText: false,
        },
        children: {
          EventImages: {
            appearance: {
              showUseAutomaticallyGeneratedBackgroundToggle: true,
              showUsetransparentLogoInsteadOfEventNameToggle: true,
            },
          },
          EventVenue: {
            children: {
              EventVenueFlags: {
                hideSendAlternativeInformationForRSVPs: false,
                hideDisabledAccess: false,
                showApplyTax: true,
                messages: {
                  hideToggleLabel: 'Hide this venue from customers',
                },
              },
            },
          },
          EventDetails: {
            children: {
              EventUrlRow: {
                appearance: {
                  showEmbedCodeButton: true,
                },
              },
            },
          },
        },
      },
      BrandForm: {
        appearance: {
          showAdvancedOptions: true,
        },
        children: {
          BrandNameDetails: {
            behavior: {
              baseURL: `${ENV.API_CONSUMER}/events/`,
            },
          },
        },
      },
    },
  },
  '/events/new-online': {
    id: 'EventNewOnline',
    messages: {
      documentTitle: 'New Online Event - The Ticket Fairy Dashboard',
    },
    children: {
      EventForm: {
        children: {
          OnlineEventImages: {
            appearance: {
              showUseAutomaticallyGeneratedBackgroundToggle: true,
              showUsetransparentLogoInsteadOfEventNameToggle: true,
            },
          },
          OnlineEventDetails: {
            children: {
              UrlRow: {
                appearance: {
                  showEmbedCodeButton: true,
                },
              },
            },
          },
        },
      },
      BrandForm: {
        appearance: {
          showAdvancedOptions: true,
        },
        children: {
          BrandNameDetails: {
            behavior: {
              baseURL: `${ENV.API_CONSUMER}/events/`,
            },
          },
        },
      },
    },
  },
  '/event/:id/checkin': {
    id: 'EventCheckIn',
    messages: {
      documentTitle: 'Check-In - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/draft-events': {
    id: 'DraftEventList',
    messages: {
      documentTitle: 'Draft Events - The Ticket Fairy Dashboard',
    },
  },
  '/event/:id/email-templates': {
    id: 'EmailTemplates',
    messages: {
      documentTitle: 'Email Templates - {displayName} - The Ticket Fairy Dashboard',
    },
    children: {
      NewEmailTemplateForm: {
        appearance: {
          showUploadZippedContent: true,
        },
      },
      EditEmailTemplateForm: {
        appearance: {
          showUploadZippedContent: true,
        },
      },
    },
  },
  '/event/:id/pre-registration': {
    id: 'PreRegistration',
    messages: {
      documentTitle: 'Pre-Registration - {displayName} - The Ticket Fairy Dashboard',
    },
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOwner: true,
    },
  },
  '/tours/new': {
    id: 'NewTourPage',
    messages: {
      documentTitle: 'New Tour - The Ticket Fairy Dashboard',
    },
  },
  '/tour/:id/details': {
    id: 'TourDetails',
    messages: {
      documentTitle: 'Details - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/tours': {
    id: 'ToursPage',
    messages: {
      documentTitle: 'Tours - The Ticket Fairy Dashboard',
    },
  },
  '/tour/:id/performance': {
    id: 'TourPerformance',
    messages: {
      documentTitle: 'Performance - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/tour/:id/team': {
    id: 'TourTeam',
    messages: {
      documentTitle: 'Team - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/brand/:id/events': {
    id: 'BrandEventList',
    messages: {
      documentTitle: 'Brand Events - The Ticket Fairy Dashboard',
    },
    appearance: {
      enableEventDuplicate: true,
      hideDeleteButton: false,
      hideOnlineButton: false,
    },
    behavior: {
      skipIDVerificationStep: false,
    },
  },
  '/users': {
    id: 'Users',
    messages: {
      documentTitle: 'Users - The Ticket Fairy',
    },
  },
  '/event/:id/multi-entry-passes': {
    id: 'EventMultiEntryPasses',
    messages: {
      documentTitle: 'Multi-Entry Passes - {displayName} - The Ticket Fairy Dashboard',
    },
  },
  '/support': {
    appearance: {},
  },
}
