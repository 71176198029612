import { ENV } from '../constants/env'

export const RNConfigs = {
  global: {
    appearance: {
      fontFamily: 'Inter',
    },
    CONSTANTS: {
      BASE_URL: ENV.RN_CONSUMER,
    },
    behavior: {
      skipIDVerificationStep: true,
      displayCustomZendeskButton: true,
      displayUsersnap: false,
      hideAppcuesSurvey: true,
    },
    baseURL: ENV.RN_CONSUMER,
  },
  '*': {
    domain: 'restlessnites',
    messages: {
      documentTitle: 'Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      icons: {
        favicon: '/resources/images/rn_favicon.ico',
      },
      assets: {
        headerLogo: '/resources/images/rn-white-ttf-logo.png',
        logo: '/resources/images/rn-logo.svg',
      },
      classes: {
        header: 'res_logo_rn',
        logoLabel: 'rn-powered-text',
      },
    },
  },
  '/': {
    id: 'HomePage',
    messages: {
      documentTitle: 'Home - Restless Nites Ticketing - Powered by TheTicketFairy',
    },
    behavior: {
      redirectTo: '/events',
    },
  },
  '/ads': {
    id: 'AdsPage',
    messages: {
      documentTitle: 'Ads - Restless Nites Ticketing - Powered by TheTicketFairy',
    },
  },
  '/event/:id/*': {
    id: 'EventAllTabs',
    appearance: {
      isBudgetVisible: true,
      isCredentialsVisible: true,
      isPreregistrationVisible: false,
      isFAQVisible: false,
      isMusicVisible: false,
      isPsychographicsVisible: false,
      isGamingVisible: false,
      isDevicesVisible: false,
      isDemographicsVisible: false,
      isGeographicsVisible: false,
      isTalentVisible: false,
      isSupportVisible: false,
    },
  },
  '/event/:id/details': {
    id: 'EventDetails',
    messages: {
      documentTitle: 'Details - {displayName} - Restless Nites Ticketing - Powered by TheTicketFairy',
    },
    children: {
      BrandForm: {
        appearance: {
          showAdvancedOptions: true,
        },
        children: {
          BrandPaymentDetails: {
            displaySection: false,
          },
          PaymentMethod: {
            displaySection: true,
            appearance: {
              showStripeConnectedAccount: true,
            },
          },
          BrandNameDetails: {
            appearance: {
              showDescriptionBlock: true,
            },
            behavior: {
              baseURL: `${ENV.RN_CONSUMER}/`,
            },
            children: {
              AutoSlugField: {
                behavior: {
                  separator: '',
                },
              },
            },
          },
          BrandImages: {
            children: {
              behavior: {
                fileUploaderId: 'squareImage',
              },
              messages: {
                fileUploaderLabel: 'Square Image',
              },
            },
          },
        },
      },
      EventForm: {
        appearance: {
          showOpenGraphImages: false,
          showEventAlternativeViews: false,
          showShopifyCard: false,
          showEventGenres: true,
          showEventPayoutDetails: false,
          showEventCustomConfirmationText: false,
          hideShopify: true,
        },
        children: {
          EventDetails: {
            appearance: {
              showAgeRow: false,
            },
            children: {
              EventNameRow: {
                appearance: {
                  showShortNameField: false,
                },
                behavior: {
                  eventDisplayNameLength: 50,
                  eventDisplayNameClassName: 'col-xs-12',
                },
                messages: {
                  eventDisplayNameLabel: 'Event Title',
                },
              },
              EventUrlRow: {
                behavior: {
                  baseURL: `${ENV.RN_CONSUMER}/events/`,
                  slugSeparator: '',
                },
                messages: {
                  autoSlugFieldLabel: 'Custom URL',
                },
                appearance: {
                  showEmbedCodeButton: false,
                },
              },
              EventDatesRow: {
                behavior: {
                  autoSetDates: true,
                  dateDefaultValues: {
                    startTime: 21,
                    minutes: 0,
                    duration: 4,
                  },
                },
                appearance: {
                  showDateTimePickerNew: true,
                },
                children: {
                  DateTimePickerNew: {
                    behavior: {
                      checkInitialValue: true,
                    },
                  },
                },
              },
            },
          },
          EventVenue: {
            appearance: {
              showRegionField: true,
            },
            behavior: {
              useShortStateValue: true,
            },
            children: {
              EventVenueSearch: {
                behavior: {
                  useAreaShortName: true,
                },
              },
              EventVenueFlags: {
                hideSendAlternativeInformationForRSVPs: false,
                hideDisabledAccess: true,
                showApplyTax: false,
                messages: {
                  hideToggleLabel: 'Hide Location on the Event Page',
                },
              },
            },
          },
          EventDescription: {
            appearance: {
              showDescriptionLanguageField: false,
              showBriefDescriptionField: true,
            },
          },
          EventImages: {
            appearance: {
              showDescriptionLanguageField: false,
              showBackgroundURLField: true,
              showAutoBackgroundField: false,
              showImageAsEventNameSection: false,
              showUseAutomaticallyGeneratedBackgroundToggle: false,
              showUsetransparentLogoInsteadOfEventNameToggle: false,
            },
            messages: {
              imageURLsLabel: 'Square image',
              backgroundURL: 'Banner image',
            },
          },
          EventAlternativeViews: {
            behavior: {
              eventUrl: `${ENV.RN_CONSUMER}/events/`,
            },
          },
          EventUrlRow: {
            behavior: {
              eventUrl: `${ENV.RN_CONSUMER}/events/`,
            },
          },
          EventUrlField: {
            behavior: {
              eventUrl: `${ENV.RN_CONSUMER}/events/`,
            },
          },
          EventType: {
            // NOTE: this component must be refactored
            behavior: {
              idString: 'eventCategory_',
              fieldId: 'eventCategory',
              useEventCategoriesArray: true,
              useLabelAsId: true,
            },
            appearance: {
              togglesContainerClassNames: ['rn-event-genres', 'rn-event-categories'],
            },
          },
          EventSettings: {
            appearance: {
              showEventSettingsTogglesSection: false,
              showEventSettingsSection: true,
              showRequireNTFs: false,
              showRefundsAllowed: true,
              showFreeCarerTicketForDisabledGuests: true,
            },
          },
          EventSocialMedia: {
            appearance: {
              showTwitterHashtagField: false,
              showRsvpUrlField: true,
              showExternalUrlField: true,
            },
            messages: {
              cardTitle: 'Additional Information',
              facebookEventFieldLabel: 'Facebook URL',
            },
          },
          EventTags: {
            messages: {
              cardTitle: 'Artists',
              tagsFieldPlaceholder: 'Add an Artist',
            },
          },
        },
      },
    },
  },
  '/event/:id/musicad/:audienceItemId': {
    id: 'NewAdPage',
    messages: {
      documentTitle: 'New Facebook AD - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/musicstreamingad/:audienceItemId': {
    id: 'NewAdPage',
    messages: {
      documentTitle: 'New Facebook AD - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/likesad/:audienceItemId': {
    id: 'NewAdPage',
    messages: {
      documentTitle: 'New Facebook AD - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/account': {
    id: 'AccountSettingsPage',
    messages: {
      documentTitle: 'Account Settings - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/team-invitations': {
    id: 'ConfirmTeamInvitationPage',
    messages: {
      documentTitle: 'Team Invitation - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/signin': {
    id: 'LoginPage',
    messages: {
      documentTitle: 'Log In - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      showBackgroundVideo: false,
    },
  },
  '/reset-password': {
    id: 'ResetPasswordPage',
    messages: {
      documentTitle: 'Reset Password - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/sign-up': {
    id: 'SignUpPage',
    messages: {
      documentTitle: 'Sign Up - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/brand/:id/*': {
    id: 'BrandAllTabs',
    appearance: {
      showStreamingTab: false,
      showDemographicTab: false,
    },
  },
  '/brand/:id/demographics': {
    id: 'BrandAudienceDemoGraphics',
    messages: {
      documentTitle: 'Demographics - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: false,
    },
  },
  '/brand/:id/music': {
    id: 'BrandAudienceMusic',
    messages: {
      documentTitle: 'Music - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: false,
      isVisibleForTtfSupport: false,
      isVisibleForAdminOrOwner: false,
    },
  },
  '/brand/:id/musicstreaming': {
    id: 'BrandAudienceMusicStreaming',
    messages: {
      documentTitle: 'Music Streaming - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
    },
  },
  '/brand/:id/details': {
    id: 'BrandDetails',
    messages: {
      documentTitle: 'Details - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    children: {
      BrandForm: {
        appearance: {
          hideShopify: true,
          showAdvancedOptions: true,
        },
        children: {
          BrandPaymentDetails: {
            displaySection: false,
          },
          PaymentMethod: {
            displaySection: true,
            appearance: {
              showStripeConnectedAccount: true,
            },
          },
          BrandNameDetails: {
            appearance: {
              showDescriptionBlock: true,
            },
            behavior: {
              baseURL: `${ENV.RN_CONSUMER}/`,
            },
            children: {
              AutoSlugField: {
                behavior: {
                  separator: '',
                },
              },
            },
          },
          BrandImages: {
            children: {
              behavior: {
                fileUploaderId: 'squareImage',
              },
              messages: {
                fileUploaderLabel: 'Square Image',
              },
            },
          },
        },
      },
    },
  },
  '/events': {
    id: 'EventList',
    messages: {
      documentTitle: 'Events - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      hideDeleteButton: true,
      hideOnlineButton: false,
      showEventAlternativeViews: false,
    },
    behavior: {
      skipIDVerificationStep: true,
      eventUrl: `${ENV.RN_CONSUMER}/events/`,
    },
    children: {
      EventDuplicateModal: {
        children: {
          EventUrlRow: {
            appearance: {
              showEmbedCodeButton: false,
            },
          },
        },
      },
    },
  },
  '/brand/:id/likes': {
    id: 'BrandLikes',
    messages: {
      documentTitle: 'Likes - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/brand/:id/payments': {
    id: 'BrandPayments',
    messages: {
      documentTitle: 'Payments - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/brands': {
    id: 'BrandsPage',
    messages: {
      documentTitle: 'Brands - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/brands/new': {
    id: 'NewBrandPage',
    messages: {
      documentTitle: 'New Brand - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    children: {
      BrandForm: {
        appearance: {
          showAdvancedOptions: true,
        },
        children: {
          BrandPaymentDetails: {
            displaySection: false,
          },
          PaymentMethod: {
            displaySection: true,
            appearance: {
              showStripeConnectedAccount: true,
            },
          },
          BrandNameDetails: {
            appearance: {
              showDescriptionBlock: true,
            },
            behavior: {
              baseURL: `${ENV.RN_CONSUMER}/`,
            },
            children: {
              AutoSlugField: {
                behavior: {
                  separator: '',
                },
              },
            },
          },
          BrandImages: {
            children: {
              behavior: {
                fileUploaderId: 'squareImage',
              },
              messages: {
                fileUploaderLabel: 'Square Image',
              },
            },
          },
        },
      },
    },
  },
  '/brand/:id/sub-brands/new': {
    id: 'NewSubBrandPage',
    messages: {
      documentTitle: 'New Sub Brand - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    children: {
      BrandForm: {
        appearance: {
          showAdvancedOptions: true,
        },
        children: {
          BrandPaymentDetails: {
            displaySection: false,
          },
          PaymentMethod: {
            displaySection: true,
            appearance: {
              showStripeConnectedAccount: true,
            },
          },
          BrandNameDetails: {
            appearance: {
              showDescriptionBlock: true,
            },
            behavior: {
              baseURL: `${ENV.RN_CONSUMER}/`,
            },
            children: {
              AutoSlugField: {
                behavior: {
                  separator: '',
                },
              },
            },
          },
          BrandImages: {
            children: {
              behavior: {
                fileUploaderId: 'squareImage',
              },
              messages: {
                fileUploaderLabel: 'Square Image',
              },
            },
          },
        },
      },
    },
  },
  '/brand/:id/team': {
    id: 'BrandTeam',
    messages: {
      documentTitle: 'Team - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/brand/:id/messaging': {
    id: 'BrandMessaging',
    messages: {
      documentTitle: 'Messaging - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      showMailchimpSection: false,
    },
  },
  '/brand/:id/email-templates': {
    id: 'EmailTemplates',
    messages: {
      documentTitle: 'Email Templates - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    children: {
      NewEmailTemplateForm: {
        appearance: {
          showUploadZippedContent: false,
        },
      },
      EditEmailTemplateForm: {
        appearance: {
          showUploadZippedContent: false,
        },
      },
    },
  },
  '/event/:id/demographics': {
    id: 'EventAudienceDemographics',
    messages: {
      documentTitle: 'Demographics - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOwner: false,
      isVisibleForStatsOrLimitedStats: false,
    },
  },
  '/event/:id/music': {
    id: 'EventAudienceMusic',
    messages: {
      documentTitle: 'Music - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: true,
      isVisibleForStatsOrLimitedStats: true,
    },
  },
  '/event/:id/musicstreaming': {
    id: 'EventAudienceMusicStreaming',
    messages: {
      documentTitle: 'Music Streaming - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOwner: false,
      isVisibleForStatsOrLimitedStats: false,
    },
  },
  '/event/:id/psychographics': {
    id: 'EventAudiencePsychographics',
    messages: {
      documentTitle: 'Psychographics - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: true,
      isVisibleForStatsOrLimitedStats: false,
    },
  },
  '/event/:id/budgets': {
    id: 'EventBudgetManagement',
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOwner: true,
    },
    messages: {
      permissionDeniedSubHeader: "You don't have access to this section",
      documentTitle:
        'Budget Management - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/credentials': {
    id: 'EventCredentials',
    messages: {
      permissionDeniedSubHeader: "You don't have access to this section",
      documentTitle: 'Credentials - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOwner: true,
    },
  },
  '/event/:id/devices': {
    id: 'EventDevices',
    messages: {
      documentTitle: 'Devices - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: false,
      isVisibleForStatsOrLimitedStats: false,
    },
  },
  '/event/:id/faq': {
    id: 'EventFAQ',
    messages: {
      documentTitle: 'FAQs - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section.",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: false,
    },
  },
  '/event/:id/gaming': {
    id: 'EventGaming',
    messages: {
      documentTitle: 'Gaming - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: true,
      isVisibleForStatsOrLimitedStats: false,
    },
  },
  '/event/:id/gift-certificates-management': {
    id: 'EventGiftCertificatesMangement',
    messages: {
      documentTitle: 'Gift Certificate - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/guest-tickets': {
    id: 'EventGuestTickets',
    messages: {
      documentTitle: 'Guest Tickets - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      showGuestTicketDelegation: true,
    },
  },
  '/event/:id/influencers': {
    id: 'EventInfluencers',
    messages: {
      documentTitle: 'Influencers - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/inventory': {
    id: 'EventInventory',
    messages: {
      documentTitle: 'Inventory - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/invitations': {
    id: 'EventInvitation',
    messages: {
      documentTitle: 'Invitations - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/likes': {
    id: 'EventLikes',
    messages: {
      documentTitle: 'Likes - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/messaging': {
    id: 'EventMessaging',
    messages: {
      documentTitle: 'Messaging - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      showMailchimpSection: true,
    },
    children: {
      EmailToTicketHolders: {
        appearance: {
          hideRSVPText: false,
          hideAllPreregisteredCustomers: false,
          hideHoldersOfRefundedTickets: false,
          hideHoldersOfSlidingScaleTickets: false,
          hideHoldersOfSpecificAddOns: true,
        },
      },
      SMSToTicketHolders: {
        appearance: {
          showHoldersOfSpecificAddOns: false,
        },
      },
    },
  },
  '/event/:id/messaging-hidden': {
    id: 'EventMessaging',
    messages: {
      documentTitle: 'Messaging - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/orders': {
    id: 'EventOrders',
    messages: {
      documentTitle: 'Orders - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      showSoldOrders: true,
      showPendingPaymentPlanOrders: true,
      showIssueGuestAddOnButton: false,
    },
  },
  '/event/:id/performance/:tabId?': {
    id: 'EventPerformance',
    messages: {
      documentTitle: '{titlePrefix} - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      showPaymentPlans: false,
      showAddOns: true,
      showPreregistration: false,
      showBoxOfficeSales: false,
      showRSVP: true,
    },
    children: {
      EventReportPDF: {
        appearance: {
          showBoxOfficeSales: true,
          showPaidAddOns: false,
          showFreeAddOns: false,
        },
      },
    },
  },
  '/event/:id/promotions': {
    id: 'EventPromotion',
    messages: {
      documentTitle: 'Promotions - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    children: {
      Promotion: {
        children: {
          TagsField: {
            messages: {
              promotionUrl: '{hostDomain}/events/{eventSlug}/?r={tag}',
            },
          },
        },
      },
    },
  },
  '/event/:id/contact': {
    id: 'EventSupport',
    messages: {
      documentTitle: 'Contacts - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/talent': {
    id: 'EventTalent',
    messages: {
      documentTitle: 'Talent - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOrOwner: false,
    },
  },
  '/event/:id/team': {
    id: 'EventTeam',
    messages: {
      documentTitle: 'Team - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/tickets': {
    id: 'EventTickets',
    messages: {
      documentTitle: 'Tickets - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      showTables: false,
      showAdvancedOptions: true,
      showAddOnsSection: true,
    },
    children: {
      TicketForm: {
        children: {
          CheckBoxesRow: {
            appearance: {
              hideRequireOwnershipOfNFTsToRegisterForThisTicketTypeToggle: true,
            },
          },
          DataRow: {
            messages: {
              stockLabel: 'Quantity Available',
            },
          },
        },
      },
      TicketOptionsForm: {
        appearance: {
          hideWaitingListAfterAddOnSellOutToggle: true,
          hideAllowCustomersToInitiateRefundsToggle: true,
          hideTicketsAreBeingSoldOnADifferentTicketingPlatformToggle: true,
          hideAddOnResaleSystemEnabledToggle: true,
          showRequireBuyerToEnterTheirPhoneNumberToggle: false,
          showHideBuyerPhoneNumberToggle: false,
        },
      },
      SalesTracking: {
        children: {
          TrackingForm: {
            appearance: {
              showAddOnIdsField: true,
            },
          },
        },
      },
      AddOnSection: {
        behavior: {
          useAddOnsWithVariants: true,
        },
        messages: {
          stockLabel: 'Quantity Available',
        },
        children: {
          AddOnForm: {
            appearance: {
              showWithholdFromResalToggle: false,
            },
          },
          AddOnModal: {
            children: {
              AddOnForm: {
                appearance: {
                  showWithholdFromResalToggle: false,
                },
              },
            },
          },
        },
      },
      TicketsTable: {
        messages: {
          stockLabel: 'Quantity Available',
        },
      },
    },
  },
  '/events/new': {
    id: 'EventNew',
    messages: {
      documentTitle: 'New Event - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    children: {
      BrandForm: {
        appearance: {
          showAdvancedOptions: true,
        },
        children: {
          BrandPaymentDetails: {
            displaySection: false,
          },
          PaymentMethod: {
            displaySection: true,
            appearance: {
              showStripeConnectedAccount: true,
            },
          },
          BrandNameDetails: {
            appearance: {
              showDescriptionBlock: true,
            },
            behavior: {
              baseURL: `${ENV.RN_CONSUMER}/`,
            },
            children: {
              AutoSlugField: {
                behavior: {
                  separator: '',
                },
              },
            },
          },
          BrandImages: {
            children: {
              behavior: {
                fileUploaderId: 'squareImage',
              },
              messages: {
                fileUploaderLabel: 'Square Image',
              },
            },
          },
        },
      },
      EventForm: {
        appearance: {
          showEventPayoutDetails: false,
          showEventCustomConfirmationText: false,
          showOpenGraphImages: false,
        },
        children: {
          EventImages: {
            appearance: {
              showUseAutomaticallyGeneratedBackgroundToggle: false,
              showUsetransparentLogoInsteadOfEventNameToggle: false,
            },
          },
          EventSettings: {
            showEventSettingsTogglesSection: false,
            showEventSettingsSection: true,
            showRequireNTFs: false,
            showRefundsAllowed: true,
            showFreeCarerTicketForDisabledGuests: true,
          },
          EventVenue: {
            children: {
              EventVenueFlags: {
                hideSendAlternativeInformationForRSVPs: false,
                hideDisabledAccess: true,
                showApplyTax: false,
                messages: {
                  hideToggleLabel: 'Hide Location on the Event Page',
                },
              },
            },
          },
          EventDescription: {
            appearance: {
              showBriefDescriptionField: true,
            },
          },
          EventDetails: {
            children: {
              EventUrlRow: {
                appearance: {
                  showEmbedCodeButton: false,
                },
              },
            },
          },
        },
      },
    },
  },
  '/events/new-online': {
    id: 'EventNewOnline',
    messages: {
      documentTitle: 'New Online Event - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    children: {
      EventForm: {
        children: {
          OnlineEventImages: {
            appearance: {
              showUseAutomaticallyGeneratedBackgroundToggle: false,
              showUsetransparentLogoInsteadOfEventNameToggle: false,
            },
          },
          OnlineEventDetails: {
            children: {
              UrlRow: {
                appearance: {
                  showEmbedCodeButton: false,
                },
              },
            },
          },
        },
      },
      BrandForm: {
        appearance: {
          showAdvancedOptions: true,
        },
        children: {
          BrandPaymentDetails: {
            displaySection: false,
          },
          PaymentMethod: {
            displaySection: true,
            appearance: {
              showStripeConnectedAccount: true,
            },
          },
          BrandNameDetails: {
            appearance: {
              showDescriptionBlock: true,
            },
            behavior: {
              baseURL: `${ENV.RN_CONSUMER}/`,
            },
            children: {
              AutoSlugField: {
                behavior: {
                  separator: '',
                },
              },
            },
          },
          BrandImages: {
            children: {
              behavior: {
                fileUploaderId: 'squareImage',
              },
              messages: {
                fileUploaderLabel: 'Square Image',
              },
            },
          },
        },
      },
    },
  },
  '/event/:id/checkin': {
    id: 'EventCheckIn',
    messages: {
      documentTitle: 'Check-In - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/draft-events': {
    id: 'DraftEventList',
    messages: {
      documentTitle: 'Draft Events - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/geographics': {
    id: 'EventGeographics',
    messages: {
      documentTitle: 'Geographics - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOwner: false,
      isVisibleForStatsOrLimitedStats: false,
    },
  },
  '/event/:id/email-templates': {
    id: 'EmailTemplates',
    messages: {
      documentTitle: 'Email Templates - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    children: {
      NewEmailTemplateForm: {
        appearance: {
          showUploadZippedContent: false,
        },
      },
      EditEmailTemplateForm: {
        appearance: {
          showUploadZippedContent: false,
        },
      },
    },
  },
  '/event/:id/pre-registration': {
    id: 'PreRegistration',
    messages: {
      documentTitle:
        'Pre-Registration - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
      permissionDeniedSubHeader: "You don't have access to this section",
    },
    appearance: {
      alwaysDisplaySection: false,
      displaySection: false,
      isVisibleForTtfTeam: true,
      isVisibleForTtfSupport: true,
      isVisibleForAdminOwner: false,
    },
  },
  '/tours/new': {
    id: 'NewTourPage',
    messages: {
      documentTitle: 'New Tour - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/tour/:id/details': {
    id: 'TourDetails',
    messages: {
      documentTitle: 'Details - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      hideShopify: true,
    },
  },
  '/tours': {
    id: 'ToursPage',
    messages: {
      documentTitle: 'Tours - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/tour/:id/performance': {
    id: 'TourPerformance',
    messages: {
      documentTitle: 'Performance - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/tour/:id/team': {
    id: 'TourTeam',
    messages: {
      documentTitle: 'Team - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/brand/:id/events': {
    id: 'BrandEventList',
    messages: {
      documentTitle: 'Brand Events - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
    appearance: {
      hideDeleteButton: true,
      hideOnlineButton: false,
    },
    behavior: {
      skipIDVerificationStep: true,
    },
  },
  '/users': {
    id: 'Users',
    messages: {
      documentTitle: 'Users - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/event/:id/multi-entry-passes': {
    id: 'EventMultiEntryPasses',
    messages: {
      documentTitle:
        'Multi-Entry Passes - {displayName} - Restless Nites Ticketing – Powered by TheTicketFairy',
    },
  },
  '/support': {
    appearance: {},
  },
}
