import _filter from 'lodash/filter'
import _orderBy from 'lodash/orderBy'
import _result from 'lodash/result'
import _get from 'lodash/get'
import { connect } from 'react-redux'
import React from 'react'
import Modal from 'react-modal'
import modalStyle from '../../../_common/core/modalStyle'
import LoadingBar from '../../_library/LoadingBar'
import Button from '../../_library/Button'
import {
  FETCH_BRAND_TEAM_INVITATIONS,
  FETCH_BRAND_TEAM_MEMBERS,
  ADD_BRAND_TEAM_INVITATION,
  RESEND_BRAND_TEAM_INVITATION,
  UPDATE_BRAND_PROMOTER_GENERIC_INVITATION
} from '../../../_common/redux/brandteam/actions'
import {
  DELETE_PERMISSION,
  CANCEL_INVITATION,
  EDIT_PERMISSION
} from '../../../_common/redux/eventteam/actions'
import {
  FETCH_BRAND_PROMOTER_GENERIC_INVITATION,
  REGENERATE_PROMOTER_GENERIC_INVITATION_BRAND
} from '../../../_common/redux/brandteam/actions'
import { JSONDatatable, TYPE_FROM_ARRAY, SEARCHBAR, DATATABLE } from '../../_library/JSONDatatable'

import BrandTeamInvitationForm from './BrandTeamInvitationForm'
import BrandEditTeamInvitationForm from './BrandEditTeamInvitationForm'
import Clipboard from '../../_library/Clipboard'
import { getTitle } from '../../utils/getTitle'
import { IS_TF_MEMBER } from '../../events/EventTeam'
import { ROLES } from '../../constants/roles'

const INVITATION_INITIAL_VALUES = {
  attributes: {
    role: 'promoter'
  }
}

const ROLES_MAP = {
  owner: 'Owner',
  admin: 'Administrator',
  stats: 'Stats',
  limited_stats: 'Limited Stats',
  promoter: 'Promoter',
  staff: 'Door Staff',
  curator: 'Curator',
  support: 'Support',
  limited_support: 'Limited Support'
}

@connect(
  state => {
    const invitations = state.brandteam.invitations.attributes
    const { members } = state.brandteam
    const brand = state.brands.selected
    const { user } = state.auth
    return {
      user,
      brand,
      invitations,
      members,
      promoterGenericInvitation: state.brandteam.promoterGenericInvitation
    }
  },
  {
    UPDATE_BRAND_PROMOTER_GENERIC_INVITATION,
    REGENERATE_PROMOTER_GENERIC_INVITATION_BRAND,
    FETCH_BRAND_PROMOTER_GENERIC_INVITATION,
    FETCH_BRAND_TEAM_INVITATIONS,
    FETCH_BRAND_TEAM_MEMBERS,
    ADD_BRAND_TEAM_INVITATION,
    CANCEL_INVITATION,
    DELETE_PERMISSION,
    RESEND_BRAND_TEAM_INVITATION,
    EDIT_PERMISSION
  }
)
export default class BrandTeam extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loadingInvitations: false,
      loadingGenericPromoterInvitation: false,
      loadingMembers: false,
      showNewInvitationModal: false,
      showCancelInvitationModal: false,
      invitationToCancel: null,
      cancelingInvitation: false,
      invitationToResend: null,
      resendingInvitation: false,
      showDeleteConfirmModal: false,
      permissionToDelete: null,
      deleting: false,
      showEditConfirmModal: false,
      permissionToEdit: null
    }
    this.getMembersFilteredRows = this.getMembersFilteredRows.bind(this)
    this.getMembersSortedRows = this.getMembersSortedRows.bind(this)
    this.getMembersTableData = this.getMembersTableData.bind(this)
    this.onClickNewInvitation = this.onClickNewInvitation.bind(this)
    this.getInvitationsFilteredRows = this.getInvitationsFilteredRows.bind(this)
    this.getInvitationsSortedRows = this.getInvitationsSortedRows.bind(this)
    this.getInvitationsTableData = this.getInvitationsTableData.bind(this)
    this.onCancelNewInvitation = this.onCancelNewInvitation.bind(this)
    this.handleNewInvitationSubmit = this.handleNewInvitationSubmit.bind(this)
    this.onCancelDelete = this.onCancelDelete.bind(this)
    this.deletePermission = this.deletePermission.bind(this)
    this.onCancelInvitationCancel = this.onCancelInvitationCancel.bind(this)
    this.cancelInvitation = this.cancelInvitation.bind(this)
  }

  regenerateGenericPromoterInvitation = async () => {
    try {
      const {
        REGENERATE_PROMOTER_GENERIC_INVITATION_BRAND,
        promoterGenericInvitation: { token }
      } = this.props
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: true })
      await REGENERATE_PROMOTER_GENERIC_INVITATION_BRAND(token)
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: false })
    } catch (e) {
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: false })
    }
  }

  fetchGenericPromoterInvitation = async () => {
    try {
      const { brand, FETCH_BRAND_PROMOTER_GENERIC_INVITATION } = this.props
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: true })
      await FETCH_BRAND_PROMOTER_GENERIC_INVITATION(brand.id)
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: false })
    } catch (e) {
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: false })
    }
  }

  disableOrEnableGenericPromoterInvitation = async () => {
    try {
      const { UPDATE_BRAND_PROMOTER_GENERIC_INVITATION, promoterGenericInvitation } = this.props
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: true })
      await UPDATE_BRAND_PROMOTER_GENERIC_INVITATION(promoterGenericInvitation.id, {
        id: promoterGenericInvitation.id,
        status: promoterGenericInvitation.status === 'disabled' ? 'pending' : 'disabled'
      })
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: false })
    } catch (e) {
      !this.isUnmounted && this.setState({ loadingGenericPromoterInvitation: false })
    }
  }

  componentDidMount() {
    const {
      brand: { displayName },
      configs
    } = this.props
    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])
    this.fetchInvitations()
    this.fetchMembers()
    this.fetchGenericPromoterInvitation()
  }

  fetchInvitations = async (indicator = true) => {
    try {
      const { brand, FETCH_BRAND_TEAM_INVITATIONS } = this.props
      indicator && this.setState({ loadingInvitations: true })
      const res = await FETCH_BRAND_TEAM_INVITATIONS(brand.id)
      indicator && this.setState({ loadingInvitations: false })
      return res
    } catch (err) {
      indicator && this.setState({ loadingInvitations: false })
    }
  }

  fetchMembers = async (indicator = true) => {
    try {
      const { brand, FETCH_BRAND_TEAM_MEMBERS } = this.props
      indicator && this.setState({ loadingMembers: true })
      const res = await FETCH_BRAND_TEAM_MEMBERS(brand.id)
      indicator && this.setState({ loadingMembers: false })
      return res
    } catch (err) {
      indicator && this.setState({ loadingMembers: false })
    }
  }

  getInvitationsFilteredRows(rows, search) {
    const isFound = (value, keyword) => {
      if (!value) return 0
      return value.toLowerCase().indexOf(keyword) != -1 ? 1 : 0
    }
    let rows_filtered = rows
    const keyword = search.join('').trim().toLowerCase()
    if (keyword != '') {
      rows_filtered = _filter(rows_filtered, item => {
        let found = 0
        found += isFound(item.firstName, keyword)
        found += isFound(item.lastName, keyword)
        found += isFound(item.email, keyword)
        found += isFound(item.role, keyword)
        return found > 0
      })
    }
    return rows_filtered
  }

  getInvitationsSortedRows(rows_filtered, sort) {
    rows_filtered = _orderBy(rows_filtered, t => t.firstName, 'desc')
    return rows_filtered
  }

  getInvitationsTableData(datatable, rows_filtered, sort) {
    const self = this
    const { user } = this.props

    const content_header = datatable.getHeaderRow(
      datatable,
      [
        { title: 'Name', sort: false },
        { title: 'Email', sort: false },
        { title: 'Role', sort: false },
        { title: 'Status', sort: false },
        { title: 'Actions', sort: false }
      ],
      sort
    )

    return (
      <table className="table brand-team-invitations-table">
        <thead>{content_header}</thead>
        <tbody>
          {rows_filtered.map((row, index) => (
            <tr key={index} className={index % 2 == 0 ? 'row-stale' : ''}>
              <td>
                {row.firstName} {row.lastName}
              </td>
              <td>{row.email}</td>
              <td>
                <div className="role">
                  <span className={`circle ${row.role}`} />
                  <span>{ROLES_MAP[row.role]}</span>
                </div>
              </td>
              <td>{row.status}</td>
              <td>
                {!ROLES.is_tf_support_or_client_support(user?.tfRole) && (
                  <>
                    <Button
                      disabled={row.status === 'accepted'}
                      className="btn btn-primary"
                      onClick={this.resendInvitation.bind(this, row)}
                    >
                      <i className="fa fa-undo" />
                      Resend
                    </Button>
                    <Button
                      disabled={row.status === 'accepted'}
                      className="btn btn-danger"
                      onClick={this.onClickCancelInvitation.bind(this, row)}
                    >
                      <i className="fa fa-times" />
                      Cancel
                    </Button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    )
  }

  getMembersFilteredRows(rows, search) {
    const isFound = (value, keyword) => {
      if (!value) return 0
      return value.toLowerCase().indexOf(keyword) != -1 ? 1 : 0
    }
    let rows_filtered = rows
    const keyword = search.join('').trim().toLowerCase()
    if (keyword != '') {
      rows_filtered = _filter(rows_filtered, item => {
        let found = 0
        found += isFound(item.attributes.firstName, keyword)
        found += isFound(item.attributes.lastName, keyword)
        found += isFound(item.attributes.firstName + ' ' + item.attributes.lastName, keyword)
        found += isFound(item.attributes.lastName + ' ' + item.attributes.firstName, keyword)
        found += isFound(item.attributes.email, keyword)
        return found > 0
      })
    }
    return rows_filtered
  }

  handleNewInvitationSubmit = async form => {
    try {
      const { brand, ADD_BRAND_TEAM_INVITATION } = this.props
      const res = await ADD_BRAND_TEAM_INVITATION(brand.id, form)
      this.setState({
        showNewInvitationModal: false
      })
      setTimeout(() => {
        this.fetchInvitations(false)
      }, 2000)
      return res
    } catch (err) {
      this.setState({
        showNewInvitationModal: false
      })
      Promise.reject(_result(err, 'toFieldErrors', err))
    }
  }

  handleEditInvitationSubmit = async form => {
    try {
      const { permissionToEdit } = this.state
      const { EDIT_PERMISSION } = this.props
      await EDIT_PERMISSION(permissionToEdit.roleBrand.id, form)
      this.fetchMembers(false)
      this.setState({
        showEditConfirmModal: false,
        permissionToEdit: null
      })
      return
    } catch (err) {
      this.setState({
        showEditConfirmModal: false,
        permissionToEdit: null
      })
    }
  }

  cancelInvitation = async () => {
    try {
      const { CANCEL_INVITATION } = this.props
      const { invitationToCancel } = this.state
      this.setState({ cancelingInvitation: true })
      const res = await CANCEL_INVITATION(invitationToCancel.token)
      this.fetchInvitations(false)
      this.setState({
        cancelingInvitation: false,
        invitationToCancel: null,
        showCancelInvitationModal: false
      })
      return res
    } catch (err) {
      this.setState({
        cancelInvitation: false,
        invitationToCancel: null,
        showCancelInvitationModal: false
      })
      Promise.reject(_result(err, 'toFieldErrors', err))
    }
  }

  deletePermission = async () => {
    try {
      const { DELETE_PERMISSION } = this.props
      const { permissionToDelete } = this.state
      this.setState({ deleting: true })
      const res = await DELETE_PERMISSION(permissionToDelete.roleBrand.id)
      this.fetchMembers(false)
      this.setState({
        deleting: false,
        permissionToDelete: null,
        showDeleteConfirmModal: false
      })
      return res
    } catch (err) {
      this.setState({
        deleting: false,
        permissionToDelete: null,
        showDeleteConfirmModal: false
      })
      Promise.reject(_result(err, 'toFieldErrors', err))
    }
  }

  resendInvitation = async invitation => {
    try {
      const {
        match: {
          params: { id }
        },
        RESEND_BRAND_TEAM_INVITATION
      } = this.props
      this.setState({
        invitationToResend: invitation,
        resendingInvitation: true
      })
      const res = await RESEND_BRAND_TEAM_INVITATION(id, invitation.email)
      this.setState({
        resendingInvitation: false,
        invitationToResend: null
      })
      Messenger().post({
        type: 'success',
        message: 'The invitation is successfully resent',
        hideAfter: 3,
        showCloseButton: true
      })
      return res
    } catch (err) {
      this.setState({
        resendingInvitation: false,
        invitationToResend: null
      })
      Promise.reject(_result(err, 'toFieldErrors', err))
    }
  }

  getMembersSortedRows(rows_filtered, sort) {
    rows_filtered = _orderBy(rows_filtered, t => t.attributes.firstName, 'desc')
    return rows_filtered
  }

  getMembersTableData(datatable, rows_filtered, sort) {
    const { user } = this.props
    const myRole = rows_filtered.find(member => member.id == user.id)?.roleBrand.role

    const content_header = datatable.getHeaderRow(
      datatable,
      [
        { title: 'Name', sort: false },
        { title: 'Email', sort: false },
        { title: 'Role at Tour Level', sort: false },
        { title: 'Role at Brand Level', sort: false },
        { title: 'Actions', sort: false }
      ],
      sort
    )

    const isOwner = myRole === 'owner'
    const isAdmin = myRole === 'admin'

    return (
      <table className="table brand-team-members-table">
        <thead>{content_header}</thead>
        <tbody>
          {rows_filtered.length === 0 && (
            <tr>
              <td colSpan="5" className="text-center">
                No Members Found
              </td>
            </tr>
          )}
          {rows_filtered.length > 0 &&
            rows_filtered.map((row, index) => {
              const canModifyAsOwner = row.attributes.email !== user.email
              const canModifyAsAdmin = !(row.roleBrand.role === 'owner' || row.roleBrand.role === 'admin')
              return (
                <tr key={index} className={index % 2 == 0 ? 'row-stale' : ''}>
                  <td>
                    {row.attributes.firstName} {row.attributes.lastName}
                  </td>
                  <td>{row.attributes.email}</td>
                  <td>
                    {row.roleTour && (
                      <div className="role">
                        <span className={`circle ${row.roleTour.role}`} />
                        <span>{ROLES_MAP[row.roleTour.role]}</span>
                      </div>
                    )}
                  </td>
                  <td>
                    {row.roleBrand && (
                      <div className="role">
                        <span className={`circle ${row.roleBrand.role}`} />
                        <span>{ROLES_MAP[row.roleBrand.role]}</span>
                      </div>
                    )}
                  </td>
                  {(user && ROLES.is_tf_support_admin_or_client_admin(user?.tfRole)) || user?.isSuperAdmin ||
                  (myRole && ((isOwner && canModifyAsOwner) || (isAdmin && canModifyAsAdmin))) ? (
                    <td>
                      <Button
                        disabled={!row.currentManufacturer}
                        title={
                          !row.currentManufacturer ? 'Team member has access from parent brand' : undefined
                        }
                        className="btn btn-primary"
                        onClick={this.onClickEdit.bind(this, row)}
                      >
                        <i className="fa fa-pencil" />
                        Edit
                      </Button>
                      <Button
                        disabled={!row.currentManufacturer}
                        title={
                          !row.currentManufacturer ? 'Team member has access from parent brand' : undefined
                        }
                        className="btn btn-danger"
                        onClick={this.onClickDelete.bind(this, row)}
                      >
                        <i className="fa fa-trash" />
                        Delete
                      </Button>
                    </td>
                  ) : (
                    <td />
                  )}
                </tr>
              )
            })}
        </tbody>
      </table>
    )
  }

  onClickNewInvitation() {
    this.setState({
      showNewInvitationModal: true
    })
  }

  onCancelNewInvitation() {
    this.setState({
      showNewInvitationModal: false
    })
  }

  handleNewInvitationSubmit = async form => {
    try {
      const { brand, ADD_BRAND_TEAM_INVITATION } = this.props
      const res = await ADD_BRAND_TEAM_INVITATION(brand.id, form)

      Messenger().post({
        type: 'success',
        message: res.message,
        hideAfter: 3,
        showCloseButton: true
      })

      this.setState({
        showNewInvitationModal: false
      })
      setTimeout(() => {
        this.fetchInvitations(false)
      }, 2000)
      return res
    } catch (err) {
      this.setState({
        showNewInvitationModal: false
      })
      Promise.reject(_result(err, 'toFieldErrors', err))
    }
  }

  onClickCancelInvitation(invitation) {
    this.setState({
      invitationToCancel: invitation,
      showCancelInvitationModal: true
    })
  }

  onCancelInvitationCancel() {
    this.setState({
      invitationToCancel: null,
      showCancelInvitationModal: false
    })
  }

  onCancelDelete() {
    this.setState({
      showDeleteConfirmModal: false,
      permissionToDelete: null
    })
  }

  onClickDelete(permission) {
    this.setState({
      permissionToDelete: permission,
      showDeleteConfirmModal: true
    })
  }

  onClickEdit(permission) {
    this.setState({
      permissionToEdit: permission,
      showEditConfirmModal: true
    })
  }

  onCancelEdit = () => {
    this.setState({
      permissionToEdit: null,
      showEditConfirmModal: false
    })
  }

  copyToClipboard = () => {
    const { promoterGenericInvitation } = this.props
    const _promoterLink = `${window.location.origin}/team-invitations/${promoterGenericInvitation?.token}`
    navigator.clipboard.writeText(_promoterLink)
  }

  render() {
    const { invitations, members, promoterGenericInvitation, user } = this.props
    const {
      loadingGenericPromoterInvitation,
      loadingInvitations,
      loadingMembers,
      showNewInvitationModal,
      showCancelInvitationModal,
      invitationToCancel,
      cancelingInvitation,
      showDeleteConfirmModal,
      permissionToDelete,
      deleting,
      showEditConfirmModal,
      permissionToEdit
    } = this.state

    let _members = []
    if (Array.isArray(members)) {
      _members = members.map(m => {
        const roleBrand = m.relationships.permissions.data.find(
          r => r.attributes.enabled && r.attributes.scope === 'brand'
        )
        const roleTour = m.relationships.permissions.data.find(
          r => r.attributes.enabled && r.attributes.scope === 'tour'
        )

        const manufacturer = m.relationships.permissions.data.find(
          r => r.attributes.enabled && r.attributes.manufacturerId
        )
        return {
          id: m.id,
          attributes: m.attributes,
          roleBrand: roleBrand ? { ...roleBrand.attributes, id: roleBrand.id } : null,
          roleTour: roleTour ? { ...roleTour.attributes, id: roleTour.id } : null,
          currentManufacturer: manufacturer.attributes.manufacturerId == this.props.brand.id
        }
      })
    }
    _members = _members.filter(m => (user.tfStaff ? true : !IS_TF_MEMBER(m)))

    const deleteConfirmMsg = permissionToDelete
      ? 'Are you sure to delete ' +
        permissionToDelete.attributes.firstName +
        ' ' +
        permissionToDelete.attributes.lastName +
        "'s " +
        ROLES_MAP[permissionToDelete.roleBrand.role] +
        ' permission?'
      : ''
    const invitationCancelConfirmMsg = invitationToCancel
      ? 'Are you sure you want to cancel the ' +
        ROLES_MAP[invitationToCancel.role] +
        ' team invitation to ' +
        invitationToCancel.email +
        '?'
      : ''
    const showGenericPromoterInvitationButton =
      promoterGenericInvitation?.status === 'pending' || promoterGenericInvitation?.status === 'disabled'
    const isActivatedGenericPromoterInvitation = promoterGenericInvitation?.status === 'pending'
    const _promoterLink = `${window.location.origin}/team-invitations/${promoterGenericInvitation?.token}`

    return (
      <div className="brand-team">
        <h3 className="heading_style">Team Members</h3>
        <div className="brand-team-members">
          {loadingMembers && (
            <LoadingBar key="loadingbar" title={"Hold tight! We're getting your brand's team members..."} />
          )}
          {!loadingMembers && (
            <JSONDatatable
              type={TYPE_FROM_ARRAY}
              data={_members}
              sort={{ index: 0, asc: true }}
              getFilteredRows={this.getMembersFilteredRows}
              getSortedRows={this.getMembersSortedRows}
              getTableData={this.getMembersTableData}
              autoRefresh={20 * 1000}
              loadingBarTitle={"Hold tight! We're getting your brand's team members..."}
            >
              <div ref={SEARCHBAR} hasSearch labelTotalCount="Number of Matching Members" />
              <div ref={DATATABLE} />
            </JSONDatatable>
          )}
        </div>
        <h3 className="heading_style">Invite Your Promoters</h3>
        {promoterGenericInvitation?.token ? (
          <div className="row">
            <div className="col-xs-12">
              <label className="autoslugfield-label">
                Copy Promoter Invitation Link
                <Clipboard text={_promoterLink} />
              </label>
              <div className="wrapper-promoter-link-box">
                <div onClick={this.copyToClipboard} className="wrapper-promoter-link">
                  <img
                    className="ico-show-small image-promoter-link"
                    src={asset('/resources/images/system_icons/links.svg')}
                  />
                  <div className="link-prefix-url" style={{ display: 'flex', flexDirection: 'row' }}>
                    {_promoterLink}
                    <Clipboard containerStyle={{ marginLeft: 10 }} text={_promoterLink} />
                  </div>
                </div>
                <div className="generic-buttons-box">
                  <Button
                    className="btn btn-default btn-shadow"
                    type="button"
                    disabled={!isActivatedGenericPromoterInvitation}
                    onClick={this.regenerateGenericPromoterInvitation}
                  >
                    <i className="fa fa-undo" /> Regenerate
                  </Button>
                  {showGenericPromoterInvitationButton && (
                    <Button
                      className={`btn ${
                        isActivatedGenericPromoterInvitation ? 'btn-danger' : 'btn-success'
                      } btn-shadow`}
                      type="button"
                      style={{ marginLeft: 10 }}
                      onClick={this.disableOrEnableGenericPromoterInvitation}
                    >
                      <i className="fa fa-minus-circle" aria-hidden="true" />{' '}
                      {isActivatedGenericPromoterInvitation ? 'Deactivate' : 'Enable'}{' '}
                      {loadingGenericPromoterInvitation && (
                        <i className="fa fa-circle-o-notch fa-fw fa-spin" />
                      )}
                    </Button>
                  )}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <i className="fa fa-circle-o-notch fa-fw fa-spin" />
        )}
        <h3 className="heading_style">Team Invitations</h3>
        <div className="btn-toolbar text-right">
          <Button className="btn btn-success" onClick={this.onClickNewInvitation}>
            <i className="fa fa-fw fa-plus" /> Invite New Member
          </Button>
        </div>
        <div className="brand-team-invitations">
          {loadingInvitations && (
            <LoadingBar
              key="loadingbar"
              title={"Hold tight! We're getting your brand's team invitations..."}
            />
          )}
          {!loadingInvitations && (
            <JSONDatatable
              type={TYPE_FROM_ARRAY}
              data={invitations}
              sort={{ index: 0, asc: true }}
              getFilteredRows={this.getInvitationsFilteredRows}
              getSortedRows={this.getInvitationsSortedRows}
              getTableData={this.getInvitationsTableData}
              autoRefresh={20 * 1000}
              loadingBarTitle={"Hold tight! We're getting your brand's team invitations..."}
            >
              <div ref={SEARCHBAR} hasSearch labelTotalCount="Number of Matching Invitations" />
              <div ref={DATATABLE} />
            </JSONDatatable>
          )}
        </div>
        <Modal
          className="event-team-new-invitation modal-trans"
          style={modalStyle}
          isOpen={showNewInvitationModal}
          contentLabel="Modal"
          onRequestClose={this.onCancelNewInvitation}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">New Team Invitation</div>
              <div className="modal-body">
                <BrandTeamInvitationForm
                  onSubmit={this.handleNewInvitationSubmit}
                  onCancel={this.onCancelNewInvitation}
                  submitLabel={'Invite'}
                  initialValues={INVITATION_INITIAL_VALUES}
                  isNew={true}
                />
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="event-team-new-invitation modal-trans"
          style={modalStyle}
          isOpen={showEditConfirmModal}
          contentLabel="Modal"
          onRequestClose={this.onCancelEdit}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">Edit Team Invitation</div>
              <div className="modal-body">
                <BrandEditTeamInvitationForm
                  onSubmit={this.handleEditInvitationSubmit}
                  onCancel={this.onCancelEdit}
                  submitLabel={'Edit'}
                  initialValues={{
                    attributes: {
                      role: permissionToEdit?.roleBrand?.role
                    }
                  }}
                />
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="event-team-delete-confirm modal-trans"
          style={modalStyle}
          isOpen={showDeleteConfirmModal}
          contentLabel="Modal"
          onRequestClose={this.onCancelDelete}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">Permission Delete</div>
              <div className="modal-body">{deleteConfirmMsg}</div>
              <div className="modal-footer">
                <div className="btn-toolbar btn-toolbar-right">
                  <Button
                    className="btn btn-success btn-shadow"
                    type="button"
                    onClick={this.deletePermission}
                    disabled={deleting}
                  >
                    Ok
                  </Button>
                  <Button
                    className="btn btn-default btn-shadow"
                    type="button"
                    onClick={this.onCancelDelete}
                    disabled={deleting}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        <Modal
          className="brand-team-cancel-invitation-confirm modal-trans"
          style={modalStyle}
          isOpen={showCancelInvitationModal}
          contentLabel="Modal"
          onRequestClose={this.onCancelInvitationCancel}
          closeTimeoutMS={150}
          ariaHideApp={false}
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">Invitation Cancel</div>
              <div className="modal-body">{invitationCancelConfirmMsg}</div>
              <div className="modal-footer">
                <div className="btn-toolbar btn-toolbar-right">
                  <Button
                    className="btn btn-success btn-shadow"
                    type="button"
                    onClick={this.cancelInvitation}
                    disabled={cancelingInvitation}
                  >
                    Ok
                  </Button>
                  <Button
                    className="btn btn-default btn-shadow"
                    type="button"
                    onClick={this.onCancelInvitationCancel}
                    disabled={cancelingInvitation}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    )
  }
}
