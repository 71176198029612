import React from 'react'
import _cloneDeep from 'lodash/cloneDeep'
import _forEach from 'lodash/forEach'
import _map from 'lodash/map'
import _keys from 'lodash/keys'
import _isEmpty from 'lodash/isEmpty'
import _find from 'lodash/find'
import _get from 'lodash/get'
import { withFormik } from 'formik'
import { Element, Events, scroller } from 'react-scroll'

import Button from '../../../_library/Button'
import { EVENT_DESCRIPTION_LANGUAGES } from '../../../constants/languages.js'

import PasswordsCard from '../PasswordsCard'
import NFTCard from '../NFTCard'
import ImportFaceBookEvent from './ImportFacebookEvent'
import EventDetails from './EventDetails'
import EventVenue from './Venue/EventVenue'
import EventDescription from './EventDescription'
import EventConfirmationText from './EventConfirmationText'
import EventImages from './EventImages'
import EventType from './EventType'
import EventSettings from './EventSettings'
import EventSocialMedia from './EventSocialMedia'
import EventTags from './EventTags'
import EventAlternativeViews from './EventAlternativeViews'
import OpenGraphImages from './OpenGraphImages'
import { validateEvent } from './validation'
import { getDataFromProps, getRnMetaData, getScrollToElement } from './utils'
import RelativeError from '../../../_library/RelativeError'
import PayoutDetails from './PayoutDetails'
import EventPaymentMethod from '../common/EventPaymentMethod'
import { ShopifyCard } from '../../../shopify'
import { asyncComponent, DisableSectionByPermissionWrapper } from '../../../hoc'
import { connect } from 'react-redux'
import { FETCH_BRAND } from '../../../../_common/redux/brands/actions'
import { countryName } from '../../../../_common/core/countries'
import ConfirmModal from '../../../_library/ConfirmModal'
import { countryCodeAdapter } from '../../../utils/coutriesUtils'

const BlockSeatsCard = asyncComponent(() => import('./SeatsCard/index'))

const languages = getLanguages()
export const defaultLanguage = 'en'

class MyForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      fbURL: '',
      importFacebook: false,
      descriptionLanguage: defaultLanguage,
      imageLanguage: defaultLanguage,
      languageOptions: [],
      hasVenueChanged: false,
      isOpenVenueChangedModal: false,
      isOpenLocationDisclosureModal: false,
    }
  }

  componentDidMount() {
    const { values } = this.props
    let languageOptions = _map(_keys(values.descriptions), d => ({
      label: languages[d] + `${d === defaultLanguage ? ' (Default)' : ''}`,
      value: d,
    }))
    if (!languageOptions.length) {
      languageOptions = EVENT_DESCRIPTION_LANGUAGES
    }

    this.setState({
      imageLanguage: defaultLanguage,
      languageOptions,
    })

    const { FETCH_BRAND, event } = this.props
    event?.owner?.id && FETCH_BRAND(event.owner.id)
  }

  componentWillReceiveProps(nextProps) {
    const { isSubmitting, event } = this.props
    if (!_isEmpty(nextProps.errors) && isSubmitting) {
      Events.scrollEvent.register('end', () => {
        Events.scrollEvent.remove('end')
      })

      const scrollTo = getScrollToElement(nextProps.errors)

      scroller.scrollTo(scrollTo, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart',
        offset: -70,
      })
    }

    if (nextProps.selectedBrand !== this.props.selectedBrand) {
      const { organizations, selectedBrand } = nextProps
      if (selectedBrand) {
        this.updateField('owner', _find(organizations, { id: selectedBrand }))
      }
    }
    if (nextProps.event) {
      if (nextProps.event.blurredBackgroundUrl !== event.blurredBackgroundUrl) {
        this.updateField('blurredBackgroundUrl', nextProps.event.blurredBackgroundUrl)
      }
    }
  }

  updateField = (field, value) => {
    const { setFieldValue } = this.props
    setFieldValue(field, value)
    const fieldName = field === 'owner' ? 'owner' : `${field}`
    const fields = form_helper_get()
    if (field === 'flagNftOwnershipRequired' && !value) {
      setFieldValue('nftContracts.ethereum', [])
      setFieldValue('nftContracts.polygon', [])
      setFieldValue('nftLists.solana-mainnet', [])
    }
    if (fieldName !== 'checkInStart' && fieldName !== 'checkInEnd') {
      fields[fieldName] = fieldName
      form_helper_set(fields)
    }
  }

  handleChangeAutoBackground = e => {
    const { checked } = e.target
    this.updateField('autoBackground', checked)
  }

  closeFBDialog = () => {
    this.setState({ importFacebook: false })
  }

  onFbEvent = e => {
    this.setState({ fbURL: e.target.value })
  }

  onClickNewBrand = () => {
    if (this.props.onClickNewBrand) this.props.onClickNewBrand()
  }

  onLanguageChange = (e, fieldKey) => {
    this.setState({
      [fieldKey]: e.target.value,
    })
  }

  handleChangeImageAsEventName = e => {
    const checked = e.target ? e.target.checked : e
    this.updateField('useImageInsteadOfTitle', checked)
  }

  handleVenueChange = () => {
    this.setState({ hasVenueChanged: true })
  }

  handleSubmit = e => {
    e.preventDefault()
    const { hasVenueChanged } = this.state
    const { event, handleSubmit, values } = this.props

    if (hasVenueChanged && !values.flagHidden && (event?.hasSales || event?.hasRsvpCustomer)) {
      this.setState({ isOpenVenueChangedModal: true })
    } else if (hasVenueChanged && values.flagHidden && !values.hideUntil) {
      this.setState({ isOpenLocationDisclosureModal: true })
    } else {
      handleSubmit()
      this.setState({ hasVenueChanged: false })
    }
  }

  onConfirmResponse = value => {
    const { setFieldValue, handleSubmit } = this.props
    if (value === 'yes') {
      setFieldValue('notifyTicketHolders', true, false)
    }
    this.setState({ isOpenVenueChangedModal: false, hasVenueChanged: false })
    handleSubmit()
  }

  onConfirmLocationDisclosure = value => {
    const { handleSubmit } = this.props
    if (value === 'yes') {
      handleSubmit()
    }
    this.setState({ isOpenLocationDisclosureModal: false, hasVenueChanged: false })
  }

  render() {
    const {
      importFacebook,
      descriptionLanguage,
      imageLanguage,
      languageOptions,
      fbURL,
      hasVenueChanged,
      isOpenVenueChangedModal,
      isOpenLocationDisclosureModal,
    } = this.state
    const {
      submitLabel,
      organizations,
      isNew,
      cardsStatus,
      onTokenResponse,
      values,
      touched,
      errors,
      isSubmitting,
      resetedDescriptions,
      updateResetedDescriptions,
      isRnDomain,
      //useImageInsteadOfTitle,
      //handleChangeImageAsEventName,
      alternativeViews,
      onClickAlternativeView,
      onEditAlternativeView,
      deleteView,
      user,
      dynamicConfigs,
      passwords,
      onCreatePassword,
      onDeletePassword,
      onRefreshPasswords,
      event,
      shopifyConnectSubmit,
      onDisconnectShopify,
      onSopifyUpdate,
      onSopifyRefresh,
      shopifyInfo,
      shopifyConnectLoading,
      shopifyDisconnectLoading,
      shopifyRefreshLoading,
      fetchProductsLoading,
      globalCountries,
      brand,
      FETCH_BRAND,
      setFieldValue,
      configs,
    } = this.props
    const showEventAlternativeViews = _get(configs, "appearance.showEventAlternativeViews", true)
    const showEventPayoutDetails = _get(configs, "appearance.showEventPayoutDetails", true)
    const showOpenGraphImages = _get(configs, "appearance.showOpenGraphImages", true)
    const showEventCustomConfirmationText = _get(configs, "appearance.showEventCustomConfirmationText", true)
    const { autoBackground, useImageInsteadOfTitle, nftContracts, nftInput, nftLists } = values
    const flagIsStripeConnected = brand?.flagIsStripeConnected || event?.flagIsStripeConnected
    const showPaymentData = (values.flagPaymentDetails || !flagIsStripeConnected) && showEventPayoutDetails
    const isFundsRemmited = event?.flagFundsRemitted || false
    const hideShopify = _get(configs, 'appearance.hideShopify', false)

    return (
      <DisableSectionByPermissionWrapper>
        <div className="event-form">
          <ConfirmModal
            isOpen={isOpenVenueChangedModal}
            header="Confirm"
            content="Do you want to send venue update information to all ticket holders and RSVPs?"
            actions={[
              { value: 'yes', label: 'yes', className: 'btn btn-success btn-shadow' },
              { value: 'no', label: 'no', className: 'btn btn-default' },
            ]}
            onClose={() => this.setState({ isOpenVenueChangedModal: false })}
            onAction={this.onConfirmResponse}
          />
          <ConfirmModal
            isOpen={isOpenLocationDisclosureModal}
            header="Confirm"
            // eslint-disable-next-line max-len
            content="If the location disclosure date is not specified ticket holders will receive location information, but it will be hidden from the event page."
            actions={[{ value: 'yes', label: 'OK', className: 'btn btn-success btn-shadow' }]}
            onClose={() => this.setState({ isOpenLocationDisclosureModal: false })}
            onAction={this.onConfirmLocationDisclosure}
          />
          {!!isNew && (
            <div>
              <div className="body-panel-header">
                <div className="left">
                  <div className="title">Add New Event</div>
                </div>
              </div>
              <div className="body-panel-spacing" />
            </div>
          )}
          <div className={!!isNew ? 'body-panel-content' : ''}>
            {!!isNew && (
              <ImportFaceBookEvent
                values={values}
                fbURL={fbURL}
                importFacebook={importFacebook}
                onFbEvent={this.onFbEvent}
                descriptionLanguage={descriptionLanguage}
                imageLanguage={imageLanguage}
                updateField={this.updateField}
                closeFBDialog={this.closeFBDialog}
                onTokenResponse={onTokenResponse}
                handleChangeDescription={this.handleChangeDescription}
              />
            )}
            <form autoComplete="off" ref="form" method="POST" onSubmit={this.handleSubmit}>
              <Element name="scroll-to-top" />
              <input
                type="hidden"
                ref="image"
                value={(values.imageURLs && values.imageURLs[imageLanguage]) || ''}
              />
              <input type="hidden" ref="banner" value={values.bannerURL || ''} />
              <EventDetails
                isNew={isNew}
                values={values}
                errors={errors}
                touched={touched}
                cardsStatus={cardsStatus}
                organizations={organizations}
                updateField={this.updateField}
                onClickNewBrand={this.onClickNewBrand}
                isRnDomain={isRnDomain}
                FETCH_BRAND={FETCH_BRAND}
                isFundsRemmited={isFundsRemmited}
                configs={_get(configs, 'children.EventDetails')}
              />
              <Element name="scroll-to-eventVenue" />
              <EventVenue
                event={event}
                brand={brand}
                isNew={isNew}
                values={values}
                errors={errors}
                touched={touched}
                cardsStatus={cardsStatus}
                updateField={(key, value) => {
                  this.updateField(key, value)
                  this.handleVenueChange()
                }}
                isRnDomain={isRnDomain}
                globalCountries={globalCountries}
                hasVenueChanged={hasVenueChanged}
                configs={_get(configs, 'children.EventVenue')}
              />
              {!isNew && <BlockSeatsCard event={event} />}
              <Element name="scroll-to-eventDescription" />
              <EventDescription
                values={values}
                errors={errors}
                touched={touched}
                cardsStatus={cardsStatus}
                updateField={this.updateField}
                descriptionLanguage={descriptionLanguage}
                languageOptions={languageOptions}
                onLanguageChange={this.onLanguageChange}
                resetedDescriptions={resetedDescriptions}
                updateResetedDescriptions={updateResetedDescriptions}
                isRnDomain={isRnDomain}
                configs={_get(configs, 'children.EventDescription')}
                user={user}
              />
              {showEventCustomConfirmationText ? (
                <EventConfirmationText
                  values={values}
                  errors={errors}
                  touched={touched}
                  updateField={this.updateField}
                />
              ) : null}
              <Element name="scroll-to-eventImages" />
              <EventImages
                values={values}
                errors={errors}
                cardsStatus={cardsStatus}
                updateField={this.updateField}
                imageLanguage={imageLanguage}
                languageOptions={languageOptions}
                onLanguageChange={this.onLanguageChange}
                autoBackground={autoBackground}
                useImageInsteadOfTitle={useImageInsteadOfTitle}
                handleChangeAutoBackground={this.handleChangeAutoBackground}
                handleChangeImageAsEventName={this.handleChangeImageAsEventName}
                isRnDomain={isRnDomain}
                configs={_get(configs, 'children.EventImages')}
              />
              <Element name="scroll-to-eventMediaImages" />
              {showOpenGraphImages ? (
                <OpenGraphImages values={values} errors={errors} updateField={this.updateField} />
              ) : null}
              {!!_get(dynamicConfigs, 'show_alternative_view', false) && !isNew && showEventAlternativeViews && (
                <EventAlternativeViews
                  values={values}
                  cardsStatus={cardsStatus}
                  errors={errors}
                  touched={touched}
                  updateField={this.updateField}
                  isRnDomain={isRnDomain}
                  alternativeViews={alternativeViews}
                  onClickAlternativeView={onClickAlternativeView}
                  onEditAlternativeView={onEditAlternativeView}
                  deleteView={deleteView}
                  configs={_get(configs, 'children.EventAlternativeViews')}
                />
              )}
              <Element name="scroll-to-eventType" />
              <EventType
                values={values}
                errors={errors}
                touched={touched}
                cardsStatus={cardsStatus}
                updateField={this.updateField}
                isRnDomain={isRnDomain}
              />
              {isRnDomain && (
                <>
                  <Element name="scroll-to-eventGenres" />
                  <EventType
                    values={values}
                    errors={errors}
                    touched={touched}
                    cardsStatus={cardsStatus}
                    updateField={this.updateField}
                    isRnDomain={isRnDomain}
                    showGenres={true}
                  />
                </>
              )}
              {errors.oneOfTags && <RelativeError errorClass="oneOfTags">{errors.oneOfTags}</RelativeError>}
              <Element name="scroll-to-eventSettings" />
              <EventSettings
                values={values}
                cardsStatus={cardsStatus}
                updateField={this.updateField}
                isRnDomain={isRnDomain}
                user={user}
                configs={_get(configs, 'children.EventSettings')}
              />
              <Element name="scroll-to-nft" />
              {values.flagNftOwnershipRequired && (
                <NFTCard
                  nftContracts={nftContracts}
                  nftInput={nftInput}
                  nftLists={nftLists}
                  errors={errors}
                  touched={touched}
                  setFieldValue={setFieldValue}
                />
              )}
              {values.flagPasswordProtected && !isNew && (
                <PasswordsCard
                  passwords={passwords}
                  onCreatePassword={onCreatePassword}
                  onDeletePassword={onDeletePassword}
                  onRefreshPasswords={onRefreshPasswords}
                />
              )}
              <Element name="scroll-to-eventAdditionalInformation" />
              <EventSocialMedia
                values={values}
                errors={errors}
                touched={touched}
                cardsStatus={cardsStatus}
                updateField={this.updateField}
                isRnDomain={isRnDomain}
                user={user}
              />
              <EventTags
                values={values}
                cardsStatus={cardsStatus}
                updateField={this.updateField}
                isRnDomain={isRnDomain}
              />
              <Element name="scroll-to-PayoutDetails" />
              {showPaymentData && (
                <PayoutDetails
                  event={event}
                  brand={brand}
                  values={values}
                  errors={errors}
                  touched={touched}
                  enableToggle={true}
                  updateField={this.updateField}
                  handleChange={(e, data) => {
                    const fieldKey = e.target ? e.target.id : e
                    const value = e.target ? e.target.value : data
                    this.updateField(fieldKey, value)
                  }}
                  flagIsStripeConnected={flagIsStripeConnected}
                />
              )}
              <EventPaymentMethod
                event={event}
                user={user}
                configs={_get(configs, 'children.EventPaymentMethod')}
              />
              {user.tfStaff
                ? !isNew &&
                  _get(dynamicConfigs, 'show_connect_shopify', false) &&
                  !hideShopify && (
                  <ShopifyCard
                    id={event.id}
                    onSubmit={shopifyConnectSubmit}
                    onDisconnectShopify={onDisconnectShopify}
                    shopifyInfo={shopifyInfo}
                    shopifyConnectLoading={shopifyConnectLoading}
                    shopifyDisconnectLoading={shopifyDisconnectLoading}
                    shopifyRefreshLoading={shopifyRefreshLoading}
                    fetchProductsLoading={fetchProductsLoading}
                    onUpdate={onSopifyUpdate}
                    onRefresh={onSopifyRefresh}
                    title="Shopify"
                  />
                )
                : null}
              <div className="card-block text-center">
                <Button className="btn btn-success btn-lg btn-shadow" type="submit" loading={isSubmitting}>
                  {submitLabel || 'Create Event'}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </DisableSectionByPermissionWrapper>
    )
  }
}

const EventForm = withFormik({
  mapPropsToValues: props => getDataFromProps(props),
  // Custom sync validation
  validate: (values, props) => validateEvent(values, props),
  enableReinitialize: true,

  handleSubmit: (values, { props, setSubmitting, setFieldValue, setValues }) => {
    let updatedValues = _cloneDeep(values)
    const updatedDescriptions = updatedValues.descriptions
    const {
      useImageInsteadOfTitle,
      accountName,
      accountNumber,
      flagValidateAge,
      minimumAge,
      country,
      countryCode,
    } = updatedValues

    const bankingDetails = {
      accountName,
      accountNumber,
      routingNumber: '',
      bsb: '',
      sortCode: '',
      ifscNumber: '',
      bankName: '',
      accountType: '',
      swiftCode: '',
      fullAddress: '',
      clabe: '',
      countryCode,
      transitNumber: null,
      institutionNumber: null,
    }

    const brandCountry = countryCodeAdapter(props.brand?.country, 'uk', 'gb') || ''
    const isSameCountry = countryName(brandCountry) === country

    if ((!isSameCountry && countryCode === 'MX') || (isSameCountry && country === 'Mexico')) {
      bankingDetails.swiftCode = updatedValues.swiftCode
      bankingDetails.clabe = updatedValues.clabe
      bankingDetails.fullAddress = updatedValues.fullAddress
    }

    if ((!isSameCountry && countryCode === 'US') || (isSameCountry && country === 'United States')) {
      bankingDetails.routingNumber = updatedValues.routingNumber
      bankingDetails.accountType = updatedValues.accountType
    }

    if ((!isSameCountry && countryCode === 'AU') || (isSameCountry && country === 'Australia')) {
      bankingDetails.bsb = updatedValues.bsb
    }

    if ((!isSameCountry && countryCode === 'GB') || (isSameCountry && country === 'United Kingdom')) {
      bankingDetails.sortCode = updatedValues.sortCode
    }

    if ((!isSameCountry && countryCode === 'IN') || (isSameCountry && country === 'India')) {
      bankingDetails.ifscNumber = updatedValues.ifscNumber
      bankingDetails.bankName = updatedValues.bankName
    }

    if ((!isSameCountry && countryCode === 'CA') || (isSameCountry && country === 'Canada')) {
      const { transitNumber, institutionNumber } = updatedValues
      bankingDetails.transitNumber = transitNumber
      bankingDetails.institutionNumber = institutionNumber
    }

    if (updatedValues.autoBackground && !props.isRnDomain) {
      updatedValues.backgroundURL = null
      setFieldValue('backgroundURL', null)
    }
    if (!useImageInsteadOfTitle) {
      updatedValues.titleReplacementImage = null
      setValues({ ...updatedValues, titleReplacementImage: null })
    } else {
      const { titleReplacementImage } = updatedValues
      if (titleReplacementImage && !titleReplacementImage.startsWith('https://')) {
        const replacedImage = updatedValues.titleReplacementImage
        delete updatedValues.titleReplacementImage
        setValues({ ...updatedValues, titleReplacementImage: replacedImage })
      }
    }
    if (!props.isRnDomain) {
      _forEach(updatedValues.descriptions, (value, key) => {
        if (_find(props.resetedDescriptions, r => r === key)) {
          updatedDescriptions[key] = null
        } else {
          updatedDescriptions[key] = value
        }
      })
      updatedValues.descriptions = updatedDescriptions
    }
    if (props.isRnDomain) {
      updatedValues = getRnMetaData(updatedValues, props)
      updatedValues.backgroundURL = null
    }
    updatedValues = {
      ...updatedValues,
      rsvpAlternativeInformation: updatedValues.rsvpAlternativeInformation || null,
      bankingDetails: {
        ...bankingDetails,
        transitNumber: bankingDetails.transitNumber || null,
        institutionNumber: bankingDetails.institutionNumber || null,
      },
    }

    if (flagValidateAge) {
      updatedValues = {
        ...updatedValues,
        minimumAge: minimumAge === 0 ? null : minimumAge,
      }
    }

    if (!updatedValues.flagPaymentDetails) {
      updatedValues.bankingDetails = {
        accountName: '',
        accountNumber: '',
        routingNumber: '',
        accountType: '',
        bsb: '',
        sortCode: '',
        ifscNumber: '',
        bankName: '',
        swiftCode: '',
        clabe: '',
        fullAddress: '',
        countryCode: '',
        transitNumber: null,
        institutionNumber: null,
      }
    }

    updatedValues.flagEnableAddOnWaitingList = true
    updatedValues.flagEnableAddOnResale = true

    delete updatedValues.flagPaymentDetails
    props
      .onSubmit({
        attributes: { ...updatedValues },
        relationships: {
          owner: {
            data: values.owner,
          },
        },
      })
      .then(v => {
        setSubmitting(false)
        setFieldValue('notifyTicketHolders', false)
      })
      .catch(err => {
        setSubmitting(false)
      })
  },
  displayName: 'EventForm', // helps with React DevTools
})(MyForm)

const EventFormRedux = connect(
  state => {
    const brand = state.brands.selected
    return {
      brand,
    }
  },
  {
    FETCH_BRAND,
  },
)(EventForm)

export default EventFormRedux
