import _get from 'lodash/get'
import _map from 'lodash/map'
import _forEach from 'lodash/forEach'
import _filter from 'lodash/filter'
import _isEmpty from 'lodash/isEmpty'
import _isNull from 'lodash/isNull'
import _sortBy from 'lodash/sortBy'
import _find from 'lodash/find'
import _isNaN from 'lodash/isNaN'

export const addOnsAdapter = (data = []) => {
  //addons and addon_groups are at the same level,here we made them together in addons[]
  const addons = data.attributes.add_ons
  const addon_groups = data.attributes.add_on_groups

  return _map(addons, el => ({
    ..._get(el, 'attributes', {}),
    isGroup: false,
    addOnGroupName: _get(
      _find(addon_groups, groupItem => groupItem.id === el.attributes.addOnGroupId),
      'attributes.name',
    ),
  })).concat(_map(addon_groups, el => ({ ..._get(el, 'attributes', {}), isGroup: true })))
}

export const addOnAdapter = (addOn = {}) => {
  const attributes = _get(addOn, 'attributes', {})

  return { ...addOn, ...attributes }
}

export const addOnFormAdapter = (form, addons, variantsData = []) => {
  const nonGroupAddOns = _filter(addons, addon => !addon.isGroup)

  const formData = { ...form }
  const variants = [...variantsData]
  if (!formData.ticketTypeNeeded) {
    formData.prerequisiteTicketTypeIds = null
  }
  delete formData.ticketTypeNeeded

  if (formData.stockUnlimited) {
    formData.stock = null
  }
  delete formData.stockUnlimited

  formData.price = parseInt(formData.price) * 100
  formData.sortOrder = nonGroupAddOns.length + 1
  if (formData.hasVariant) {
    _forEach(variants, (variant, index) => {
      variant.sortOrder = nonGroupAddOns.length + index + 1
    })
  }

  // Add addon group
  if (formData.hasVariant) {
    formData.variants = variants
    delete formData.stock
    delete formData.sortOrder
  }

  return formData
}

export const editAddOnFormAdapter = form => {
  const formData = { ...form }

  if (!formData.ticketTypeNeeded) {
    formData.prerequisiteTicketTypeIds = null
  }

  delete formData.ticketTypeNeeded

  if (formData.stockUnlimited || formData.isUnlimited) {
    formData.stock = null
  }

  delete formData.stockUnlimited

  return formData
}

export const addonStockAdapter = addons => {
  const addonVariants = _filter(addons, item => item.addOnGroupId)
  const addonGroups = _filter(addons, item => !addonVariants.includes(item))
  const groupsWithStocks = []

  _forEach(addonGroups, group => {
    const groupVariants = _filter(addonVariants, variant => variant.addOnGroupId === group.id)
    let isUnlimited = false
    let sum = 0
    if (!_isEmpty(groupVariants)) {
      const groupObj = { ...group, stock: '' }

      _forEach(groupVariants, variant => {
        if (!_isNull(variant.stock) && !_isNaN(Number(variant.stock))) {
          sum += Number(variant.stock)
        } else {
          isUnlimited = true
        }
      })

      if (!isUnlimited) {
        groupObj.stock = sum
      } else {
        groupObj.stock = null
      }
      groupsWithStocks.push(groupObj)
    } else {
      groupsWithStocks.push(group)
    }
  })
  return [...addonVariants, ...groupsWithStocks]
}

export const addOnSortAdapter = (data, addons) => {
  const sortableData = { ...data }
  const dataWithSortedVariants = []

  _forEach(sortableData, sortableAddOn => {
    if (!_isNull(sortableAddOn.addOnGroupId)) {
      const addOnVariants = _filter(addons, addOn => addOn.addOnGroupId === sortableAddOn.id)
      _forEach(addOnVariants, addOnVariant => {
        const addOnVariantCopy = { ...addOnVariant, sortOrder: sortableAddOn.sortOrder }
        dataWithSortedVariants.push(addOnVariantCopy)
      })
    } else {
      dataWithSortedVariants.push(sortableAddOn)
    }
  })

  const sortedData = _sortBy(dataWithSortedVariants, variants => variants.sortOrder)
  _forEach(sortedData, (addOn, index) => {
    if (index > 0) {
      if (addOn.sortOrder <= sortedData[index - 1].sortOrder) {
        addOn.sortOrder += sortedData[index - 1].sortOrder - addOn.sortOrder + 1
      }
    }
  })
  return sortedData
}

export const addOnGroupSortAdapter = (addOnGroups, addOnsVariants) => {
  const sortableAddOnGroups = [...addOnGroups]
  const sortableAddOnGroupsCopy = []
  _forEach(sortableAddOnGroups, group => {
    if (!_isNull(group.addOnGroupId)) {
      const groupRelatedAddOn = _find(addOnsVariants, variant => variant.addOnGroupId === group.id)
      sortableAddOnGroupsCopy.push({ ...group, sortOrder: Number(groupRelatedAddOn?.sortOrder) })
    } else {
      sortableAddOnGroupsCopy.push({ ...group, sortOrder: Number(group.sortOrder) })
    }
  })
  const sortedAddOnGroups = _sortBy(sortableAddOnGroupsCopy, addOn => addOn.sortOrder)
  return sortedAddOnGroups
}

export const addOnBreakdownDataAdapter = (addons = []) => {
  const adaptedData = addons.map(addon => ({ 
    ...addon, 
    name: addon.groupName ? `${addon.groupName}: ${addon.name}` : addon.name,
  }))

  return adaptedData
}
