import React, { Fragment } from 'react'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _isNaN from 'lodash/isNaN'
import _forEach from 'lodash/forEach'
import _isEmpty from 'lodash/isEmpty'
import _isNumber from 'lodash/isNumber'
import _isEqual from 'lodash/isEqual'
import { Formik, Form, Field } from 'formik'

import Button from '../../_library/Button'
import TicketsSection from './TicketsSection'
import { SimpleField, FileUploaderField, CustomToggleField } from '../../formik/Fields'
import { RichTextAreaField } from '../../formik/Fields'
import {
  createFixedFloatNormalizer,
  toInt,
  toNumber,
  requiredValidator,
} from '../../../_common/core/validation'
import { getTableColumns } from '../../../web/utils/sortableTableUtils.js'
import AddOnVariantCreateModal from './modals/AddOnVariantCreateModal'
import SortableTable from '../../_library/SortableTable'
import { OverlayTrigger } from '../../_library/OverlayTrigger'
import { addOnStockNormalizer } from './normalizers/addOnStockNormalizer'

const intRE = /\d+/

function validateForm(data) {
  const errors = {}

  if (!_get(data, 'stockUnlimited')) {
    const rawstock = _get(data, 'stock')
    const stock = parseInt(rawstock, 10)
    const maxQuantity = _get(data, 'maxQuantity')
    const maxQuantityPerTicketType = _get(data, 'maxQuantityPerTicketType')
    const variantIsEnabled = _get(data, 'hasVariant')

    if ((!intRE.test(rawstock) || _isNaN(stock) || stock < 0) && !variantIsEnabled) {
      _set(errors, 'stock', 'Value must be greater than or equal to 0')
    }

    if (maxQuantity && maxQuantity > stock) {
      _set(errors, 'maxQuantity', 'Value must be less than or equal to stock value')
    }

    _forEach(maxQuantityPerTicketType, item => {
      if (item.quantity > stock) {
        _set(errors, 'maxQuantityPerTicketType', {
          ...errors.maxQuantityPerTicketType,
          [item.ticket_type]: 'Value must be less than or equal to stock value',
        })
      }
    })
  }

  return errors
}

class AddOnForm extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      variants: [],
      showAddVariant: false,
    }
    this.tableOrdersColumns = getTableColumns([
      {
        label: 'Name',
        key: 'name',
      },
      {
        label: props.stockLabel,
        key: 'stock',
        normalizer: addOnStockNormalizer,
      },
    ])
  }

  renderInitialValues = () => {
    const { initialValues, isEditing, isAddOnGroupSelected } = this.props
    const price = _get(initialValues, 'price', '')
    const stockValue = _get(initialValues, 'stock')
    const stockUnlimited = _isNumber(stockValue) ? false : true
    const stock = _isNumber(stockValue) ? stockValue : ''
    const values = {
      name: _get(initialValues, 'name', ''),
      stock,
      stockUnlimited: isEditing ? stockUnlimited : false,
      price: price ? createFixedFloatNormalizer(2)(parseFloat(price) / 100) : price,
      description: _get(initialValues, 'description', ''),
      active: _get(initialValues, 'active', true),
      ticketTypeNeeded: !!_get(initialValues, 'prerequisiteTicketTypeIds', false),
      prerequisiteTicketTypeIds: _get(initialValues, 'prerequisiteTicketTypeIds', []),
      flagLimitToTicketQuantity: _get(initialValues, 'flagLimitToTicketQuantity', true),
      maxQuantity: !isEditing ? _get(initialValues, 'maxQuantity') || '' : initialValues.maxQuantity || '',
      limitPerTicket: !isEditing
        ? _get(initialValues, 'maxQuantity') || ''
        : initialValues?.limitPerTicket || '',
      withholdFromResale: _get(initialValues, 'withholdFromResale', false),
      imageUrl: _get(initialValues, 'imageUrl'),
      hasVariant: isEditing && isAddOnGroupSelected,
      sortOrder: _get(initialValues, 'sortOrder'),
    }
    return values
  }

  openAddVariantModal = () => {
    this.setState({ showAddVariant: true })
  }

  onConfirmAddVariantResponse = (response, variants) => {
    switch (response) {
      case 'save':
        this.setState(prevState => ({
          variants: [...prevState.variants, ...variants],
          showAddVariant: false,
        }))
        break
      case 'cancel':
        this.setState(prevState => ({
          ...prevState,
          showAddVariant: false,
        }))
        break
      default:
        break
    }
  }

  handleDeleteVariant = data => {
    const { variants } = this.state
    const updatedVariantsData = variants.filter(dataItem => !_isEqual(data, dataItem))

    this.setState({
      variants: updatedVariantsData,
    })
  }

  checkButtonDisabledState = hasVariant => {
    const { enableAddOnsWithVariants, isEditing } = this.props
    const { variants } = this.state
    if (enableAddOnsWithVariants && !isEditing && hasVariant && _isEmpty(variants)) {
      return true
    }
    return false
  }

  getEnableVariantsTooltip = () => {
    const { isEditing, isAddOnGroupSelected } = this.props
    if (!isEditing) {
      return `You are enabling variant creation for this add-on. Note: 
      If you create at least one variant,then you won't be able to disable the toggle.`
    }
    if (isAddOnGroupSelected) {
      return 'Variants can no longer be disabled for this add-on.'
    }
    return 'Variants can no longer be available for this add-on.'
  }

  render() {
    const {
      tickets,
      tables,
      isEditing,
      onCancel = () => {},
      handleSubmit,
      handleSubmitGroup,
      enableAddOnsWithVariants,
      configs,
      stockLabel,
    } = this.props
    const { variants, showAddVariant } = this.state
    const showWithholdFromResalToggle = _get(configs, 'appearance.showWithholdFromResalToggle')

    return (
      <Fragment>
        <AddOnVariantCreateModal
          isOpen={enableAddOnsWithVariants && showAddVariant}
          handleAction={this.onConfirmAddVariantResponse}
          stockLabel={stockLabel}
        />
        <Formik
          initialValues={this.renderInitialValues()}
          onSubmit={values => {
            if (values.hasVariant) {
              handleSubmitGroup(values, variants)
            } else {
              handleSubmit(values)
            }
          }}
          validate={validateForm}
          render={({ values, isSubmitting, touched, setFieldValue }) => {
            const isButtonDisabled = this.checkButtonDisabledState(values.hasVariant)
            return (
              <Form autoComplete="off" className="addon-form">
                <div className="row">
                  <div className="col-xs-12 col-sm-6">
                    <Field name="name" label="Name" component={SimpleField} validate={requiredValidator()} />
                  </div>
                  <div className="col-xs-12 col-sm-6">
                    <Field
                      name="price"
                      label="Price"
                      disabled={isEditing}
                      component={SimpleField}
                      normalizer={toNumber}
                      onBlurNormalizer={value => createFixedFloatNormalizer(2)(parseFloat(value))}
                      validate={requiredValidator()}
                    />
                  </div>
                  {!values.hasVariant && (
                    <div className="col-xs-12 col-sm-6" style={{ margin: '20px 0' }}>
                      <Field name="stockUnlimited" onText="Unlimited" component={CustomToggleField} />
                    </div>
                  )}
                  {!values.stockUnlimited && !values.hasVariant && (
                    <div className="col-xs-12 col-sm-6">
                      <Field
                        name="stock"
                        label={stockLabel}
                        component={SimpleField}
                        normalizer={toInt}
                        validate={requiredValidator()}
                      />
                    </div>
                  )}
                  {enableAddOnsWithVariants && (
                    <div className="col-xs-12 col-sm-12" style={{ margin: '20px 0' }}>
                      <OverlayTrigger
                        id="enable-variants-tooltip"
                        tooltip={this.getEnableVariantsTooltip()}
                        placement="top"
                      >
                        <div className="inline-block">
                          <Field
                            name="hasVariant"
                            onText="Enable Variants (Size, Color, Etc)"
                            component={CustomToggleField}
                            disabled={isEditing}
                            checked={values.hasVariant}
                          />
                        </div>
                      </OverlayTrigger>
                    </div>
                  )}
                  {enableAddOnsWithVariants && (
                    <>
                      {values.hasVariant && (
                        <>
                          <div className="col-xs-12 mt-2" style={{ margin: '20px 0' }}>
                            <Button
                              className="btn btn-success btn-shadow"
                              type="button"
                              onClick={this.openAddVariantModal}
                            >
                              Add variants
                            </Button>
                          </div>
                          {!!variants.length && (
                            <div className="col-xs-12 mt-2">
                              <SortableTable
                                data={variants}
                                tableColumns={this.tableOrdersColumns}
                                enableCopyTable={true}
                                enableSort={false}
                                disableInnerSort={true}
                                actions={[
                                  {
                                    label: 'Delete',
                                    className: 'btn btn-danger',
                                    icon: 'fa fa-trash',
                                    onClick: this.handleDeleteVariant,
                                  },
                                ]}
                              />
                            </div>
                          )}
                        </>
                      )}
                      <div className="col-xs-3 col-6">
                        <label style={{ paddingTop: '10px' }} htmlFor="image">
                          Photo/Video
                        </label>
                        <Field
                          name="imageUrl"
                          filetype="image"
                          label="Upload Photo/Video"
                          component={FileUploaderField}
                          accept="video/*,image/*"
                        />
                      </div>
                    </>
                  )}
                </div>
                <div className="row">
                  <div className="col-xs-12">
                    <Field
                      name="description"
                      label="Description"
                      disablePlugin={enableAddOnsWithVariants}
                      disableMedia={true}
                      component={RichTextAreaField}
                    />
                  </div>
                  <div className="col-xs-12">
                    <Field name="active" onText="Enabled" component={CustomToggleField} />
                  </div>
                  <div className="col-xs-12">
                    <Field
                      name="ticketTypeNeeded"
                      onText="Limit to Certain Ticket Types Only"
                      component={CustomToggleField}
                    />
                    {values.ticketTypeNeeded && (
                      <TicketsSection
                        tickets={tickets}
                        tables={tables}
                        groupedTicketIds={values.prerequisiteTicketTypeIds}
                        setTicketTypePreRequired={value => setFieldValue('prerequisiteTicketTypeIds', value)}
                        setTicketTypeLimit={value => setFieldValue('maxQuantityPerTicketType', value)}
                        stockValue={values.stock}
                        touched={touched.maxQuantityPerTicketType}
                        enableAddOnsWithVariants={enableAddOnsWithVariants}
                      />
                    )}
                  </div>
                  <div
                    className={`col-xs-12 col-sm-12 ${
                      !values.flagLimitToTicketQuantity ? 'spacing_bottom_20' : ''
                    }`}
                  >
                    <Field
                      name="flagLimitToTicketQuantity"
                      onText="Limit to ticket quantity"
                      component={CustomToggleField}
                    />
                  </div>
                  {!values.flagLimitToTicketQuantity && (
                    <>
                      <div className="col-xs-12 col-sm-6">
                        <Field
                          name="maxQuantity"
                          label="Maximum quantity (optional)"
                          component={SimpleField}
                          normalizer={toInt}
                        />
                      </div>
                      <div className="col-xs-12 col-sm-6">
                        <Field
                          name="limitPerTicket"
                          label="Limit per ticket (optional)"
                          component={SimpleField}
                          normalizer={toInt}
                        />
                      </div>
                    </>
                  )}
                  {showWithholdFromResalToggle && (
                    <div className="col-xs-12">
                      <Field
                        name="withholdFromResale"
                        onText="Withhold from Resale"
                        component={CustomToggleField}
                      />
                    </div>
                  )}
                </div>
                <div className="modal-footer center no-border">
                  <OverlayTrigger
                    id="save-addon-tooltip"
                    tooltip="Enable Variants toggle is turned on"
                    placement="top"
                    className={isButtonDisabled ? 'inline-block cursor-not-allowed' : 'inline-block'}
                    hideTooltip={!isButtonDisabled}
                  >
                    <div>
                      <Button
                        className={
                          isButtonDisabled
                            ? 'btn btn-success btn-shadow btn-disabled'
                            : 'btn btn-success btn-shadow'
                        }
                        loading={isSubmitting}
                        disabled={
                          (values.ticketTypeNeeded && _isEmpty(values.prerequisiteTicketTypeIds)) ||
                          isButtonDisabled
                        }
                        style={{ marginRight: '6px' }}
                      >
                        Save
                      </Button>
                    </div>
                  </OverlayTrigger>
                  <Button className="btn btn-cancel btn-shadow" disabled={isSubmitting} onClick={onCancel}>
                    Cancel
                  </Button>
                </div>
              </Form>
            )
          }}
        />
      </Fragment>
    )
  }
}

export default AddOnForm
