import moment from 'moment'
import React from 'react'
import Field from './Field'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import { defaultDateFormat } from '../../_common/core/validation/normalizers'

export default class DateTimePickerReact extends React.Component {
  componentDidMount() {
    const { suggestion, onChange, value, timestamp } = this.props
    const isYYYMMDDHHMMSSFormat = value
      ? /^([0-9]{4})-([0-1][0-9])-([0-3][0-9])\s([0-1][0-9]|[2][0-3]):([0-5][0-9]):([0-5][0-9])$/.test(value)
      : false
    if (suggestion && !value) {
      const d = timestamp
      ? this.getDateFromTimestamp(suggestion)
      : isYYYMMDDHHMMSSFormat ? this.geDateFromFormat(suggestion) : this.getDateFromUTC(suggestion)
      const m = moment.utc([d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), 0, 0])
      onChange(m.toISOString())
    }
  }
  handleChange = (d, isSelectedDay) => {
    const { value } = this.props
    const isYYYMMDDHHMMSSFormat = value
      ? /^([0-9]{4})-([0-1][0-9])-([0-3][0-9])\s([0-1][0-9]|[2][0-3]):([0-5][0-9]):([0-5][0-9])$/.test(value)
      : false
    const { onChange } = this.props
    if (isYYYMMDDHHMMSSFormat) {
      onChange(moment(d).format(defaultDateFormat))
      return
    }
    if (d) {
      const m = moment.utc([d.getFullYear(), d.getMonth(), d.getDate(), d.getHours(), d.getMinutes(), 0, 0])
      onChange(m.toISOString(), isSelectedDay)
    } else {
      onChange(null)
    }
  }
  getDateFromUTC = value => {
    const sameValue = moment.utc(value)
    return new Date(sameValue.year(), sameValue.month(), sameValue.date(), sameValue.hour(), sameValue.minute())
  }
  getDateFromTimestamp = value => new Date(new Date(value).toISOString().replace('Z', ''))
  geDateFromFormat = value => new Date(value.replace(/-/g, '/'))
  render() {
    const {
      size,
      className,
      loading,
      active,
      id,
      hint,
      hintClass,
      label,
      placeholder,
      value,
      onChange,
      children,
      disabled,
      timestamp,
      ...input
    } = this.props
    const { error, touched } = input
    const hasError = error && touched
    const before = []
    const after = []
    const rest = []
    React.Children.forEach(children, c => {
      if (!React.isValidElement(c)) {
        rest.push(c)
      } else if (c.type === Field.PrefixButton) {
        before.push(c)
      } else if (c.type === Field.SuffixButton) {
        after.push(c)
      } else {
        rest.push(c)
      }
    })
    const isYYYMMDDHHMMSSFormat = value
      ? /^([0-9]{4})-([0-1][0-9])-([0-3][0-9])\s([0-1][0-9]|[2][0-3]):([0-5][0-9]):([0-5][0-9])$/.test(value)
      : false
    const dateJS = value
      ? timestamp
        ? this.getDateFromTimestamp(value)
        : isYYYMMDDHHMMSSFormat ? this.geDateFromFormat(value) : this.getDateFromUTC(value)
      : null
    let hintNode = null
    const hintNodeId = id ? `${id}_hint` : ''
    if (hint) {
      hintNode = (
        <small id={hintNodeId} className={`hint ${hintClass || ''}`}>
          {hint}
        </small>
      )
    }
    return (
      <div>
        <div>
          {!!before.length && <div className="floating-field-addons">{before}</div>}
          <div>
            {label ? (
                <label
                  htmlFor={id}
                  className="control-label"
                  style={{ fontSize: 12, fontWeight: 600, color: '#c6cbd0', cursor: 'pointer' }}
                >
                  {label}
                </label>
              ) : null}
            <div style={{ position: 'relative' }}>
              {hasError ? <div className="error-block-datepicker">{error}</div> : null}
            </div>
            <DatePicker
              disabled={disabled}
              selected={dateJS}
              onSelect={d => this.handleChange(d, true)}
              onChange={this.handleChange}
              id={id}
              autoComplete="off"
              dateFormat={'d MMM yyyy h:mm a'}
              showTimeSelect={true}
              placeholderText={placeholder}
            />
          </div>
          {!!after.length && <div className="floating-field-addons">{after}</div>}
        </div>
        {hintNode}
      </div>
    )
  }
}

