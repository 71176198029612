import React, { useState } from 'react'

import { Prompt, useHistory } from 'react-router-dom'

import _get from 'lodash/get'

import ConfirmModal from './ConfirmModal'

const RouteLeavingGuard = props => {
  const {
    routerWillLeave = () => true,

    header = 'Confirm Switch',
    content = 'Are you sure you want to switch to a new section without saving your changes?',
  } = props

  const history = useHistory()
  const [modalVisible, setModalVisible] = useState(false)
  const [lastLocation, setLastLocation] = useState(null)

  const handleBlockedNavigation = nextLocation => {
    const confirmedNavigation = _get(nextLocation, 'state.confirmedNavigation', false)
    // In Case of logout skip modal
    if (nextLocation.pathname === '/signin') {
      return true
    }
    if (!routerWillLeave(nextLocation) && !confirmedNavigation) {
      setModalVisible(true)
      setLastLocation(nextLocation)
      return false
    }
    return true
  }

  const onConfirmResponse = response => {
    switch (response) {
      case 'ok': {
        history.push(lastLocation.pathname, { confirmedNavigation: true })
        break
      }
      case 'cancel': {
        break
      }
    }
    setModalVisible(false)
  }

  return (
    <>
      <Prompt message={handleBlockedNavigation} />
      <ConfirmModal
        isOpen={modalVisible}
        header={header}
        content={content}
        actions={[
          { value: 'ok', label: 'Ok', className: 'btn btn-success btn-shadow' },
          { value: 'cancel', label: 'Cancel', className: 'btn btn-default' },
        ]}
        onAction={onConfirmResponse}
      />
    </>
  )
}
export { RouteLeavingGuard }
