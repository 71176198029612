import _get from 'lodash/get'
import { baseRequest } from '.'

import { eventAdapter } from './adapters'

export const getEvents = async (offset, limit, total) => {
  const params = {
    offset,
    limit,
    compact: 'event-list',
  }
  //IF offset = 0 then dont send total, otherwise send total to avoid extra calculations in the backend
  if(offset !== 0) {
    params.total = total
  }
  const res = await baseRequest.get(`events/`, {
    params
  })
  return _get(res, 'data')
}

export const pinEvents = async (eventId, pinned = false) => {
  const res = await baseRequest[pinned ? 'delete' : 'post'](`/event/pin/${eventId}/`)
  return _get(res, 'data')
}

export const getDraftEvents = async (offset, limit) => {
  const res = await baseRequest.get(`draft-events/`, {
    params: {
      offset,
      limit
    }
  })

  const data = _get(res, 'data.data') || []
  return { data }
}

export const updateEventStatus = async (eventId, status) =>
  await baseRequest.patch(`event/${eventId}/status/`, {
    data: {
      attributes: {
        status
      }
    }
  })

export const getEvent = async eid => {
  const res = await baseRequest.get(`events/${eid}/`)
  const data = _get(res, 'data.data')
  return eventAdapter(data)
}

export const fetchEventSupport = async eventId => {
  const res = await baseRequest.get(`event/${eventId}/support/`)

  return _get(res, 'data.data')
}
export const fetchEventTraffic = async (eventId, params) => {
  const res = await baseRequest.get(`events/${eventId}/relationships/page-views`, {
    params
  })
  return _get(res, 'data.data')
}

export const fetchEventTrafficTable = async (eventId, params) => {
  const res = await baseRequest.get(`events/${eventId}/relationships/page-views-table`, {
    params
  })
  return _get(res, 'data.data')
}

export const fetchEventTrafficSubtable = async (eventId, date) => {
  const res = await baseRequest.get(`events/${eventId}/relationships/page-views-subtable?date=${date}`)
  return _get(res, 'data.data')
}

export const fetchEventTrafficOriginsByDay = async eventId => {
  const res = await baseRequest.get(`events/${eventId}/relationships/referrer-url-by-day`)
  return _get(res, 'data.data')
}

export const updateEventSupport = async (eventId, values) => {
  const res = await baseRequest.patch(`event/${eventId}/support/`, {
    data: {
      attributes: {
        ...values
      }
    }
  })

  return res
}
export const createEvent = async data => {
  const res = await baseRequest.post(`events/`, {
    data: {
      ...data,
      type: 'event'
    }
  })
  const event = _get(res, 'data.data')
  return eventAdapter(event)
}

export const duplicateEvent = async (eId, data) => {
  const res = await baseRequest.post(`event/${eId}/clone`, {
    data: {
      ...data,
      type: 'event'
    }
  })
  const event = _get(res, 'data.data')
  return eventAdapter(event)
}

export const updateEvent = async (eid, data) => {
  const res = await baseRequest.patch(`events/${eid}/`, {
    data: {
      ...data,
      id: eid,
      type: 'event'
    }
  })
  const event = _get(res, 'data.data')
  return eventAdapter(event)
}

export const deleteEvent = async eid => {
  const res = await baseRequest.delete(`events/${eid}/`)
  const data = _get(res, 'data.data')
  return eventAdapter(data)
}

export const getInfluencersData = async eid => {
  const res = await baseRequest.get(`events/${eid}/relationships/referrals/`)
  return _get(res, 'data.data')
}

export const announceLocation = async (eid, location, subject) => {
  const res = await baseRequest.post(`event/${eid}/announce-location/`, {
    data: {
      attributes: {
        location,
        subject
      }
    }
  })
  return res
}

// Internal Notes
export const fetchSupportNotes = async eventId => {
  const res = await baseRequest.get(`event/${eventId}/notes`)

  return _get(res, 'data.data')
}

export const createSupportNotes = async (eventId, payload) => {
  const res = await baseRequest.post(`event/${eventId}/note`, payload)

  return _get(res, 'data.data')
}

export const editSupportNotes = async (eventId, noteId, payload) => {
  const res = await baseRequest.put(`event/${eventId}/note/${noteId}`, payload)

  return _get(res, 'data.data')
}

export const deleteSupportNotes = async (eventId, noteId) => {
  const res = await baseRequest.delete(`event/${eventId}/note/${noteId}`)

  return _get(res, 'data.data')
}
