export const countries = {
  '': 'Select Country',
  us: 'United States',
  uk: 'United Kingdom',
  au: 'Australia',
  nz: 'New Zealand',
  in: 'India',
  at: 'Austria',
  be: 'Belgium',
  bg: 'Bulgaria',
  ca: 'Canada',
  hr: 'Croatia',
  cy: 'Cyprus',
  cz: 'Czechia',
  dk: 'Denmark',
  ee: 'Estonia',
  fi: 'Finland',
  fr: 'France',
  de: 'Germany',
  el: 'Greece',
  hu: 'Hungary',
  ie: 'Ireland',
  it: 'Italy',
  lv: 'Latvia',
  lt: 'Lithuania',
  lu: 'Luxembourg',
  mt: 'Malta',
  mx: 'Mexico',
  nl: 'Netherlands',
  pl: 'Poland',
  pt: 'Portugal',
  ro: 'Romania',
  sk: 'Slovakia',
  si: 'Slovenia',
  es: 'Spain',
  se: 'Sweden',
  ae: 'United Arab Emirates',
  ar: 'Argentina',
  ch: 'Switzerland',
  br: 'Brazil',
  ph: 'Philippines'
}
