/*global asset, getCurrencySymbol*/
import _map from 'lodash/map'
import _get from 'lodash/get'
import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { checkIsPromoter } from '../../../utils/permissions'
import NumberAnimation from '../../../_library/NumberAnimation'
import { ROLES } from '../../../constants/roles'
import { getPermissions } from '../../../_theme/utils/permissions'

const mapStateToProps = state => ({
  tfRole: _get(state, 'auth.user.tfRole'),
})

export default connect(mapStateToProps)(
  React.memo(props => {
    const { event, performanceData, influencersData, navigateToTab, currentTabIndex, tfRole } = props
    const { carts, sales, addOns } = performanceData || {}
    const { statistics, referrers = [] } = influencersData || {}
    const isTicketsInCartsTab = currentTabIndex === 4
    const isPromoter = checkIsPromoter(event)
    const permissions = getPermissions(event?.self?.role)

    // Tickets in Carts data
    const lockedTickets = carts ? carts.total || 0 : 0
    const buyers = carts && carts.buyers ? carts.buyers.length : 0

    // Tickets sales data
    const rebates = statistics ? statistics.refunds_due : 0
    let cumulativeQuantity = 0
    let cumulativeRevenue = 0
    let cumulativeDeposit = 0
    let cumulativeDebt = 0
    let cumulativeGuestRevenue = 0

    _map(sales || [], row => {
      let debt = parseFloat(row.pendingToPay)
      if (isNaN(debt)) {
        debt = 0
      }

      let guestRevenue = parseFloat(row.guestRevenue)
      if (isNaN(guestRevenue)) {
        guestRevenue = 0
      }

      cumulativeQuantity += parseInt(row.quantity)
      cumulativeRevenue += parseFloat(row.income)
      cumulativeDeposit += parseFloat(row.income) + guestRevenue - debt
      cumulativeDebt += debt
      cumulativeGuestRevenue += guestRevenue
    })

    // calculate add-ons revenue
    _map(addOns || [], addOn => {
      const numSales = parseInt(addOn.num_sales)
      const cost = parseFloat(addOn.cost)
      cumulativeRevenue += numSales * cost
    })

    const ticketsInCart = useCallback(() => {
      navigateToTab(4)
    }, [navigateToTab])

    const isShownTotalRebats = (tfRole, flagDisableReferral, isPromoter, hasInfluencers) => {
      const hasPerrmission =
        ROLES.is_tf_support(tfRole) || permissions.isAdminOrOwner() || permissions.isStatsOrLimitedStats
      if (!(flagDisableReferral && !hasInfluencers) && hasPerrmission) {
        return true
      }
      return !flagDisableReferral && !isPromoter
    }

    const displayReleasedToBank = () => (
      <p>
        {event?.flagPaidInAdvance &&
          !!Number(event?.paidInAdvanceAmount) &&
          `${getCurrencySymbol(event)} ${event?.paidInAdvanceAmount} `}
        (Released to bank account&nbsp;
        <img
          src="/resources/images/green-check-mark.svg"
          alt="green check mark"
          style={{
            width: '10px',
            height: '10px',
            padding: 0,
            position: 'static',
            filter: 'unset',
          }}
        />
        &nbsp;)
      </p>
    )

    return !!sales || carts ? (
      <div className="buyerlocation locked-tickets">
        {!!sales && (
          <div className="rowTotal left-part">
            <div className="totalQuantity">
              <div className="value">
                <img src={asset('/resources/images/system_icons/ticket.svg')} alt="" />
                <NumberAnimation
                  duration={1500}
                  target={cumulativeQuantity}
                  localeString={true}
                  linear={true}
                />
              </div>
              <div className="title">
                <div className={'inner_text'}>Tickets Sold</div>
              </div>
            </div>

            {/* CumulativeDebt == 0 means there isn't at least a table that allows partial deposit */}
            {cumulativeDebt < Number.EPSILON && (
              <div className="totalRevenue">
                <div className="value">
                  <img src={asset('/resources/images/system_icons/money.svg')} alt="" />
                  <NumberAnimation
                    duration={1500}
                    target={cumulativeRevenue + cumulativeGuestRevenue - rebates}
                    decimals={2}
                    localeString={true}
                    prefix={getCurrencySymbol(event)}
                    linear={true}
                  />
                </div>
                <div className="title">
                  <div className={'inner_text'}>
                    Net Revenue
                    {(event.flagFundsRemitted || event.flagPaidInAdvance) && displayReleasedToBank()}
                  </div>
                </div>
              </div>
            )}

            {cumulativeDebt >= Number.EPSILON && (
              <>
                <div className="totalRevenue">
                  <div className="value">
                    <img src={asset('/resources/images/system_icons/money.svg')} alt="" />
                    <NumberAnimation
                      duration={1500}
                      target={cumulativeDeposit}
                      decimals={2}
                      localeString={true}
                      prefix={getCurrencySymbol(event)}
                      linear={true}
                    />
                  </div>
                  <div className="title">
                    <div className={'inner_text'}>Current Deposit</div>
                  </div>
                </div>

                <div className="totalRevenue">
                  <div className="value">
                    <img src={asset('/resources/images/system_icons/money.svg')} alt="" />
                    <NumberAnimation
                      duration={1500}
                      target={cumulativeDebt}
                      decimals={2}
                      localeString={true}
                      prefix={getCurrencySymbol(event)}
                      linear={true}
                    />
                  </div>
                  <div className="title">
                    <div className={'inner_text'}>Pending to Pay</div>
                  </div>
                </div>

                <div className="totalRevenue">
                  <div className="value">
                    <img src={asset('/resources/images/system_icons/money.svg')} alt="" />
                    <NumberAnimation
                      duration={1500}
                      target={cumulativeRevenue + cumulativeGuestRevenue - rebates}
                      decimals={2}
                      localeString={true}
                      prefix={getCurrencySymbol(event)}
                      linear={true}
                    />
                  </div>
                  <div className="title">
                    <div className={'inner_text'}>Final Net Revenue</div>
                    {(event.flagFundsRemitted || event.flagPaidInAdvance) && displayReleasedToBank()}
                  </div>
                </div>
              </>
            )}

            {isShownTotalRebats(tfRole, event.flagDisableReferral, isPromoter, !!referrers.length) && (
              <div className="totalRebates">
                <div className="value">
                  <img src={asset('/resources/images/system_icons/referral-rebates.svg')} alt="" />
                  <NumberAnimation
                    duration={1500}
                    target={rebates}
                    decimals={2}
                    localeString={true}
                    prefix={getCurrencySymbol(event)}
                    linear={true}
                  />
                </div>
                <div className="title">
                  <div className={'inner_text'}>Referral Rebates</div>
                </div>
              </div>
            )}
          </div>
        )}
        {carts && (
          <div className={`rowTotal ${isTicketsInCartsTab ? 'hidden-label' : ''}`}>
            {!isTicketsInCartsTab && (
              <Link to={'/event/' + event.id + '/performance/tickets-in-carts'}>
                <div
                  className="tictets-state-title"
                  style={{ paddingBottom: 0 }}
                  onClick={ticketsInCart}
                  aria-hidden="true"
                >
                  <div className="value">
                    <img
                      src={asset('/resources/images/event/performance/tickets-in-carts-ico.svg')}
                      className="event-stat-ico"
                      alt=""
                    />
                  </div>
                  <div className="title">
                    <div className="inner_text">Tickets In Carts</div>
                  </div>
                </div>
              </Link>
            )}
            <div className="totalQuantity">
              <div className="value">
                <img src={asset('/resources/images/system_icons/lock-ticket.svg')} alt="" />
                <NumberAnimation duration={1500} target={lockedTickets} localeString={true} linear={true} />
              </div>
              <div className="title">
                <div className={'inner_text'}>Tickets Locked</div>
              </div>
            </div>
            <div className="totalRevenue">
              <div className="value">
                <img src={asset('/resources/images/system_icons/ticket-buyer.svg')} alt="" />
                <NumberAnimation duration={1500} target={buyers} localeString={true} linear={true} />
              </div>
              <div className="title">
                <div className={'inner_text'}>Buyers</div>
              </div>
            </div>
          </div>
        )}
      </div>
    ) : null
  }),
)
