import _result from 'lodash/result'
import _get from 'lodash/get'
import _isEmpty from 'lodash/isEmpty'
import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { DisableSectionByPermissionWrapper } from '../hoc'

import { RouteLeavingGuard } from '../_library/RouteLeavingGuard'
import Card from '../_library/Card'
import EmailToTicketHolders from './messaging/EmailToTicketHolders'
import Mailchimp from '../_features/messaging/Mailchimp'
import SentEmails from '../_features/messaging/SentEmails'

import { EMAIL_TICKET_HOLDERS } from '../../_common/redux/mailchimp/actions'
import { FETCH_EVENT_TICKETS } from '../../_common/redux/tickets/actions'
import { FETCH_EMAIL_TEMPLATES } from '../../_common/redux/emailtemplates/actions'
import { FETCH_EVENT_ADDONS } from '../../_common/redux/addons/actions'
import { SMS_TICKET_HOLDERS } from '../../_common/redux/twilio/actions'

import {
  get_event,
  get_event_tickets,
  get_event_addons,
  get_email_templates,
} from '../../_common/core/selectors'
import { getTitle } from '../utils/getTitle'
import SMSToTicketHolders from './SMS/SMSToTicketHolders'
import SentSMS from './SMS/SentSMS'
import { showAxiosError, showMessage } from '../utils/messenger'
import { UPDATE_EVENT } from '../../_common/redux/events/actions'

@withRouter
@connect(
  state => {
    const event = get_event(state)
    const tickets = get_event_tickets(state)
    const eventAddOns = get_event_addons(state)
    const emailTemplates = get_email_templates(state)
    const { user } = state.auth

    const eventAddOnsLoading = state.loading.has('FETCH_EVENT_ADDONS')
    const eventTicketsLoading = state.loading.has('FETCH_EVENT_TICKETS')
    return {
      event,
      tickets,
      eventAddOns,
      emailTemplates,
      eventAddOnsLoading,
      eventTicketsLoading,
      user,
    }
  },
  {
    EMAIL_TICKET_HOLDERS,
    SMS_TICKET_HOLDERS,
    FETCH_EVENT_TICKETS,
    FETCH_EMAIL_TEMPLATES,
    FETCH_EVENT_ADDONS,
    UPDATE_EVENT,
  },
)
class EventMessaging extends React.Component {
  constructor(props) {
    super(props)
    form_helper_reset()
    this.state = {
      isEmailSent: false,
      isSMSSent: false,
      activeTab: {
        messaging: true,
        sms: false,
      },
    }
    this.mounted = false
  }

  componentDidMount() {
    const {
      event: { id, displayName },
      FETCH_EMAIL_TEMPLATES,
      configs,
    } = this.props

    FETCH_EMAIL_TEMPLATES(id)

    Messenger.options = {
      extraClasses: 'messenger-fixed messenger-on-top messenger-on-right',
      theme: 'future',
    }

    const configDocTitle = _get(configs, 'messages.documentTitle', '')
    document.title = getTitle(configDocTitle, [displayName])

    this.mounted = true
  }

  componentWillUnmount() {
    form_helper_reset()
    this.mounted = false
  }

  routerWillLeave = () => !form_helper_isEditted()

  toggleTabs = tab => {
    this.setState({
      activeTab: {
        messaging: tab === 'messaging',
        sms: tab === 'sms',
      },
    })
  }

  confirmSMS = form => {
    const { event, SMS_TICKET_HOLDERS } = this.props
    this.setState({ isSMSSent: false })
    let selectedRecipientGroup = Object.keys(form).find(
      key => form[key] === true && key !== 'status_checked_in',
    )
    let scopeData = {}
    if (!selectedRecipientGroup) {
      if (form.ticket_holders.length) {
        selectedRecipientGroup = 'ticket_holders'
        scopeData = {
          status_checked_in: form.status_checked_in || false,
          ticket_holders_ids: [...form.ticket_holders],
        }
      } else if (form.add_on_holders.length) {
        selectedRecipientGroup = 'add_on_holders'
        scopeData = {
          add_on_holders_ids: [...form.add_on_holders],
        }
      }
    }
    const formBody = {
      content: form.SMSBody,
      scope: selectedRecipientGroup,
    }
    if (!_isEmpty(scopeData)) {
      formBody.scopeData = scopeData
    }

    return SMS_TICKET_HOLDERS(event.id, formBody)
      .catch(err => Promise.reject(showAxiosError(err)))
      .then(v => {
        showMessage('success', 'Successfully sent!', 3, true)
        this.setState(
          {
            isSMSSent: true,
          },
          () => {
            this.setState({
              isSMSSent: false,
            })
            setTimeout(() => {
              this.setState({
                isSMSSent: true,
              })
            }, 30000)
          },
        )
        return v
      })
  }

  confirmEmail = form => {
    const { event, EMAIL_TICKET_HOLDERS } = this.props
    this.setState({ isEmailSent: false })
    form.toSelected =
      form.toAll ||
      form.toWaitingList ||
      form.toBrandSubscribers ||
      form.toPreregisteredUsers ||
      form.toRefundedTicketHolders ||
      form.toAllEventTeamMembers ||
      form.toAllBrandTeamMembers ||
      form.toInfluencers ||
      form.toSelectedAddonHolders.length
        ? []
        : form.toSelected
    return Promise.resolve(EMAIL_TICKET_HOLDERS(event.id, form))
      .catch(err => Promise.reject(_result(err, 'toFieldErrors', err)))
      .then(v => {
        Messenger().post({
          type: 'success',
          message: 'Successfully sent!',
          hideAfter: 3,
          showCloseButton: true,
        })
        this.setState({
          isEmailSent: true,
        })
        return v
      })
  }

  resetSMSState = () => {
    this.setState({
      isSMSSent: false,
    })
  }

  fetchEventAddOns = () => {
    const { event, FETCH_EVENT_ADDONS } = this.props
    FETCH_EVENT_ADDONS(event.id)
  }

  fetchEventTickets = () => {
    const {
      event: { id },
      FETCH_EVENT_TICKETS,
    } = this.props
    FETCH_EVENT_TICKETS(id, { filter: 'all' })
  }

  render() {
    const {
      event,
      emailTemplates,
      eventTicketsLoading,
      tickets,
      eventAddOnsLoading,
      eventAddOns,
      UPDATE_EVENT,
      user,
      configs,
    } = this.props
    const { isEmailSent, activeTab, isSMSSent } = this.state
    const showMailchimpSection = _get(configs, 'appearance.showMailchimpSection', false)

    return (
      <DisableSectionByPermissionWrapper>
        <div className="EventMessaging-header EventMessaging">
          <div className="tabs-container">
            <div
              className="EventMessaging-tabs"
              onClick={() => this.toggleTabs('messaging')}
              aria-hidden="true"
            >
              <div
                onClick={e => e.preventDefault()}
                className={`${activeTab.messaging && `active`} tab`}
                aria-hidden="true"
              >
                <img
                  alt="messaging"
                  src={asset('/resources/images/system_icons/messaging.svg')}
                  className="ico-show"
                />
                Email
              </div>
            </div>
            {event.flagRedbullSmsEnabled && (
              <div className="EventMessaging-tabs" onClick={() => this.toggleTabs('sms')} aria-hidden="true">
                <div
                  onClick={e => e.preventDefault()}
                  className={`${activeTab.sms && `active`} tab`}
                  aria-hidden="true"
                >
                  <img alt="sms" src={asset('/resources/images/system_icons/SMS.svg')} className="ico-show" />
                  SMS
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="EventMessaging">
          <RouteLeavingGuard routerWillLeave={this.routerWillLeave} />
          {activeTab.messaging && (
            <>
              <Card title={'Emails'} className="EventMessaging-header-card">
                <EmailToTicketHolders
                  // Add-ons handlers
                  eventAddOnsLoading={eventAddOnsLoading}
                  fetchEventAddOns={this.fetchEventAddOns}
                  eventAddOns={eventAddOns}
                  // Tickets handlers
                  eventTicketsLoading={eventTicketsLoading}
                  fetchEventTickets={this.fetchEventTickets}
                  eventTickets={tickets}
                  filterMediumEditor={this.receiveMediumEditor}
                  onSubmit={this.confirmEmail}
                  event={event}
                  emailTemplates={emailTemplates}
                  configs={_get(configs, 'children.EmailToTicketHolders')}
                />
              </Card>
              <Card title={'Sent Emails'}>
                <SentEmails originType="events" id={event.id} isEmailSent={isEmailSent} />
              </Card>
              {user.tfStaff
                ? showMailchimpSection && (
                  <>
                    <div className="mail_chipbg" />
                    <Card title={'MailChimp Integration'}>
                      <Mailchimp originType="events" originData={event} updateFunc={UPDATE_EVENT} />
                    </Card>
                  </>
                )
                : null}
            </>
          )}
          {activeTab.sms && (
            <>
              <Card title={'SMS'} className="EventMessaging-header-card">
                <h5 className="EventMessaging-title">Select Recipients</h5>
                <SMSToTicketHolders
                  // Add-ons handlers
                  eventAddOnsLoading={eventAddOnsLoading}
                  fetchEventAddOns={this.fetchEventAddOns}
                  eventAddOns={eventAddOns}
                  // Tickets handlers
                  eventTicketsLoading={eventTicketsLoading}
                  fetchEventTickets={this.fetchEventTickets}
                  eventTickets={tickets}
                  filterMediumEditor={this.receiveMediumEditor}
                  onSubmit={this.confirmSMS}
                  event={event}
                  emailTemplates={emailTemplates}
                  configs={_get(configs, 'children.SMSToTicketHolders')}
                />
              </Card>
              <Card title={'Sent SMS'}>
                <SentSMS event={event} isSMSSent={isSMSSent} resetSMSState={this.resetSMSState} />
              </Card>
            </>
          )}
        </div>
      </DisableSectionByPermissionWrapper>
    )
  }
}

export default EventMessaging
