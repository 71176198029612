import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import Collapsible from 'react-collapsible'

export const EVENT_STATISTICS = '|performance|influencers|traffic|demographics|psychographics|checkin|music|musicstreaming|likes|orders|gaming|geographics|devices|musicad|musicstreamingad|likesad|performance/ticket-sales|performance/sales-by-ticket-type|performance/add-ons|performance/tickets-in-carts|performance/pre-registration|performance/waiting-list|performance/ticket-resale|performance/sales-by-promo-code|performance/sales-by-promoter|performance/box-office-sales|performance/additional-charges|performance/sales-by-password|performance/rsvp|traffic/views|traffic/locations|traffic/languages|traffic/conversions|traffic/devices|traffic/origin|performance/payment-plans|'
export const EVENT_EDIT = '|details|talent|venues|tickets|budgets|team|credentials|gift-certificates-management|pre-registration|nft|'
export const EVENT_INTERACT = '|messaging|invitations|guest-tickets|gift-certificates|promotions|membership|email-templates|multi-entry-passes|'
export const EVENT_SUPPORT = '|faq|contact|notes|'
export const PROMO = '|sales-by-promo-code|'
export const USERS = '|users|'
export const SIGN_UP = '|sign-up|'
export const SIGN_IN = '|signin|'
export const TRACK_SALES = '|track-sales|'

export const FUNC_CHECKPAGE = (pageGroup, page) => pageGroup.indexOf(page) !== -1

export class MenuItem extends Component {
	static propType = {
	  isMobile: PropTypes.bool,
	  level: PropTypes.number,
	  title: PropTypes.string,
	  img: PropTypes.string,
	  icon: PropTypes.string,
	  active: PropTypes.bool,
	  open: PropTypes.bool,
	  visible: PropTypes.bool,
	  href: PropTypes.string,
	  className: PropTypes.string,
	}

	constructor(props) {
	  super(props)
	  this.state = {
	    isExpanded: false,
	    isOnHover: false,
	    childIndex: -1,
	  }
	  this.onMouseEnter = this.onMouseEnter.bind(this)
	  this.onMouseLeave = this.onMouseLeave.bind(this)
	  this.onMouseClick = this.onMouseClick.bind(this)
	}

	onMouseEnterChild(index){
	  this.setState({
	    childIndex: index
	  })
	}

	onMouseLeaveChild(index){
	  this.setState({
	    childIndex: -1
	  })
	}

	onMouseEnter() {
	  const { title, level, href, isMobile, active, img } = this.props
	  this.setState({
	    isOnHover: true
	  })
	  if(isMobile)
	    return
	  if(level == 2){
	    this.setState({ isExpanded: true })
	  }
	}

	onMouseLeave() {
	  const { title, level, href, isMobile } = this.props
	  this.setState({
	    isOnHover: false
	  })
	  if(isMobile)
	    return
	  if(level == 2){
	    this.setState({ isExpanded: false })
	  }
	}

	onMouseClick() {
	  const { title, level, href, isMobile } = this.props
	  if(!isMobile)
	    return
	  if(level == 2){
	    this.setState({ isExpanded: !this.state.isExpanded })
	  }
	}

	render() {
	  const { isExpanded, isOnHover, childIndex } = this.state
	  const { title, img, icon, level, open, visible, active, href, className, childGroup, isMobile } = this.props

	  let iconObj = null; let iconObj_inActive = null
	  if(img) {
	    const zIndex_active = active || isOnHover ? 100 : 99
	    const zIndex_inActive = active || isOnHover ? 99 : 100
		  const styleTrafficActive =
      	img === 'traffic'
          ? {
					  width: 224,
					  height: 204,
					  transform: 'scale(0.15) translate(-660px, -540px)',
            display: active || isOnHover ? 'block' : 'none',
          }
          : {}
    	const styleTrafficInactive =
				img === 'traffic'
					? {
				  width: 224,
				  height: 204,
				  transform: 'scale(0.15) translate(-660px, -540px)',
			  	display: !(active || isOnHover) ? 'block' : 'none',
			  }
			  : {}
		  iconObj = (
			  <img
				  alt=""
		  		src={asset('/resources/images/system_icons/' + img + '.svg')}
		  		style={{ zIndex: zIndex_active, ...styleTrafficActive }}
		  	/>
		  )
		  iconObj_inActive = (
		  	<img
				  alt=""
		  		src={asset('/resources/images/system_icons/inactive/' + img + '.svg')}
		  		style={{ zIndex: zIndex_inActive, ...styleTrafficInactive }}
		  	/>
		  )
	  }
	  if(icon) {
	    iconObj = <i className={icon} aria-hidden='true'/>
	  }

	  const classes = classNames({
	    'menuitem': true,
	    'level2': level === 2,
	    active,
	    'hover': isOnHover,
	    'open': open || isExpanded,
	  }, className)

	  let subMenu = null
	  if(level == 2){
	    if(!!childGroup){
	      subMenu = _.map(childGroup, (child, index) => {
	        const childClasses = classNames({
	          'menuitem': true,
	          'level3': true,
	          'active2': child.active || childIndex == index
	        })
	        const zIndex_active = child.active || childIndex == index ? 100 : 99
	        const zIndex_inActive = child.active || childIndex == index ? 99 : 100
				  const styleTrafficActive =
      		child.img === 'traffic'
          ? {
					  width: 224,
					  height: 204,
					  transform: 'scale(0.12) translate(-840px, -780px)',
            display: (child.active || childIndex == index) ? 'block' : 'none',
          }
          : {}
    			const styleTrafficInactive =
					child.img === 'traffic'
					? {
					  width: 224,
					  height: 204,
					  transform: 'scale(0.12) translate(-840px, -780px)',
			  		display: !(child.active || childIndex == index) ? 'block' : 'none',
			  	}
			  	: {}
	        return (<Link key={index} className={childClasses} to={child.href} onMouseEnter={this.onMouseEnterChild.bind(this,index)} onMouseLeave={this.onMouseLeaveChild.bind(this, index)} style={{ display: child.visible ? 'block': 'none' }}>
	          <img src = {asset('/resources/images/system_icons/' + child.img + '.svg')} style={{ zIndex: zIndex_active, ...styleTrafficActive }}/>
	          <img src = {asset('/resources/images/system_icons/inactive/' + child.img + '.svg')} style={{ zIndex: zIndex_inActive, ...styleTrafficInactive }}/>
	          <div className='title'>{child.title}</div>
	        </Link>)
	      })
	    }
	  }

	  return (
	    <div onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave} onClick={this.onMouseClick} className={level == 2 ? 'level2_container' : ''}>
	      <Link
	        onClick={e => isMobile && level ===2 ? e.preventDefault() : null}
	        to={href}
	        className={classes}
	        style={{ display: visible ? 'block' : 'none' }}
	      >
	        {iconObj}
	        {iconObj_inActive}
	        <div className='title'>{title}</div>
	      </Link>

	      {level === 2 && isMobile &&
					<Collapsible open={isExpanded}>
					  {subMenu}
					</Collapsible>
	      }
	      {level === 2 && isExpanded && !isMobile &&
					<div className="level2_submenu">
					  {subMenu}
					</div>
	      }
	    </div>
	  )
	}
}
