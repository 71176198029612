import _get from 'lodash/get'
import { baseRequest } from '.'

export const getContract = async (eventId) => {
    try {
        const res = await baseRequest.get(`events/${eventId}/contract`)
        return _get(res, 'data.data.attributes')
    } catch(err) {
        if (err?.response?.data?.message === 'Contract not found') {
            return null
        }
        throw err
    }
}

export const createContract = async (eventId, data) => {
    const res = await baseRequest.post(`events/${eventId}/contract`, {data})
    return _get(res, 'data')
}

export const updateContract = async (eventId, data) => {
    const res = await baseRequest.put(`events/${eventId}/contract`, {data})
    return _get(res, 'data')
}

export const getContractStatus = async (eventId) => {
    const contract = await getContract(eventId)
    return contract ? (contract.status ?? 'not-ready') : 'not-exist'
}

export const deployContract = async (eventId) => {
    const res = await baseRequest.post(`events/${eventId}/contract/deploy`)
    return _get(res, 'data')
}

export const deleteContract = async (eventId) => {
    const res = await baseRequest.delete(`events/${eventId}/contract`)
    return _get(res, 'data')
}