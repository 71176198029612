import React from 'react'
import Papa from 'papaparse'
import _get from 'lodash/get'
import _set from 'lodash/set'
import _map from 'lodash/map'
import _find from 'lodash/find'
import { withFormik } from 'formik'

import Button from '../../_library/Button'
import Field from '../../_library/Field'
import Select from '../../_library/Select'
import Card from '../../_library/Card'
import MultiSelect from '../../_library/MultiSelect'
import TagsField2 from '../../_library/TagsField2'

function validateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

function validateForm(data, props) {  
  const errors = {}

  const recipients = _get(data, 'recipients')
  const recipientsError = []
  recipients.forEach((r, index) => {
    if(!r.first_name) {
      _set(errors, 'recipients['+index+'].first_name', 'Required')
    }
    if(r.email && !validateEmail(r.email)) {
      _set(errors, 'recipients['+index+'].email', 'Invalid')
    }
    if(!r.ticketTypeName) {
      _set(errors, 'recipients['+index+'].ticketTypeName', 'Required')
    } else if(!validateTicketTypeName(r.ticketTypeName, props.tickets.concat(props.tables))) {
      _set(errors, 'recipients['+index+'].ticketTypeName', 'Invalid')
    }
    if(!r.quantity) {
      _set(errors, 'recipients['+index+'].quantity', 'Required')
    } else if(isNaN(r.quantity) || r.quantity<0) {
      _set(errors, 'recipients['+index+'].quantity', 'Invalid')
    } else if(r.quantity>50) {
      _set(errors, 'recipients['+index+'].quantity', '>50')
    }
  })

  return errors
}

function validateTicketTypeName(ticketTypeName, tickets) {
  const ticketType = _find(tickets, ticket => ticket.displayName.toLowerCase() == ticketTypeName.toLowerCase())
  return ticketType != null
}

function getTicketType(ticketTypeName, tickets) {
  if(!ticketTypeName) return null
  const ticketType = _find(tickets, ticket => ticket.displayName.toLowerCase() == ticketTypeName.toLowerCase())
  return ticketType
}

function getAddOns(addOnNames, addons) {
  if(!addOnNames) return null
  const addOnArray = addOnNames.split(",");
  const matchedAddOns = [];

  addOnArray.forEach(addOnName => {
    const addOn = _find(addons, addon => addon.name.toLowerCase().trim() == addOnName.toLowerCase().trim())
    if (addOn) {
      matchedAddOns.push(addOn);
    }
  });
  return matchedAddOns
}

class ImportGuestsFormComponent extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      csvParsing: false,
      csvParseCountAll: 0,
      csvParseCount: 0
    }
  }

  componentDidMount(){

  }

  openCSV() {
    $(this.refs.fileCSV).click()
  }

  importCSV(e) {
    const that = this
    const { tickets, addons, event } = this.props

    const hasAttendeeTags = event.attendeeTags && event.attendeeTags.length > 0

    const ext = e.target.files[0].name.split('.').pop()

    // if(e.target.files[0].type === 'text/csv') {
    if (ext === 'csv') {
      this.setState({
        csvParsing: true,
        csvParseCountAll: 0,
        csvParseCount: 0
      })

      Papa.parse(e.target.files[0], {
        complete(results) {
          const data = []
          for(let j=0; j<results.data.length; j++) {
            if(results.data[j].length === 1 && results.data[j][0].trim() === '') {

            } else {
              data.push(results.data[j])
            }
          }
          let i = 0
          that.setState({ csvParseCountAll: data.length })
          const addGuestTicketInterval = setInterval(() => {
            const dd = data.slice(i, i + 100).map((r) => {
              const tt = getTicketType(r[4] && r[4].trim(), tickets)
              const ao = getAddOns(r[5] && r[5].trim(), addons)
              return {
                first_name: r[0] && r[0].trim(),
                last_name: r[1] && r[1].trim(),
                email: r[2] && r[2].trim(),
                quantity: parseInt(r[3] && r[3].trim()),
                ticketTypeName: tt ? tt.displayName : r[4] && r[4].trim(),
                ticketAddOns: ao ? ao : r[5] && r[5].trim(),
                notes: r[6] && r[6].trim() || '',
                tags: hasAttendeeTags ? r.slice(7) : [],
              }
            })
            that.props.setFieldValue('recipients', [...that.props.values.recipients, ...dd])
            i += 100
            that.setState({ csvParseCount: i })
            if(i >= data.length) {
              clearInterval(addGuestTicketInterval)
              that.setState({
                csvParsing: false
              }, () => {
                that.touchRecipientFields()
              })
            }
          }, 100)
        }
      })
    // } else if (e.target.files[0].type === 'application/vnd.ms-excel' || e.target.files[0].type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
    } else if (ext === 'xls' || ext === 'xlsx') {
      Messenger().post({
        type: 'error',
        message: 'Please upload a CSV file instead of an Excel format file',
        hideAfter: 5,
        showCloseButton: true
      })
    } else {
      Messenger().post({
        type: 'error',
        message: 'Please upload a CSV file',
        hideAfter: 5,
        showCloseButton: true
      })
    }
    $(this.refs.fileCSV).val('')
  }

  touchRecipientFields() {
    for(let i = 0; i < this.props.values.recipients.length; i++) {
      this.props.setFieldTouched('recipients[' + i + '].first_name', true)
      this.props.setFieldTouched('recipients[' + i + '].last_name', true)
      this.props.setFieldTouched('recipients[' + i + '].email', true)
      this.props.setFieldTouched('recipients[' + i + '].quantity', true)
      this.props.setFieldTouched('recipients[' + i + '].ticketTypeName', true)
      this.props.setFieldTouched('recipients[' + i + '].notes', true)
      this.props.setFieldTouched('recipients[' + i + '].tags', true)
      this.props.setFieldTouched('recipients[' + i + '].ticketAddOns', true)
    }
  }

  isRecipientValid(recipient) {
    return false
  }

  deleteRecipient(index) {
    const recipients = [...this.props.values.recipients]
    recipients.splice(index, 1)
    this.props.setFieldValue('recipients', recipients)
  }

  handleRecipientChangeTags(index, tags) {
    this.props.setFieldTouched('recipients[' + index + '].tags', true)
    this.props.setFieldValue('recipients[' + index + '].tags', tags)
  }

  handleMultiSelect = (id, value) => {
    this.props.setFieldValue(id, value)
  }

  render() {
    const {
      values,
      touched,
      errors,
      isSubmitting,
      handleChange,
      handleBlur,
      handleSubmit,
      handleReset,
      isValid,
      submitLabel,
      event,
      tickets,
      tables,
      addons,
      cardsStatus,
    } = this.props

    const { csvParsing, csvParseCountAll, csvParseCount } = this.state

    const optionsTicketTypeNames = _map(tickets.concat(tables), (ticket)=> ({ value: ticket.displayName, label: ticket.displayName }))
    optionsTicketTypeNames.unshift({ value:'', label:'- Select -' })

    const optionsAddons = _map(addons, (addon)=> ({ id: addon.id, name: addon.name }))
    
    const hasAttendeeTags = event.attendeeTags && event.attendeeTags.length > 0

    return (
      <Card title={'Upload Guest Tickets'} status={cardsStatus && cardsStatus.form}>
        <p>
          Format guidelines &nbsp;&nbsp;&nbsp;
          <i className="fa fa-caret-right" aria-hidden="true" />
          Please do <strong>not</strong> include a header row in the file. Each row should include the following:
        </p>
        <div className="recipients-table-container">
          <table className="table recipients-table">
            <thead>
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email Address (optional)</th>
                <th>Number of Tickets in Total</th>
                <th>Type of Ticket (Crew, Performer, etc.)</th>
                <th>Attach Add-Ons (optional)</th>
                <th>Notes (optional)</th>
                { hasAttendeeTags && <th>Attendee Tags (optional)</th> }
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colSpan={ hasAttendeeTags ? 7 : 6 }>
                  <p className="show_title">Example:</p>
                </td>
              </tr>
              <tr>
                <td>Jane</td>
                <td>Smith</td>
                <td />
                <td>2</td>
                <td>Crew</td>
                <td>Camping</td>
                <td>Stage Manager</td>
                { hasAttendeeTags && <td>{ event.attendeeTags[0] }{ event.attendeeTags.length > 1 && (', ' + event.attendeeTags[1]) }</td> }
              </tr>
              <tr>
                <td>Calvin</td>
                <td>Francis</td>
                <td>dj.calvin.francis@gmail.com</td>
                <td>3</td>
                <td>Performer</td>
                <td />
                <td />
                { hasAttendeeTags && <td>{ event.attendeeTags[0] }{ event.attendeeTags.length > 2 && (', ' + event.attendeeTags[2]) }</td> }
              </tr>
              <tr>
                <td>Clark</td>
                <td>Kent</td>
                <td>superman@yahoo.com</td>
                <td>1</td>
                <td>Media</td>
                <td>Camping, VIP</td>
                <td>Artist Guest</td>
                { hasAttendeeTags && <td>{ event.attendeeTags.length > 1 && (event.attendeeTags[1]) }{ event.attendeeTags.length > 2 && (', ' + event.attendeeTags[2]) }</td> }
              </tr>
            </tbody>
          </table>
        </div>
        <div className="upload-button-container">
          <input type="file" accept=".csv" onChange={this.importCSV.bind(this)} ref="fileCSV" className="file-csv" />
          <div className="btn btn-success btn-shadow btn-importcsv" onClick={this.openCSV.bind(this)}>
            <i className="fa fa-upload" aria-hidden="true" /> Add from CSV
          </div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="recipients-table-container">
            <table className="table recipients-table">
              <thead>
                <tr>
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Number of Tickets</th>
                  <th>Type of Ticket</th>
                  <th>Attach Add-Ons</th>
                  <th colSpan={ hasAttendeeTags ? 1 : 2 }>Notes</th>
                  { hasAttendeeTags && <th colSpan="2">Attendee Tags</th> }
                </tr>
              </thead>
              <tbody>
                { csvParsing &&
                  <tr>
                    <td colSpan={ hasAttendeeTags ? 8 : 7 } className="text-center">
                      <i className="fa fa-circle-o-notch fa-fw fa-spin" />&nbsp;&nbsp;Loaded {csvParseCount} of {csvParseCountAll}
                    </td>
                  </tr>
                }
                { !csvParsing && (!values.recipients || values.recipients.length == 0) &&
                  <tr>
                    <td colSpan={ hasAttendeeTags ? 8 : 7 } className="text-center addnew-msg">
                      No CSV uploaded yet
                    </td>
                  </tr>
                }
                { values.recipients && values.recipients.map((recipient, index) => (
                  <tr key={index} className={this.isRecipientValid(recipient)?'':'row-error'}>
                    <td>
                      <Field
                        type="text"
                        id={'recipients[' + index + '].first_name'}
                        value={values.recipients[index].first_name}
                        error={errors.recipients && errors.recipients[index] && errors.recipients[index].first_name}
                        touched={touched.recipients && touched.recipients[index] && touched.recipients[index].first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </td>
                    <td>
                      <Field
                        type="text"
                        id={'recipients[' + index + '].last_name'}
                        value={values.recipients[index].last_name}
                        error={errors.recipients && errors.recipients[index] && errors.recipients[index].last_name}
                        touched={touched.recipients && touched.recipients[index] && touched.recipients[index].last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </td>
                    <td>
                      <Field
                        type="email"
                        id={'recipients[' + index + '].email'}
                        value={values.recipients[index].email}
                        error={errors.recipients && errors.recipients[index] && errors.recipients[index].email}
                        touched={touched.recipients && touched.recipients[index] && touched.recipients[index].email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </td>
                    <td>
                      <Field
                        type="number"
                        id={'recipients[' + index + '].quantity'}
                        value={values.recipients[index].quantity}
                        error={errors.recipients && errors.recipients[index] && errors.recipients[index].quantity}
                        touched={touched.recipients && touched.recipients[index] && touched.recipients[index].quantity}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </td>
                    <td>
                      <Select
                        options={optionsTicketTypeNames}
                        id={'recipients[' + index + '].ticketTypeName'}
                        value={values.recipients[index].ticketTypeName}
                        error={errors.recipients && errors.recipients[index] && errors.recipients[index].ticketTypeName}
                        touched={touched.recipients && touched.recipients[index] && touched.recipients[index].ticketTypeName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </td>
                    <td>
                    <MultiSelect
                        options={optionsAddons}
                        selectedValues={values.recipients[index].ticketAddOns}
                        id={'recipients[' + index + '].ticketAddOns'}
                        error={errors.recipients && errors.recipients[index] && errors.recipients[index].ticketAddOns}
                        touched={touched.recipients && touched.recipients[index] && touched.recipients[index].ticketAddOns}
                        onSelect={this.handleMultiSelect}
                        onRemove={this.handleMultiSelect}
                        avoidHighlightFirstOption="true"
                        displayValue="name"
                        hidePlaceholder="true"
                        emptyRecordMsg="No more add-ons available"
                      />
                    </td>
                    <td>
                      <Field
                        type="notes"
                        id={'recipients[' + index + '].notes'}
                        value={values.recipients[index].notes}
                        error={errors.recipients && errors.recipients[index] && errors.recipients[index].notes}
                        touched={touched.recipients && touched.recipients[index] && touched.recipients[index].notes}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </td>
                    { hasAttendeeTags && <td>
                      <TagsField2
                        label="Attendee Tags"
                        id={'recipients[' + index + '].tags'}
                        value={values.recipients[index].tags}
                        tags={event.attendeeTags}
                        onChange={this.handleRecipientChangeTags.bind(this, index)}
                      />
                    </td> }
                    <td>
                      <div className="delete-recipient" onClick={this.deleteRecipient.bind(this, index)}><i className="fa fa-trash" aria-hidden="true" /></div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {
            values.recipients && values.recipients.length > 0 && isValid &&
            <div className="issue-button-container">
              <Button className="btn btn-primary btn-shadow" type="submit" loading={isSubmitting}>{submitLabel || 'Issue Tickets'}</Button>
            </div>
          }          
        </form>
      </Card>
    )
  }
}

const ImportGuestsForm = withFormik({
  mapPropsToValues: (props) => ({
    recipients: _get(props, 'initialValues.attributes.recipients') || [],
  }),
  
  // Custom sync validation
  validate: (values, props) => validateForm(values, props),
  
  handleSubmit: (values, { props, setSubmitting, resetForm }) => {
    props.onSubmit({
      ...values
    })
      .then((v) => {
        setSubmitting(false)
        resetForm()
      })
      .catch((err) => {
        setSubmitting(false)
      })
  },
  displayName: 'ImportGuestsForm', // helps with React DevTools
})(ImportGuestsFormComponent)

export default ImportGuestsForm
