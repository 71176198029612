import React from 'react'

import OverlayTrigger from 'react-bootstrap/lib/OverlayTrigger'
import Tooltip from 'react-bootstrap/lib/Tooltip'
import ClipboardButton from 'react-clipboard.js'

import AutoSlugField from '../_library/AutoSlugField'
import Field from '../_library/Field'
import Card from '../_library/Card'
import RichTextArea from '../_library/RichTextArea'
import _get from 'lodash/get'

const BrandNameDetails = React.memo(props => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    brandUrlCopied,
    copyBrandUrlAfter,
    copyBrandUrlOut,
    handleChangeSlug,
    isRnDomain,
    handleChangeDescription,
    isNew,
    config,
  } = props

  const BASE_URL = _get(config, 'behavior.baseURL')
  const slugSuggestion = !isNew ? values.slug : values.displayName

  return (
    <Card icon={'fa-info'} title={'Brand Details'}>
      <Field
        id="displayName"
        label="Name"
        value={values.displayName}
        error={errors.displayName}
        touched={touched.displayName}
        onChange={handleChange}
        onBlur={handleBlur}
      />
      <label className="autoslugfield-label" onMouseLeave={copyBrandUrlOut}>
          URL
        <ClipboardButton
          component="span"
          data-clipboard-text={BASE_URL + values.slug}
          onSuccess={copyBrandUrlAfter}
        >
          <OverlayTrigger
            placement="right"
            overlay={
              brandUrlCopied ? (
                <Tooltip id="brandUrlCopied">Copied</Tooltip>
              ) : (
                <Tooltip id="brandUrlCopied">Copy Brand URL</Tooltip>
              )
            }
            trigger={['hover']}
          >
            <i className="fa fa-clipboard brand-url-copy" />
          </OverlayTrigger>
        </ClipboardButton>
      </label>
      <AutoSlugField
        id="slug"
        label="Link URL"
        separator={isRnDomain ? '' : '-'}
        hint="Letters, numbers and hyphens only"
        suggestion={slugSuggestion}
        value={values.slug}
        error={errors.slug}
        touched={touched.slug}
        onChange={handleChangeSlug}
      >
        <Field.PrefixAddon className="link-prefix hidden-xs">
          <img
            className="link-prefix-img"
            src={asset('/resources/images/event-url.png')}
          />
          <div className="link-prefix-url">{BASE_URL}</div>
        </Field.PrefixAddon>
      </AutoSlugField>
      {isRnDomain ? (
        <div>
          <div className="div-spacing-20" />
          <RichTextArea
            label="Description (Links Allowed)"
            value={values.description}
            onChange={handleChangeDescription}
            editorStyle={
              errors.description && touched.description ? { border: '2px solid #d9534f' } : null
            }
          />
          <div className="div-spacing-20" />
          <Field
            id="shortDescription"
            label="Short Description (max 150 characters)"
            value={values.shortDescription}
            error={errors.shortDescription}
            touched={touched.shortDescription}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </div>
      ) : null}
    </Card>
  )
})

export default BrandNameDetails
