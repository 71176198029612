import {
  getEventEditMenuItems,
  getEventStatisticsMenuItems,
  getEventInteractMenuItems,
  getEventSupportMenuItems,
} from '.'
import { ROLES } from '../../constants/roles'
import { EVENT_STATISTICS, EVENT_EDIT, EVENT_INTERACT, EVENT_SUPPORT, FUNC_CHECKPAGE } from '../_Library'
import { getPermissions } from './permissions'

export const getEventMenuItems = ({
  path,
  page,
  itemId,
  isRnDomain,
  isMcDomain,
  userTfRole,
  permission,
  showEventCredentials,
  enablePromotions,
  flagDisableReferral,
  flagTimeSlotsEnabled,
  enableGiftCertificates,
  flagMultiEntryPasses,
  flagRequireEthereumWallet,
  hasInfluencers,
  configs,
}) => {
  const permissions = getPermissions(permission)
  const { PERMISSION_PROMOTER, PERMISSION_ONSITE, PERMISSION_CURATOR } = permissions

  const isTfSupport = ROLES.is_tf_support(userTfRole)
  const isSupportOrPartialSupport = ROLES.is_support_or_partial_support(permission)
  const isAdminOrOwner = permissions.isAdminOrOwner()
  const isStatsOrLimitedStats = permissions.isStatsOrLimitedStats()

  const redirectPath_interact = PERMISSION_CURATOR ? 'invitations' : 'messaging'
  const redirectPath_stat = PERMISSION_ONSITE
    ? 'guest-tickets'
    : isSupportOrPartialSupport
    ? 'orders'
    : 'performance'

  const childGroup_stat = getEventStatisticsMenuItems({
    itemId,
    permission,
    path,
    flagDisableReferral,
    userTfRole,
    hasInfluencers,
    configs,
  })
  const childGroup_edit = getEventEditMenuItems({
    itemId,
    permission,
    path,
    showEventCredentials,
    enableGiftCertificates,
    userTfRole,
    flagRequireEthereumWallet,
    configs,
  })
  const childGroup_interact = getEventInteractMenuItems({
    itemId,
    permission,
    path,
    flagTimeSlotsEnabled,
    enablePromotions,
    enableGiftCertificates,
    userTfRole,
    flagMultiEntryPasses,
    configs,
  })
  const childGroup_support = getEventSupportMenuItems({ itemId, permission, path, userTfRole, configs })

  const menuGroup = []
  menuGroup.push({
    level: 2,
    img: 'statistics',
    title: 'Statistics',
    href: `/event/${itemId}/${redirectPath_stat}`,
    active: FUNC_CHECKPAGE(EVENT_STATISTICS, `|${page}|`),
    visible:
      isAdminOrOwner ||
      isTfSupport ||
      isSupportOrPartialSupport ||
      isStatsOrLimitedStats ||
      PERMISSION_ONSITE ||
      PERMISSION_PROMOTER,
    childGroup: childGroup_stat,
  })
  menuGroup.push({
    level: 2,
    img: 'edit',
    title: 'Edit',
    href: `/event/${itemId}/details`,
    active: FUNC_CHECKPAGE(EVENT_EDIT, `|${page}|`),
    visible: isAdminOrOwner || isTfSupport,
    childGroup: childGroup_edit,
  })
  menuGroup.push({
    level: 2,
    img: 'interact',
    title: 'Interact',
    href: `/event/${itemId}/${redirectPath_interact}`,
    active: FUNC_CHECKPAGE(EVENT_INTERACT, `|${page}|`),
    visible: isAdminOrOwner || isTfSupport || PERMISSION_CURATOR,
    childGroup: childGroup_interact,
  })
  const firstChildGroup_support = childGroup_support.find(child => child.visible)
  if (!firstChildGroup_support) {
    return menuGroup
  }
  menuGroup.push({
    level: 2,
    img: 'support',
    title: 'Support',
    href: firstChildGroup_support.href,
    active: FUNC_CHECKPAGE(EVENT_SUPPORT, `|${page}|`),
    visible: isAdminOrOwner || isTfSupport,
    childGroup: childGroup_support,
  })

  return menuGroup
}
