import { createAsyncAction } from '../actions'
import { fetchAPI } from '../../core/http'
import { showAxiosError } from '../../../web/utils/messenger'

const { FETCH_INVENTORY_DELEGATIONS } = createAsyncAction('FETCH_INVENTORY_DELEGATIONS', function (eid) {
  return dispatch =>
    fetchAPI(`/api/event/${eid}/inventory-delegation`, null)
      .then(res => {
        const out = { eid: eid.toString(), inventoryDelegations: res }
        dispatch(this.success(out))
        return res
      })
      .catch(err => {
        const errMsg = err.errors[0].details
        showAxiosError(err.errors[0], errMsg, 3)
      })
})

const { CREATE_INVENTORY_DELEGATION } = createAsyncAction(
  'CREATE_INVENTORY_DELEGATION',
  function (eid, delegation) {
    const body = {
      data: {
        attributes: delegation.attributes,
        type: 'inventory_delegation',
        relationships: {
          event: {
            data: { type: 'event', id: eid }
          }
        }
      }
    }
    return dispatch =>
      fetchAPI(`/api/event/${eid}/inventory-delegation`, {
        method: 'POST',
        body: JSON.stringify(body)
      })
        .catch(err => {
          const errMsg = err.errors[0].details
          showAxiosError(err.errors[0], errMsg, 3)
          throw err
        })
        .then(res => {
          dispatch(this.success({ eid, res }))
          return res
        })
  }
)

const { DELETE_INVENTORY_DELEGATION } = createAsyncAction('DELETE_INVENTORY_DELEGATION', function (did, eid) {
  return dispatch =>
    fetchAPI(`/api/event/inventory-delegation/${did}`, {
      method: 'DELETE'
    })
      .catch(err => {
        const errMsg = err.errors[0].details
        showAxiosError(err.errors[0], errMsg, 3)
        throw err
      })
      .then(res => {
        const out = { did: did.toString(), eid: eid.toString() }
        dispatch(this.success(out))
        return res
      })
})

const { UPDATE_INVENTORY_DELEGATION } = createAsyncAction(
  'UPDATE_INVENTORY_DELEGATION',
  function (did, eid, delegation) {
    const body = {
      data: { attributes: delegation.attributes },
    }
    return dispatch =>
      fetchAPI(`/api/event/inventory-delegation/${did}/access`, {
        method: 'POST',
        body: JSON.stringify(body),
      })
        .catch(err => {
          const errMsg = err.errors[0].details
          showAxiosError(err.errors[0], errMsg, 3)
          throw err
        })
        .then(res => {
          dispatch(this.success({ eid, res }))
          return res
        })
  },
)

export {
  FETCH_INVENTORY_DELEGATIONS,
  CREATE_INVENTORY_DELEGATION,
  DELETE_INVENTORY_DELEGATION,
  UPDATE_INVENTORY_DELEGATION
}
